import * as Actions from "../actions";

const INIT_STATE = {
  attendanceTimetableListLoading: false,
  attendanceTimetableList: [],

  error: {
    code: null,
    message: null,
  },
};

const attendanceTimetableReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.ATTENDANCE_TIMETABLE_LIST_LOADING: {
      return {
        ...state,
        attendanceTimetableListLoading: true,
        attendanceTimetableList: [],
      };
    }
    case Actions.ATTENDANCE_TIMETABLE_LIST_SUCCESS: {
      return {
        ...state,
        attendanceTimetableListLoading: false,
        attendanceTimetableList: [...action.payload],
      };
    }
    case Actions.ATTENDANCE_TIMETABLE_LIST_ERROR: {
      return {
        ...state,
        attendanceTimetableListLoading: false,
        attendanceTimetableList: [],
        error: {
          ...state.error,
          code: action?.payload?.code,
          message: action?.payload?.message,
        },
      };
    }
    case Actions.ATTENDANCE_TIMETABLE_LIST_RESET: {
      return {
        ...state,
        attendanceTimetableListLoading: false,
        attendanceTimetableList: [],
        error: {
          code: null,
          message: null,
        },
      };
    }
    case Actions.ATTENDANCE_TIMETABLE_ITEM_UPDATE: {
      const idx = state.attendanceTimetableList.findIndex(
        (entry) => entry.id === action.payload.id
      );
      const newList = [
        ...state.attendanceTimetableList.slice(0, idx),
        action.payload,
        ...state.attendanceTimetableList.slice(idx + 1),
      ];
      return {
        ...state,
        attendanceTimetableList: newList,
      };
    }
    case Actions.ATTENDANCE_TIMETABLE_ITEM_UPDATED: {
      const idx = state.attendanceTimetableList.findIndex(
        (entry) => entry.id === action.payload.id
      );
      
      let newList = [];
      if (idx === -1) {
        newList = [...state.attendanceTimetableList, action.payload];
      } else {
        newList = [
          ...state.attendanceTimetableList.slice(0, idx),
          action.payload,
          ...state.attendanceTimetableList.slice(idx + 1),
        ];
      }

      return {
        ...state,
        attendanceTimetableList: newList,
        attendanceTimetableListLoading: false,
      };
    }
    case Actions.ATTENDANCE_TIMETABLE_ITEM_UPDATING: {
      return {
        ...state,
        attendanceTimetableListLoading: true,
      };
    }
    case Actions.ATTENDANCE_TIMETABLE_ITEM_ERROR: {
      return {
        ...state,
        attendanceTimetableListLoading: false,
        error: {
          ...state.error,
          code: action?.payload?.code,
          message: action?.payload?.message,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default attendanceTimetableReducer;
