import * as Actions from "../actions";
import * as common from "../actions/Common";
import examsService from "../../appServices/examsService";

export {
  fetchExamsList,
  fetchWidgetExamList,
  fetchWidgetQuizList,
  fetchWidgetAssessmentList,
  createExam,
  getExamStudents,
  getSubjectSkills,
  getSubjectDetail,
  updateExamMarkList,
  updateExam,
};

const fetchExamsList = (query) => async (dispatch) => {
  try {
    console.log("thunkst: ", query);
    dispatch(common.fetchStart(Actions.EXAMS_LIST_LOADING));
    // debugger;
    const result = await examsService.getExamsList(query);
    return dispatch(
      common.fetchSuccessWithData(Actions.EXAMS_LIST_SUCCESS, result),
      console.log("thunkExamList: ", result)
    );
  } catch (error) {
    //  debugger;
    return dispatch(common.fetchError(error, Actions.EXAMS_LIST_ERROR));
  }
};

const fetchWidgetExamList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.WIDGET_EXAM_LIST_LOADING,
    Actions.WIDGET_EXAM_LIST_SUCCESS,
    Actions.WIDGET_EXAM_LIST_ERROR,
    examsService.getPagedList,
    query
  );

const fetchWidgetAssessmentList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.WIDGET_ASSESSMENT_LIST_LOADING,
    Actions.WIDGET_ASSESSMENT_LIST_SUCCESS,
    Actions.WIDGET_ASSESSMENT_LIST_ERROR,
    examsService.getPagedList,
    query
  );

const fetchWidgetQuizList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.WIDGET_QUIZ_LIST_LOADING,
    Actions.WIDGET_QUIZ_LIST_SUCCESS,
    Actions.WIDGET_QUIZ_LIST_ERROR,
    examsService.getPagedList,
    query
  );

const createExam = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.CREATE_EXAM_LOADING,
    Actions.CREATE_EXAM_SUCCESS,
    Actions.CREATE_EXAM_ERROR,
    examsService.createExam,
    query
  );

const getExamStudents = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.EXAM_STUDENTS_LOADING,
    Actions.EXAM_STUDENTS_SUCCESS,
    Actions.EXAM_STUDENTS_ERROR,
    examsService.getExamStudents,
    query
  );

const getSubjectSkills = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.SUBJECT_SKILLS_LOADING,
    Actions.SUBJECT_SKILLS_SUCCESS,
    Actions.SUBJECT_SKILLS_ERROR,
    examsService.getSubjectSkills,
    query
  );

const updateExamMarkList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.EXAM_MARK_LIST_LOADING,
    Actions.EXAM_MARK_LIST_SUCCESS,
    Actions.EXAM_MARK_LIST_ERROR,
    examsService.updateExamMarkList,
    query
  );

const updateExam = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.UPDATE_EXAM_LOADING,
    Actions.UPDATE_EXAM_SUCCESS,
    Actions.UPDATE_EXAM_ERROR,
    examsService.updateExam,
    query
  );

const getSubjectDetail = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.SUBJECT_DETAIL_LOADING,
    Actions.SUBJECT_DETAIL_SUCCESS,
    Actions.SUBJECT_DETAIL_ERROR,
    examsService.getSubjectDetail,
    query
  );
