import moment from "moment";
import { dt } from "@mb";
import Utils from "../../@mb/Utils/Utils";
import subjectService from "../../appServices/subjectService";

const parseDataForStudentAttendance = (data, date = dt.today()) => {
  const start = date.clone().startOf("month");
  const end = date.clone().endOf("month");
  const d = dt.getDateRange(start, end);
  const parsed = d.map((item) => ({
    date: item,
    count:
      data?.find(
        (k) =>
          moment(k.date).format("L") ===
          moment(item, dt.formats.DateMonthYear4).format("L")
      )?.count ?? 0,
  }));
  return parsed;
};

/**
 *
 * @param {Object} date, count
 * @returns {Object} date, count and total count
 */
const reformatDataForStudentAttendance = (
  data,
  total,
  addedStudents,
  exitedStudents
) => {
  let totals = [];

  data.reduce(
    (prev, { date }) => {
      const count =
        prev.count +
        (addedStudents?.find((a) =>
          dt
            .toMomentDate(a.date, false, "YYYY-MM-DD")
            .isSame(dt.toMomentDate(date, false, "DD/MM/YYYY"), "day")
        )?.count ?? 0) -
        (exitedStudents?.find((a) =>
          dt
            .toMomentDate(a.date, false, "YYYY-MM-DD")
            .isSame(dt.toMomentDate(date, false, "DD/MM/YYYY"), "day")
        )?.count ?? 0);

      const res = {
        date,
        count,
      };
      totals.push(res);
      return res;
    },
    { ...data[0], count: total }
  );

  const result = data.map((d, i) => ({
    ...d,
    total: totals[i].count,
    absentStudentPercentage: `${Utils.precise(
      !!totals[i].count ? (d.count / totals[i].count) * 100 : 0,
      1
    )}`,
  }));
  return result;
};

const getReportCardData = (content, { type, colInfo }, query) => {
  return new Promise(async (resolve, reject) => {
    if (
      type === "syllabusCoverage" ||
      type === "quizResult" ||
      type === "copyChecking"
    ) {
      if (!!content) {
        //Build column and data(only for copy checking)
        resolve({
          data: content,
          col: getCol(content, type),
        });
      } else {
        //Build data and column
        const { campusId, dateRangeTo: date } = query;
        try {
          const gradeSubjectList = await subjectService.fetchGradeSubject({
            campusId,
            date,
          });

          const tableDataObj = gradeSubjectList.reduce((pre, current) => {
            const { subject, class: grade, students } = current;

            if (pre[grade]) {
              pre[grade][subject] = 0;
            } else {
              pre[grade] = { grade };
              pre[grade][subject] = 0;
              pre[grade]["students"] = students || 0;
            }

            return pre;
          }, {});
          const content = Object.values(tableDataObj);
          const col = getCol(content, type);
          resolve({ data: content, col });
        } catch (error) {
          console.log(error);
          reject(error);
        }
      }
    } else {
      resolve({ data: content, col: colInfo });
    }
  });
};

//TODO: We'll build if avg is required in monthly report of copy checking;
// const getData = async (query, content, type) => {
//   if (type === "copyChecking") {
//     const { campusId, dateRangeTo: date } = query;
//     const gradeSubjectList = await subjectService.fetchGradeSubject({
//       campusId,
//       date,
//     });
//   }

//   return content;
// };
const getCol = (data, type) => {
  let col = [];

  data.forEach((item) => {
    const keys = Object.keys(item);

    keys.forEach((sub) => {
      if (col.find((c) => c.dataIndex === sub) || sub === "students") {
        return;
      }

      col.push({
        title:
          type === "quizResult" && sub !== "grade"
            ? `${Utils.startcase(sub)} (%)`
            : Utils.startcase(sub),
        dataIndex: sub,
        key: sub,
        inputType: "number",
        editable: sub !== "grade",
        render(text, record) {
          return {
            props: {
              ...(sub !== "grade" && {
                style: { background: getCellColor(parseInt(text), type) },
              }),
            },
            children: <div>{text}</div>,
          };
        },
      });
    });
  });

  return col;
};

const getCellColor = (val, type) => {
  switch (type) {
    case "syllabusCoverage":
      return getSyllabusCoverageCellColor(val);
    case "quizConduction":
      return getQuizConductionColor(val);
    case "quizResult":
      return getQuizResultCellColor(val);
    default:
      break;
  }
};

const getSyllabusCoverageCellColor = (val) => {
  let color;
  if (val > 4) {
    color = "#C00000";
  } else if (val === 3 || val === 4) {
    color = "#FFC000";
  } else if (val >= -2 && val <= 3) {
    color = "#A9D08E";
  } else if (val < -2) {
    color = "#C00000";
  } else {
    color = "#FFFFFF";
  }

  return color;
};

const getQuizConductionColor = (val) => {
  let color;
  if (val >= 90 && val <= 100) {
    color = "#00B050";
  } else if (val >= 80 && val <= 89) {
    color = "#FFC000";
  } else if (val < 80) {
    color = "#C00000";
  } else {
    color = "#FFFFFF";
  }

  return color;
};

const getQuizResultCellColor = (val) => {
  let color;
  if (val === "") {
    color = "#FFFFFF";
  } else if (val < 60) {
    color = "#C00000";
  } else if (val >= 60 && val <= 74) {
    color = "#FFC000";
  } else if (val >= 75 && val <= 89) {
    color = "#A9D08E";
  } else if (val >= 90 && val <= 100) {
    color = "#00B050";
  }

  return color;
};

const parseMonthlyReportGraphContentAsync = async (
  data,
  date = dt.today(),
  campusId
) => {
  if (Utils.isEmpty(data)) return;

  const months = dt.getShortMonths();
  const parsed = await Promise.all(
    months.map(async (month) => {
      let kpiInfo =
        data.find(
          (kpi) =>
            moment(kpi.dateRangeFrom).format("MMM") === month &&
            kpi.reportType === 2
        ) ?? null;

      let campusInfo =
        data.find(
          (kpi) =>
            moment(kpi.dateRangeFrom).format("MMM") === month &&
            kpi.reportType === 3
        ) ?? null;

      let content = kpiInfo ? JSON.parse(kpiInfo.content) : {};
      if (campusInfo && campusInfo.content) {
        content.campusUpKeep = JSON.parse(campusInfo.content);
      }

      const everyMonthData = {
        studentAttendance: getStudentAttendances(content, month),
        studentStrength: getStudentStrengths(content, month),
        dropoutStudent: getDropoutStudents(content, month),
        exitStudent: getExitStudents(content, month),
        relationshipWithParent: getRelationshipWithParents(content, month),
        teacherRetention: getTeacherRetentionList(content, month),
        teacherAdherence: getTeacherAdherenceList(content, month),
        fixture: getFixtures(content, month),
        teacherPerformance: getTeacherPerformanceList(content, month),
        slowLearner: getSlowLearners(content, month),
        complaintManagement: getComplaintManagement(content, month),
        quizResult: getQuizResult(content, month),
        quizConduction: getQuizConduction(content, month),
        syllabusCoverage: getSyllabusCoverage(content, month),
        copyChecking: await getGradeSubjects(content, date, month, campusId),
        campusUpKeep: getCampusUpKeep(content, month),
        content,
      };
      // console.log("Every month data", everyMonthData);
      return everyMonthData;
    })
  );

  const output = {
    complaintManagementList: parsed.map((item) => item.complaintManagement),
    studentAttendances: parsed.map((item) => item.studentAttendance),
    studentStrengths: parsed.map((item) => item.studentStrength),
    dropoutStudents: parsed.map((item) => item.dropoutStudent),
    exitStudents: parsed.map((item) => item.exitStudent),
    relationshipWithParents: parsed.map((item) => item.relationshipWithParent),
    teacherRetentionList: parsed.map((item) => item.teacherRetention),
    teacherAdherenceList: parsed.map((item) => item.teacherAdherence),
    fixtures: parsed.map((item) => item.fixture),
    teacherPerformanceList: parsed.map((item) => item.teacherPerformance),
    slowLearners: parsed.map((item) => item.slowLearner),
    quizResultList: parsed.map((item) => item.quizResult),
    quizConductionList: parsed.map((item) => item.quizConduction),
    syllabusCoverageList: parsed.map((item) => item.syllabusCoverage),
    copyCheckingList: parsed.map((item) => item.copyChecking),
    campusUpKeepList: parsed.map((item) => item.campusUpKeep),
  };
  return output;
};
const parseMonthlyReportGraphContent = (data, date = dt.today(), campusId) => {
  if (Utils.isEmpty(data)) return;

  const months = dt.getShortMonths();
  const parsed = months.map((month) => {
    let kpiInfo =
      data.find(
        (kpi) =>
          moment(kpi.dateRangeFrom).format("MMM") === month &&
          kpi.reportType === 2
      ) ?? null;

    let campusInfo =
      data.find(
        (kpi) =>
          moment(kpi.dateRangeFrom).format("MMM") === month &&
          kpi.reportType === 3
      ) ?? null;

    let content = kpiInfo ? JSON.parse(kpiInfo.content) : {};
    if (campusInfo && campusInfo.content) {
      content.campusUpKeep = JSON.parse(campusInfo.content);
    }

    const everyMonthData = {
      studentAttendance: getStudentAttendances(content, month),
      studentStrength: getStudentStrengths(content, month),
      dropoutStudent: getDropoutStudents(content, month),
      exitStudent: getExitStudents(content, month),
      relationshipWithParent: getRelationshipWithParents(content, month),
      teacherRetention: getTeacherRetentionList(content, month),
      teacherAdherence: getTeacherAdherenceList(content, month),
      fixture: getFixtures(content, month),
      teacherPerformance: getTeacherPerformanceList(content, month),
      slowLearner: getSlowLearners(content, month),
      complaintManagement: getComplaintManagement(content, month),
      quizResult: getQuizResult(content, month),
      quizConduction: getQuizConduction(content, month),
      syllabusCoverage: getSyllabusCoverage(content, month),
      copyChecking: getGradeSubjects(content, date, month, campusId),
      campusUpKeep: getCampusUpKeep(content, month),
      content,
    };
    // console.log("Every month data", everyMonthData);
    return everyMonthData;
  });

  const output = {
    complaintManagementList: parsed.map((item) => item.complaintManagement),
    studentAttendances: parsed.map((item) => item.studentAttendance),
    studentStrengths: parsed.map((item) => item.studentStrength),
    dropoutStudents: parsed.map((item) => item.dropoutStudent),
    exitStudents: parsed.map((item) => item.exitStudent),
    relationshipWithParents: parsed.map((item) => item.relationshipWithParent),
    teacherRetentionList: parsed.map((item) => item.teacherRetention),
    teacherAdherenceList: parsed.map((item) => item.teacherAdherence),
    fixtures: parsed.map((item) => item.fixture),
    teacherPerformanceList: parsed.map((item) => item.teacherPerformance),
    slowLearners: parsed.map((item) => item.slowLearner),
    quizResultList: parsed.map((item) => item.quizResult),
    quizConductionList: parsed.map((item) => item.quizConduction),
    syllabusCoverageList: parsed.map((item) => item.syllabusCoverage),
    copyCheckingList: parsed.map((item) => item.copyChecking),
    campusUpKeepList: parsed.map((item) => item.campusUpKeep),
  };
  return output;
};
const getGradeSubjects = ({ copyChecking }, date, month, campusId) => {
  let output = {
    copyChecking: 0,
    month,
  };
  let gradeSubjectList;
  return new Promise(async (resolve) => {
    if (!copyChecking || Utils.isEmpty(copyChecking)) {
      resolve(output);
      return;
    } else if (!copyChecking?.[0].students) {
      gradeSubjectList = await subjectService.fetchGradeSubject({
        campusId,
        date: dt.toShortDateString(
          moment(date).month(month).clone().endOf("month"),
          dt.formats.YearMonthDateDashed
        ),
      });

      copyChecking.forEach((copy) => {
        const gradeSubject = gradeSubjectList.find(
          (item) => item?.class === copy.grade
        );

        copy.students = gradeSubject?.students;
      });
    }

    output.copyChecking = getCopyCheckingPc(copyChecking);

    resolve(output);
    return;
  });
};

const getCopyCheckingPc = (copyChecking) => {
  //{Arabic: 3.2222, Englis: 34,333}
  const copyCheckingPc = {};
  const copyCheckingAvgList = [];
  copyChecking.forEach((copyCheckingItem) => {
    //[Arabic, English,...]
    const subjectList = Object.keys(copyCheckingItem);

    subjectList.forEach((sub) => {
      if (sub !== "grade" && sub !== "students") {
        if (copyCheckingPc[sub]) {
          copyCheckingPc[sub] =
            copyCheckingPc[sub] +
            (copyCheckingItem[sub] / copyCheckingItem.students) * 100;
        } else {
          copyCheckingPc[sub] =
            (copyCheckingItem[sub] / copyCheckingItem.students) * 100;
        }
      }
    });
  });

  //[Arabic, English,...]
  const subjectList = Object.keys(copyCheckingPc);
  subjectList.forEach((subj) => {
    copyCheckingAvgList.push(copyCheckingPc[subj] / subjectList.length);
  });

  return Utils.precise(
    copyCheckingAvgList.reduce((prev, curr) => (prev += curr), 0) /
      copyCheckingAvgList.length,
    1
  );
};

const getCampusUpKeep = ({ campusUpKeep }, month) => {
  let output = {
    overall: 0,
    month,
  };

  if (Utils.isEmpty(campusUpKeep)) return output;

  const val = campusUpKeep.reduce(
    (previous, current) => {
      if (!!current.value) {
        previous.sum += current.value;
        previous.count += 1;
      }

      return previous;
    },
    { sum: 0, count: 0 }
  );

  output.overall = Utils.precise(val.sum / val.count, 1);
  return output;
};

const getQuizResult = ({ quizResult }, month) => {
  let output = {
    quizResultTotalAvg: 0,
    month,
  };
  const subject = {};

  if (Utils.isEmpty(quizResult)) {
    return output;
  }

  quizResult.forEach((subj) => {
    Object.keys(subj).forEach((name) => {
      if (name !== "grade") {
        subject[name] = subject[name] || {};
        subject[name]["sum"] = (subject[name]["sum"] || 0) + subj[name];
        subject[name]["count"] = (subject[name]["count"] || 0) + 1;
      }
    });
  });

  const subjects = Object.keys(subject);

  subjects.forEach((name) => {
    subject[name]["avg"] = subject[name]["sum"] / subject[name]["count"];
  });

  const totalAvg = subjects.reduce((pre, name) => {
    return pre + subject[name]["avg"];
  }, 0);

  output.quizResultTotalAvg = Utils.precise(totalAvg / subjects?.length, 1);

  return output;
};
const getSyllabusCoverage = ({ syllabusCoverage }, month) => {
  let output = {
    lpe: 0,
    month,
  };
  const subject = {};

  if (Utils.isEmpty(syllabusCoverage)) {
    return output;
  }

  syllabusCoverage.forEach((subj) => {
    Object.keys(subj).forEach((name) => {
      if (name !== "grade") {
        subject[name] = subject[name] || {};
        if (subj[name] >= -4) {
          subject[name]["sum"] = (subject[name]["sum"] || 0) + 1;
        }
        subject[name]["count"] = (subject[name]["count"] || 0) + 1;
      }
    });
  });

  const subjects = Object.keys(subject);

  subjects.forEach((name) => {
    subject[name]["percent"] =
      (subject[name]["sum"] / subject[name]["count"]) * 100;
  });

  const lpe =
    subjects.reduce(function (previous, key) {
      return previous + subject[key].percent;
    }, 0) / subjects.length;

  output.lpe = Utils.precise(lpe, 1);
  return output;
};

const getQuizConduction = ({ quizConduction }, month) => {
  let output = {
    month,
    quizExecutionPercentage: 0,
  };
  if (!quizConduction) {
    return output;
  }
  const num = Utils.precise(
    (quizConduction?.[0].quizExecuted / quizConduction?.[0].quizReceived) *
      1 *
      100,
    1
  );
  output.quizExecutionPercentage = num;

  return output;
};
const getComplaintManagement = ({ complaintManagement }, month) => {
  let output = {
    month,
    totalEmailReceived: 0,
    slaFollowedPercentage: 0,
  };
  if (!complaintManagement) {
    return output;
  }
  output.totalEmailReceived = complaintManagement?.[0].totalEmailReceived;
  output.slaFollowedPercentage = complaintManagement?.[0].slaFollowedPercentage;

  return output;
};

const getSlowLearners = ({ slowLearnerManagement }, month) => {
  let output = {
    month,
    count: 0,
  };
  if (!slowLearnerManagement) {
    return output;
  }
  output.count = slowLearnerManagement?.[0].slowLearnerPercentage;

  return output;
};
const getTeacherPerformanceList = ({ teacherPerformance }, month) => {
  let output = {
    month,
    goodTeacher: 0.0,
    averageTeacher: 0.0,
    belowAverageTeacher: 0.0,
  };
  if (!teacherPerformance) {
    return output;
  }
  output.goodTeacher = Utils.precise(teacherPerformance?.[0].goodTeacher, 1);
  output.averageTeacher = Utils.precise(
    teacherPerformance?.[0].averageTeacher,
    1
  );
  output.belowAverageTeacher = Utils.precise(
    teacherPerformance?.[0].belowAverageTeacher,
    1
  );

  return output;
};
const getFixtures = ({ teacherAttendances }, month) => {
  let output = {
    month,
    fixture: 0,
  };
  if (!teacherAttendances) {
    return output;
  }
  output.fixture = teacherAttendances?.[0].fixtures;

  return output;
};

const getTeacherAdherenceList = ({ teacherAttendances }, month) => {
  let output = {
    month,
    absent: 0,
    late: 0,
  };
  if (!teacherAttendances) {
    return output;
  }
  output.absent = teacherAttendances?.[0].absent;
  output.late = teacherAttendances?.[0].late;

  return output;
};
const getTeacherRetentionList = ({ teacherStats }, month) => {
  let output = {
    month,
    retention: 0,
  };
  if (!teacherStats) {
    return output;
  }
  output.retention = teacherStats?.[0].overallRetention;

  return output;
};
const getRelationshipWithParents = ({ relationshipWithParents }, month) => {
  let output = {
    month,
    strong: 0,
  };
  if (!relationshipWithParents) {
    return output;
  }
  output.strong = relationshipWithParents?.[0].strongRelationship;

  return output;
};
const getExitStudents = ({ studentDropoutCounters }, month) => {
  let output = {
    month,
    current: 0,
    last: 0,
    previousToLast: 0,
  };
  if (!studentDropoutCounters) {
    return output;
  }
  output.current = studentDropoutCounters?.[0].currentPercentage;
  output.last = studentDropoutCounters?.[0].lastPercentage;
  output.previousToLast = studentDropoutCounters?.[0].previousToLastPercentage;

  return output;
};
const getDropoutStudents = ({ studentDropoutCounters }, month) => {
  let output = {
    month,
    count: 0,
  };
  if (!studentDropoutCounters) {
    return output;
  }
  output.count = studentDropoutCounters?.[0].current;

  return output;
};
const getStudentStrengths = ({ studentStrengths }, month) => {
  let output = {
    month,
    current: 0,
    last: 0,
    previousToLast: 0,
  };
  if (!studentStrengths) {
    return output;
  }
  output.current = studentStrengths?.[0].current;
  output.last = studentStrengths?.[0].last;
  output.previousToLast = studentStrengths?.[0].previousToLast;

  return output;
};

const getStudentAttendances = ({ studentAttendance }, month) => {
  let output = {
    month,
    absent: 0,
  };
  if (!studentAttendance) {
    return output;
  }
  output.absent = Utils.precise(
    studentAttendance.reduce((pre, cur) => {
      return pre + +cur.absentStudentPercentage;
    }, 0) / studentAttendance.length,
    1
  );

  return output;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseDataForStudentAttendance,
  reformatDataForStudentAttendance,
  getReportCardData,
  getCellColor,
  parseMonthlyReportGraphContent,
  parseMonthlyReportGraphContentAsync,
};
