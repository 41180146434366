import * as common from "../actions/Common";
import * as Actions from "../actions";

import kpiService from "../../appServices/kpiService";
import employeeService from "../../appServices/employeeService";
import {
  KPI_DAILY_REPORT_SAVING,
  KPI_DAILY_REPORT_SAVE_ERROR,
  KPI_DAILY_REPORT_SAVE_SUCCESS,
  KPIDB_DROPOUT_STUDENT_LOADING,
  KPIDB_DROPOUT_STUDENT_SUCCESS,
  KPIDB_DROPOUT_STUDENT_ERROR,
  KPI_DB_LOADING,
  KPI_DB_SUCCESS,
  KPI_DB_ERROR,
  KPIDB_STUDENT_EXIT_INFO_LOADING,
  KPIDB_STUDENT_EXIT_INFO_SUCCESS,
  KPIDB_STUDENT_EXIT_INFO_ERROR,
  KPI_DR_GRAPH_MANUAL_CONTENT_LOADING,
  KPI_DR_GRAPH_MANUAL_CONTENT_SUCCESS,
  KPI_DR_GRAPH_MANUAL_CONTENT_ERROR,
  KPI_LIST_LOADING,
  KPI_LIST_SUCCESS,
  KPI_LIST_ERROR,
  KPI_ITEM_LOADING,
  KPI_ITEM_SUCCESS,
  KPI_ITEM_ERROR,
  EXIT_STUDENT_COUNT_DR_LOADING,
  EXIT_STUDENT_COUNT_DR_SUCCESS,
  EXIT_STUDENT_COUNT_DR_ERROR,
  KPI_MONTHLY_REPORT_LOADING,
  KPI_MONTHLY_REPORT_SUCCESS,
  KPI_MONTHLY_REPORT_ERROR,
  KPI_MONTHLY_REPORT_SAVING,
  KPI_MONTHLY_REPORT_SAVE_SUCCESS,
  KPI_MONTHLY_REPORT_SAVE_ERROR,
  RELATIONSHIP_WITH_PARENTS_LOADING,
  RELATIONSHIP_WITH_PARENTS_SUCCESS,
  RELATIONSHIP_WITH_PARENTS_ERROR,
  MONTHLY_TEACHER_LOADING,
  MONTHLY_TEACHER_SUCCESS,
  MONTHLY_TEACHER_ERROR,
} from "../actions";

const fetchKpiInfo = (kpiInfo) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    KPI_DB_LOADING,
    KPI_DB_SUCCESS,
    KPI_DB_ERROR,
    kpiService.getKpiInfo,
    kpiInfo
  );

const fetchKpiInfoById = (query) => async (dispatch) => {
  return await common.thunkHelper(
    dispatch,
    KPI_ITEM_LOADING,
    KPI_ITEM_SUCCESS,
    KPI_ITEM_ERROR,
    kpiService.getKpiInfoById,
    query
  );
};

const submitDailyReport = (kpiInfo) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    KPI_DAILY_REPORT_SAVING,
    KPI_DAILY_REPORT_SAVE_SUCCESS,
    KPI_DAILY_REPORT_SAVE_ERROR,
    kpiService.submitDailyReport,
    kpiInfo
  );

const fetchDropoutStudent = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    KPIDB_DROPOUT_STUDENT_LOADING,
    KPIDB_DROPOUT_STUDENT_SUCCESS,
    KPIDB_DROPOUT_STUDENT_ERROR,
    kpiService.fetchDropoutStudent,
    query
  );

const fetchStudentExitInfo = (kpiInfo) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    KPIDB_STUDENT_EXIT_INFO_LOADING,
    KPIDB_STUDENT_EXIT_INFO_SUCCESS,
    KPIDB_STUDENT_EXIT_INFO_ERROR,
    kpiService.getKpiInfo,
    kpiInfo
  );

const fetchKpiList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    KPI_LIST_LOADING,
    KPI_LIST_SUCCESS,
    KPI_LIST_ERROR,
    kpiService.getPagedList,
    query
  );

const fetchGraphsManualContent = (query) => async (dispatch) => {
  return await common.thunkHelper(
    dispatch,
    KPI_DR_GRAPH_MANUAL_CONTENT_LOADING,
    KPI_DR_GRAPH_MANUAL_CONTENT_SUCCESS,
    KPI_DR_GRAPH_MANUAL_CONTENT_ERROR,
    kpiService.drDashboardItems,
    query
  );
};

const fetchExitStudentDR = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    EXIT_STUDENT_COUNT_DR_LOADING,
    EXIT_STUDENT_COUNT_DR_SUCCESS,
    EXIT_STUDENT_COUNT_DR_ERROR,
    kpiService.drDashboardItems,
    query
  );

const fetchKpiMonthlyReport = (query, sccessCb) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    KPI_MONTHLY_REPORT_LOADING,
    KPI_MONTHLY_REPORT_SUCCESS,
    KPI_MONTHLY_REPORT_ERROR,
    kpiService.fetchKpiMonthlyReport,
    query,
    null,
    sccessCb
  );

const fetchKpiMonthlyReportList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.KPI_MONTHLY_REPORT_LIST_LOADING,
    Actions.KPI_MONTHLY_REPORT_LIST_SUCCESS,
    Actions.KPI_MONTHLY_REPORT_LIST_ERROR,
    kpiService.getPagedList,
    query
  );

const submitMonthlyReport = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    KPI_MONTHLY_REPORT_SAVING,
    KPI_MONTHLY_REPORT_SAVE_SUCCESS,
    KPI_MONTHLY_REPORT_SAVE_ERROR,
    kpiService.submitMonthlyReport,
    query
  );

const fetchRelationshipWithParents = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    RELATIONSHIP_WITH_PARENTS_LOADING,
    RELATIONSHIP_WITH_PARENTS_SUCCESS,
    RELATIONSHIP_WITH_PARENTS_ERROR,
    kpiService.fetchRelationshipWithParents,
    query
  );

const setMonthlyReportDrawer = (data) => (dispatch) => {
  return dispatch(
    common.fetchSuccessWithData(Actions.KPI_MONTHLY_REPORT_DRAWER_SUCCESS, data)
  );
};

const fetchMonthlyTeacherList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    MONTHLY_TEACHER_LOADING,
    MONTHLY_TEACHER_SUCCESS,
    MONTHLY_TEACHER_ERROR,
    employeeService.getMonthlyTeacherList,
    query
  );
const submitCampusUpKeep = (kpiInfo) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.CAMPUS_UP_KEEP_SAVING,
    Actions.CAMPUS_UP_KEEP_SAVE_SUCCESS,
    Actions.CAMPUS_UP_KEEP_SAVE_ERROR,
    kpiService.submitCampusUpKeep,
    kpiInfo
  );
const fetchCampusUpKeepList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.CAMPUS_UP_KEEP_LIST_LOADING,
    Actions.CAMPUS_UP_KEEP_LIST_SUCCESS,
    Actions.CAMPUS_UP_KEEP_LIST_ERROR,
    kpiService.getPagedList,
    query
  );
const fetchCampusUpKeepById = (query) => async (dispatch) => {
  return await common.thunkHelper(
    dispatch,
    Actions.CAMPUS_UP_KEEP_ITEM_LOADING,
    Actions.CAMPUS_UP_KEEP_ITEM_SUCCESS,
    Actions.CAMPUS_UP_KEEP_ITEM_ERROR,
    kpiService.getCampusUpKeepById,
    query
  );
};
const fetchCampusUpKeepDashboard = (campusId) => async (dispatch) => {
  return await common.thunkHelper(
    dispatch,
    Actions.CAMPUS_UP_KEEP_DASHBOARD_LOADING,
    Actions.CAMPUS_UP_KEEP_DASHBOARD_SUCCESS,
    Actions.CAMPUS_UP_KEEP_DASHBOARD_ERROR,
    kpiService.fetchCampusUpKeepDashboard,
    campusId
  );
};
const fetchMonthlyReportGraphContent = (data) => async (dispatch) => {
  return await common.thunkHelper(
    dispatch,
    Actions.MR_GRAPH_CONTENT_LOADING,
    Actions.MR_GRAPH_CONTENT_SUCCESS,
    Actions.MR_GRAPH_CONTENT_ERROR,
    kpiService.fetchMonthlyReportGraphContent,
    data
  );
};

export {
  fetchKpiInfo,
  fetchKpiInfoById,
  submitDailyReport,
  fetchDropoutStudent,
  fetchStudentExitInfo,
  fetchExitStudentDR,
  fetchKpiList,
  fetchGraphsManualContent,
  fetchKpiMonthlyReport,
  fetchKpiMonthlyReportList,
  submitMonthlyReport,
  setMonthlyReportDrawer,
  fetchRelationshipWithParents,
  fetchMonthlyTeacherList,
  submitCampusUpKeep,
  fetchCampusUpKeepList,
  fetchCampusUpKeepById,
  fetchCampusUpKeepDashboard,
  fetchMonthlyReportGraphContent,
};
