export const QUESTION_ADD_SAVING = "QUESTION_ADD_SAVING";
export const QUESTION_ADD_SUCCESS = "QUESTION_ADD_SUCCESS";
export const QUESTION_ADD_ERROR = "QUESTION_ADD_ERROR";
export const QUESTION_LOADING = "QUESTION_LOADING";
export const QUESTION_SAVING = "QUESTION_SAVING";
export const QUESTION_SUCCESS = "QUESTION_SUCCESS";
export const QUESTION_ERROR = "QUESTION_ERROR";
export const QUESTION_LIST_LOADING = "QUESTION_LIST_LOADING";
export const QUESTION_LIST_SUCCESS = "QUESTION_LIST_SUCCESS";
export const QUESTION_LIST_ERROR = "QUESTION_LIST_ERROR";
export const QUESTION_CLASSS_LOADING = "QUESTION_CLASSS_LOADING";
export const QUESTION_CLASSS_SUCCESS = "QUESTION_CLASSS_SUCCESS";
export const QUESTION_CLASSS_ERROR = "QUESTION_CLASSS_ERROR";
export const QUESTION_SUBJ_LOADING = "QUESTION_SUBJ_LOADING";
export const QUESTION_SUBJ_SUCCESS = "QUESTION_SUBJ_SUCCESS";
export const QUESTION_SUBJ_ERROR = "QUESTION_SUBJ_ERROR";
export const QUESTION_BULK_UPLOAD_LOADING = "QUESTION_BULK_UPLOAD_LOADING";
export const QUESTION_BULK_UPLOAD_SUCCESS = "QUESTION_BULK_UPLOAD_SUCCESS";
export const QUESTION_BULK_UPLOAD_ERROR = "QUESTION_BULK_UPLOAD_ERROR";
export const QUESTION_MEDIA_SAVING = "QUESTION_MEDIA_SAVING";
export const QUESTION_MEDIA_SUCCESS = "QUESTION_MEDIA_SUCCESS";
export const QUESTION_MEDIA_ERROR = "QUESTION_MEDIA_ERROR";
