import { ConnectedRouter as Router } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";

import "./assets/vendors/style";
import "./styles/wieldy.less";
import configureStore, { history } from "./appRedux/store";
import App from "./containers/App/index";
import DragAndDropProvider from "@mb/context/DragAndDrop";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

export const store = configureStore();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: 3,
      refetchOnWindowFocus: false,
    },
  },
});

const NextApp = () => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <DragAndDropProvider>
      <Provider store={store}>
        <Router history={history} basename={baseUrl}>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </Router>
      </Provider>
    </DragAndDropProvider>
  </QueryClientProvider>
);

export default NextApp;
