import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getAdminUserLoggedIn,
  getAdminUserVerified,
} from "appRedux/actions/auth.actions";
import { utils } from "@mb";

import { Spin, Alert } from "antd";
const VerifyAuth = (props) => {
  const dispatch = useDispatch();
  const { location, history } = props;
  const { authRedirectionInfo, token, tokenVerified } = useSelector(
    ({ auth }) => auth
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");
    if (!code || utils.isEmpty(code)) {
      dispatch(getAdminUserLoggedIn());
    } else {
      dispatch(getAdminUserVerified(code));
    }
  }, []);

  useEffect(() => {
    // if (!utils.isEmpty(token)) {
    //   //history.push("/");
    //   return;
    // }
    if (utils.isEmpty(authRedirectionInfo)) {
      return;
    }

    if (
      !tokenVerified &&
      //authRedirectionInfo &&
      !utils.isEmpty(authRedirectionInfo.redirectUrl)
    ) {
      window.location.replace(authRedirectionInfo.redirectUrl);
    }
  }, [authRedirectionInfo]);

  useEffect(() => {
    if (!utils.isEmpty(token)) {
      history.push("/");
      return;
    }
  }, [token]);
  return <WaitComponent />;
};

export default VerifyAuth;

const WaitComponent = () => (
  <div className="gx-page-error-container">
    <div className="gx-page-error-content">
      <div className="gx-error-code">Wait</div>
      <div className="gx-text-center">
        <Spin spinning>
          <Alert
            message="Admin Auth!"
            description="Please wait, while authentication is in progress."
            type="info"
          />
        </Spin>
      </div>
    </div>
  </div>
);
