import { mb } from "@mb/api";

const feeService = {
  getChallansForApproval: async (query) =>
    mb.api.get(`api/fee/challansForApproval`, query),
  approveChallans: async (query) =>
    mb.api.post(`api/fee/approveChallans`, query),
};

export default feeService;
