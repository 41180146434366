export const KPI_ITEM_LOADING = "KPI_ITEM_LOADING";
export const KPI_ITEM_SUCCESS = "KPI_ITEM_SUCCESS";
export const KPI_ITEM_ERROR = "KPI_ITEM_ERROR";

export const KPI_DB_LOADING = "KPI_DB_LOADING";
export const KPI_DB_SUCCESS = "KPI_DB_SUCCESS";
export const KPI_DB_ERROR = "KPI_DB_ERROR";

export const KPI_DAILY_REPORT_ADD_SAVING = "KPI_DAILY_REPORT_ADD_SAVING";
export const KPI_DAILY_REPORT_SAVING = "KPI_DAILY_REPORT_SAVING";
export const KPI_DAILY_REPORT_SAVE_SUCCESS = "KPI_DAILY_REPORT_SAVE_SUCCESS";
export const KPI_DAILY_REPORT_SAVE_ERROR = "KPI_DAILY_REPORT_SAVE_ERROR";

export const KPIDB_DROPOUT_STUDENT_LOADING = "KPIDB_DROPOUT_STUDENT_LOADING";
export const KPIDB_DROPOUT_STUDENT_SUCCESS = "KPIDB_DROPOUT_STUDENT_SUCCESS";
export const KPIDB_DROPOUT_STUDENT_ERROR = "KPIDB_DROPOUT_STUDENT_ERROR";

export const EXIT_STUDENT_COUNT_DR_LOADING = "EXIT_STUDENT_COUNT_DR_LOADING";
export const EXIT_STUDENT_COUNT_DR_SUCCESS = "EXIT_STUDENT_COUNT_DR__SUCCESS";
export const EXIT_STUDENT_COUNT_DR_ERROR = "EXIT_STUDENT_COUNT_DR_ERROR";

export const KPIDB_STUDENT_EXIT_INFO_LOADING =
  "KPIDB_STUDENT_EXIT_INFO_LOADING";
export const KPIDB_STUDENT_EXIT_INFO_SUCCESS =
  "KPIDB_STUDENT_EXIT_INFO_SUCCESS";
export const KPIDB_STUDENT_EXIT_INFO_ERROR = "KPIDB_STUDENT_EXIT_INFO_ERROR";

export const KPI_LIST_LOADING = "KPI_LIST_LOADING";
export const KPI_LIST_SUCCESS = "KPI_LIST_SUCCESS";
export const KPI_LIST_ERROR = "KPI_LIST_ERROR";

export const KPI_DR_GRAPH_MANUAL_CONTENT_LOADING =
  "KPI_DR_GRAPH_MANUAL_CONTENT_LOADING";
export const KPI_DR_GRAPH_MANUAL_CONTENT_SUCCESS =
  "KPI_DR_GRAPH_MANUAL_CONTENT_SUCCESS";
export const KPI_DR_GRAPH_MANUAL_CONTENT_ERROR =
  "KPI_DR_GRAPH_MANUAL_CONTENT_ERROR";

export const KPI_MONTHLY_REPORT_LOADING = "KPI_MONTHLY_REPORT_LOADING";
export const KPI_MONTHLY_REPORT_SUCCESS = "KPI_MONTHLY_REPORT_SUCCESS";
export const KPI_MONTHLY_REPORT_ERROR = "KPI_MONTHLY_REPORT_ERROR";

export const KPI_MONTHLY_REPORT_LIST_LOADING = "KPI_MONTHLY_REPORT_LIST_LOADING";
export const KPI_MONTHLY_REPORT_LIST_SUCCESS = "KPI_MONTHLY_REPORT_LIST_SUCCESS";
export const KPI_MONTHLY_REPORT_LIST_ERROR = "KPI_MONTHLY_REPORT_LIST_ERROR";

export const KPI_MONTHLY_REPORT_DRAWER_LOADING = "KPI_MONTHLY_REPORT_DRAWER_LOADING";
export const KPI_MONTHLY_REPORT_DRAWER_SUCCESS = "KPI_MONTHLY_REPORT_DRAWER_SUCCESS";
export const KPI_MONTHLY_REPORT_DRAWER_ERROR = "KPI_MONTHLY_REPORT_DRAWER_ERROR";

export const RELATIONSHIP_WITH_PARENTS_LOADING = "RELATIONSHIP_WITH_PARENTS_LOADING";
export const RELATIONSHIP_WITH_PARENTS_SUCCESS = "RELATIONSHIP_WITH_PARENTS_SUCCESS";
export const RELATIONSHIP_WITH_PARENTS_ERROR = "RELATIONSHIP_WITH_PARENTS_ERROR";

export const KPI_MONTHLY_REPORT_SAVING = "KPI_MONTHLY_REPORT_SAVING";
export const KPI_MONTHLY_REPORT_SAVE_SUCCESS =
  "KPI_MONTHLY_REPORT_SAVE_SUCCESS";
export const KPI_MONTHLY_REPORT_SAVE_ERROR = "KPI_MONTHLY_REPORT_SAVE_ERROR";


export const CAMPUS_UP_KEEP_SAVING = "CAMPUS_UP_KEEP_SAVING";
export const CAMPUS_UP_KEEP_SAVE_SUCCESS = "CAMPUS_UP_KEEP_SAVE_SUCCESS";
export const CAMPUS_UP_KEEP_SAVE_ERROR = "CAMPUS_UP_KEEP_SAVE_ERROR";

export const CAMPUS_UP_KEEP_LIST_LOADING = "CAMPUS_UP_KEEP_LIST_LOADING";
export const CAMPUS_UP_KEEP_LIST_SUCCESS = "CAMPUS_UP_KEEP_LIST_SUCCESS";
export const CAMPUS_UP_KEEP_LIST_ERROR = "CAMPUS_UP_KEEP_LIST_ERROR";

export const CAMPUS_UP_KEEP_ITEM_LOADING = "CAMPUS_UP_KEEP_ITEM_LOADING";
export const CAMPUS_UP_KEEP_ITEM_SUCCESS = "CAMPUS_UP_KEEP_ITEM_SUCCESS";
export const CAMPUS_UP_KEEP_ITEM_ERROR = "CAMPUS_UP_KEEP_ITEM_ERROR";

export const CAMPUS_UP_KEEP_DASHBOARD_LOADING = "CAMPUS_UP_KEEP_DASHBOARD_LOADING";
export const CAMPUS_UP_KEEP_DASHBOARD_SUCCESS = "CAMPUS_UP_KEEP_DASHBOARD_SUCCESS";
export const CAMPUS_UP_KEEP_DASHBOARD_ERROR = "CAMPUS_UP_KEEP_DASHBOARD_ERROR";

export const MR_GRAPH_CONTENT_LOADING = "MR_GRAPH_CONTENT_LOADING";
export const MR_GRAPH_CONTENT_SUCCESS = "MR_GRAPH_CONTENT_SUCCESS";
export const MR_GRAPH_CONTENT_ERROR = "MR_GRAPH_CONTENT_ERROR";
