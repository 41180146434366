export const Kpi = {
  KpiDailyReportCategory: {
    10: "Student Management",
    20: "Class Management",
    30: "Teachers Management",
    40: "Parents Management",
    50: "IncidentReport",
    60: "Event Management",
  },
  KpiDailyReportItemType: {
    Auto: 0,
    Manual: 1,
  },
  KpiDailyReportItem: {
    10: "Absent Students",
    20: "Students Arrived Late",
    30: "Admission Confirmed",
    40: "Exit Students",
    50: "Exit Due To Own Reason",
    60: "Exit Due To Dissatisfaction",
    70: "Number Of Fixtures",
    80: "Technical Faults Occured In Class",
    90: "Classes Started Late",
    100: "Absent Teachers",
    110: "Late Arrival Teachers",
    120: "Number Of Teachers Required",
    130: "Teachers Hired",
    140: "Meeting With Parents",
    150: "Grievance Emails Received",
    160: "Handled Properly",
    170: "Not Handled Properly",
    180: "Critical Complaints",
    190: "Incident Reported",
    200: "Critical Incident",
    210: "Event Organized",
    220: "Number Of Good Observations",
    230: "Number Of Critical Observations",
  },
  KpiDashboardColumns: {
    AbsentStudents: 10,
    StudentsArrivedLate: 20,
    AdmissionConfirmed: 30,
    NumberOfExits: 40,
    ExitDueToOwnReason: 50,
    ExitDueToDissatisfaction: 60,
    NumberOfFixtures: 70,
    TechnicalFaultsOccuredInClass: 80,
    ClassesStartedLate: 90,
    AbsentTeachers: 100,
    LateArrivalTeachers: 110,
    NumberOfTeachersRequired: 120,
    TeachersHired: 130,
    MeetingWithParents: 140,
    GrievanceEmailsReceived: 150,
    HandledProperly: 160,
    NotHandledProperly: 170,
    CriticalComplaints: 180,
    IncidentReported: 190,
    CriticalIncident: 200,
    EventOrganized: 210,
    NumberOfGoodObservations: 220,
    NumberOfCriticalObservations: 230,
  },
  KpiDashboardGadgetType: {
    DropoutStudentReport: 10,
    StudentExitReport: 20,
  },
  BigXKpiToParsedMonthlyDataMapping: {
    1000: "studentAttendances:absent", //StudentAttendance
    1010: "exitStudents:current", //Students Exit
    1020: "slowLearners:count", //Slow Learners
    1030: "relationshipWithParents:strong", // RelationshipWithParents
    1040: "complaintManagementList:slaFollowedPercentage", // ComplaintManagement
    1050: "teacherRetentionList:retention", //TeacherRetention
    1060: "teacherAdherenceList:bigxkpi", //TeacherAttendance
    1070: "teacherPerformanceList:bigxkpi", //TeacherPerformance
    1080: "syllabusCoverageList:lpe", //SyllabusCoverageLPE
    1090: "quizConductionList:quizExecutionPercentage", //QuizConduction
    1100: "quizResultList:quizResultTotalAvg", //QuizResults
    1110: "copyCheckingList:copyChecking", //CopyChecking
    1120: "campusUpKeepList:overall", //CampusUpkeep
  },
};
