export const STUDENT_LIST_LOADING = "STUDENT_LIST_LOADING";
export const STUDENT_LIST_SUCCESS = "STUDENT_LIST_SUCCESS";
export const STUDENT_LIST_ERROR = "STUDENT_LIST_ERROR";

export const STUDENT_Y2D_LIST_LOADING = "STUDENT_Y2D_LIST_LOADING";
export const STUDENT_Y2D_LIST_SUCCESS = "STUDENT_Y2D_LIST_SUCCESS";
export const STUDENT_Y2D_LIST_ERROR = "STUDENT_Y2D_LIST_ERROR";

export const STUDENT_LIST_LAST_LOADING = "STUDENT_LIST_LAST_LOADING";
export const STUDENT_LIST_LAST_SUCCESS = "STUDENT_LIST_LAST_SUCCESS";
export const STUDENT_LIST_LAST_ERROR = "STUDENT_LIST_LAST_ERROR";

export const STUDENT_Y2D_LIST_LAST_LOADING = "STUDENT_Y2D_LIST_LAST_LOADING";
export const STUDENT_Y2D_LIST_LAST_SUCCESS = "STUDENT_Y2D_LIST_LAST_SUCCESS";
export const STUDENT_Y2D_LIST_LAST_ERROR = "STUDENT_Y2D_LIST_LAST_ERROR";

export const SECTION_LIST_LOADING = "SECTION_LIST_LOADING";
export const SECTION_LIST_SUCCESS = "SECTION_LIST_SUCCESS";
export const SECTION_LIST_ERROR = "SECTION_LIST_ERROR";

export const SET_CAMPUS = "SET_CAMPUS";
export const SET_SESSION = "SET_SESSION";
export const SET_GRADE = "SET_GRADE";
export const SET_SECTION = "SET_SECTION";
export const SET_SUBJECT = "SET_SUBJECT";

export const FEE_COUNT_LOADING = "FEE_COUNT_LOADING";
export const FEE_COUNT_SUCCESS = "FEE_COUNT_SUCCESS";
export const FEE_COUNT_ERROR = "FEE_COUNT_ERROR";

export const STUDENTS_AREA_WISE_LOADING = "STUDENTS_AREA_WISE_LOADING";
export const STUDENTS_AREA_WISE_SUCCESS = "STUDENTS_AREA_WISE_SUCCESS";
export const STUDENTS_AREA_WISE_ERROR = "STUDENTS_AREA_WISE_ERROR";

export const TEMP_EMP_LIST_LOADING = "TEMP_EMP_LIST_LOADING";
export const TEMP_EMP_LIST_SUCCESS = "TEMP_EMP_LIST_SUCCESS";
export const TEMP_EMP_LIST_ERROR = "TEMP_EMP_LIST_ERROR";

export const TOTAL_STUDENTS_DR_LOADING = "TOTAL_STUDENTS_DR_LOADING";
export const TOTAL_STUDENTS_DR_SUCCESS = "TOTAL_STUDENTS_DR_SUCCESS";
export const TOTAL_STUDENTS_DR_ERROR = "TOTAL_STUDENTS_DR_ERROR";

export const EMPLOYEE_COUNT_LOADING = "EMPLOYEE_COUNT_LOADING";
export const EMPLOYEE_COUNT_SUCCESS = "EMPLOYEE_COUNT_SUCCESS";
export const EMPLOYEE_COUNT_ERROR = "EMPLOYEE_COUNT_ERROR";

export const EXIT_STUDENTS_COUNT_CAMPUSWISE_LOADING = "EXIT_STUDENTS_COUNT_CAMPUSWISE_LOADING";
export const EXIT_STUDENTS_COUNT_CAMPUSWISE_SUCCESS = "EXIT_STUDENTS_COUNT_CAMPUSWISE_SUCCESS";
export const EXIT_STUDENTS_COUNT_CAMPUSWISE_ERROR = "EXIT_STUDENTS_COUNT_CAMPUSWISE_ERROR";

export const EXIT_STUDENTS_LIST_LOADING = "EXIT_STUDENTS_LIST_LOADING";
export const EXIT_STUDENTS_LIST_SUCCESS = "EXIT_STUDENTS_LIST_SUCCESS";
export const EXIT_STUDENTS_LIST_ERROR = "EXIT_STUDENTS_LIST_ERROR";

export const CONFIRMED_STUDENTS_COUNT_CAMPUSWISE_LOADING = "CONFIRMED_STUDENTS_COUNT_CAMPUSWISE_LOADING";
export const CONFIRMED_STUDENTS_COUNT_CAMPUSWISE_SUCCESS = "CONFIRMED_STUDENTS_COUNT_CAMPUSWISE_SUCCESS";
export const CONFIRMED_STUDENTS_COUNT_CAMPUSWISE_ERROR = "CONFIRMED_STUDENTS_COUNT_CAMPUSWISE_ERROR";

export const CONFIRMED_STUDENTS_LIST_LOADING = "CONFIRMED_STUDENTS_LIST_LOADING";
export const CONFIRMED_STUDENTS_LIST_SUCCESS = "CONFIRMED_STUDENTS_LIST_SUCCESS";
export const CONFIRMED_STUDENTS_LIST_ERROR = "CONFIRMED_STUDENTS_LIST_ERROR";

export const TOTAL_STUDENTS_COUNT_CAMPUSWISE_LOADING = "TOTAL_STUDENTS_COUNT_CAMPUSWISE_LOADING";
export const TOTAL_STUDENTS_COUNT_CAMPUSWISE_SUCCESS = "TOTAL_STUDENTS_COUNT_CAMPUSWISE_SUCCESS";
export const TOTAL_STUDENTS_COUNT_CAMPUSWISE_ERROR = "TOTAL_STUDENTS_COUNT_CAMPUSWISE_ERROR";

export const STUDENT_RETENTION_LOADING = "STUDENT_RETENTION_LOADING";
export const STUDENT_RETENTION_SUCCESS = "STUDENT_RETENTION_SUCCESS";
export const STUDENT_RETENTION_ERROR = "STUDENT_RETENTION_ERROR";

export const EMPLOYEE_RETENTION_LOADING = "EMPLOYEE_RETENTION_LOADING";
export const EMPLOYEE_RETENTION_SUCCESS = "EMPLOYEE_RETENTION_SUCCESS";
export const EMPLOYEE_RETENTION_ERROR = "EMPLOYEE_RETENTION_ERROR";

export const GLANCE_MEETINGS_LOADING = "GLANCE_MEETINGS_LOADING";
export const GLANCE_MEETINGS_SUCCESS = "GLANCE_MEETINGS_SUCCESS";
export const GLANCE_MEETINGS_ERROR = "GLANCE_MEETINGS_ERROR";

export const ANNOUNCEMENTS_LOADING = "ANNOUNCEMENTS_LOADING";
export const ANNOUNCEMENTS_SUCCESS = "ANNOUNCEMENTS_SUCCESS";
export const ANNOUNCEMENTS_ERROR = "ANNOUNCEMENTS_ERROR";

export const EXIT_STUDENTS_TRACKING_LOADING = "EXIT_STUDENTS_TRACKING_LOADING";
export const EXIT_STUDENTS_TRACKING_SUCCESS = "EXIT_STUDENTS_TRACKING_SUCCESS";
export const EXIT_STUDENTS_TRACKING_ERROR = "EXIT_STUDENTS_TRACKING_ERROR";


export const dashboardActionCreators = {
  setSubject: (subject) => ({
    type: SET_SUBJECT,
    payload: subject,
  }),
  setCampus: (campus) => ({
    type: SET_CAMPUS,
    payload: campus,
  }),
  setGrade: (grade) => ({
    type: SET_GRADE,
    payload: grade,
  }),
  setSection: (section) => ({
    type: SET_SECTION,
    payload: section,
  }),
  setSession: (session) => ({
    type: SET_SESSION,
    payload: session,
  }),
};
