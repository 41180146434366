import * as Actions from "../actions";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,
  campusListLoading: false,
  campusList: [],
  campusLocationsListLoading: false,
  campusLocationsList: [],
  sessionListLoading: false,
  sessionList: [],
  latestClassListLoading: false,
  latestClassList: [],
  latestSubjectListLoading: false,
  latestSubjectList: [],
  designationListLoading: false,
  designationList: [],
  employeeStatusListLoading: false,
  employeeStatusList: [],
  roleListLoading: false,
  roleList: [],
  departmentListLoading: false,
  departmentList: [],
  sectionListLoading: false,
  sectionList: [],
  termListLoading: false,
  termList: [],
  empAttTimetableListLoading: false,
  empAttTimetableList: [],
  bankAccountsLoading: false,
  bankAccounts: [],
  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.LOOKUP_TERM_LOADING: {
      return {
        ...state,
        termListLoading: true,
        termList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_TERM_SUCCESS: {
      return {
        ...state,
        termListLoading: false,
        termList: action.payload,
        error: null,
      };
    }
    case Actions.LOOKUP_CAMPUS_LOADING: {
      return {
        ...state,
        campusListLoading: true,
        campusList: [],
        sessionList: [],
        latestClassList: [],
        latestSubjectList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_SESSION_LOADING: {
      return {
        ...state,
        sessionListLoading: true,
        sessionList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_SECTION_LOADING: {
      return {
        ...state,
        sectionListLoading: true,
        sectionList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_CLASSS_LOADING: {
      return {
        ...state,
        latestClassListLoading: true,
        latestClassList: [],
        latestSubjectList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_SUBJ_LOADING: {
      return {
        ...state,
        latestSubjectListLoading: true,
        latestSubjectList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_DESIGNATION_LOADING: {
      return {
        ...state,
        designationListLoading: true,
        designationList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_ROLE_LOADING: {
      return {
        ...state,
        roleListLoading: true,
        roleList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_ROLE_SUCCESS: {
      return {
        ...state,
        roleListLoading: false,
        roleList: [...action.payload],
        error: null,
      };
    }
    case Actions.LOOKUP_ROLE_ERROR: {
      return {
        ...state,
        roleListLoading: false,
        roleList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_EMPLOYEE_STATUS_LOADING: {
      return {
        ...state,
        employeeStatusListLoading: true,
        employeeStatusList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_EMPLOYEE_STATUS_SUCCESS: {
      return {
        ...state,
        employeeStatusListLoading: false,
        employeeStatusList: [...action.payload],
        error: null,
      };
    }
    case Actions.LOOKUP_EMPLOYEE_STATUS_ERROR: {
      return {
        ...state,
        employeeStatusListLoading: false,
        employeeStatusList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    case Actions.LOOKUP_CAMPUS_SUCCESS: {
      return {
        ...state,
        campusListLoading: false,
        campusList: [...action.payload],
        error: null,
      };
    }
    case Actions.LOOKUP_SESSION_SUCCESS: {
      return {
        ...state,
        sessionListLoading: false,
        sessionList: [...action.payload],
        error: null,
      };
    }
    case Actions.LOOKUP_SECTION_SUCCESS: {
      return {
        ...state,
        sectionListLoading: false,
        sectionList: [...action.payload],
        error: null,
      };
    }
    case Actions.LOOKUP_CLASSS_SUCCESS: {
      return {
        ...state,
        latestClassListLoading: false,
        latestClassList: [...action.payload],
        error: null,
      };
    }
    case Actions.LOOKUP_SUBJ_SUCCESS: {
      return {
        ...state,
        latestSubjectListLoading: false,
        latestSubjectList: [...action.payload],
        error: null,
      };
    }
    case Actions.LOOKUP_DESIGNATION_SUCCESS: {
      return {
        ...state,
        designationListLoading: false,
        designationList: [...action.payload],
        error: null,
      };
    }

    case Actions.LOOKUP_CAMPUS_ERROR: {
      return {
        campusListLoading: false,
        campusList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_SESSION_ERROR: {
      return {
        sessionListLoading: false,
        sessionList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_SECTION_ERROR: {
      return {
        sectionListLoading: false,
        sectionList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_CLASSS_ERROR: {
      return {
        latestClassListLoading: false,
        latestClassList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_SUBJ_ERROR: {
      return {
        latestSubjectListLoading: false,
        latestSubjectList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_DESIGNATION_ERROR: {
      return {
        designationListLoading: false,
        designationList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_CAMPUS_LOCATIONS_LOADING: {
      return {
        ...state,
        campusLocationsListLoading: true,
        campusLocationsList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_CAMPUS_LOCATIONS_SUCCESS: {
      return {
        ...state,
        campusLocationsListLoading: false,
        campusLocationsList: [...action.payload],
        error: null,
      };
    }
    case Actions.LOOKUP_CAMPUS_LOCATIONS_ERROR: {
      return {
        ...state,
        campusLocationsListLoading: false,
        campusLocationsList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_DEPARTMENT_LOADING: {
      return {
        ...state,
        departmentListLoading: true,
        departmentList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        departmentListLoading: false,
        departmentList: [...action.payload],
        error: null,
      };
    }
    case Actions.LOOKUP_DEPARTMENT_ERROR: {
      return {
        ...state,
        departmentListLoading: false,
        departmentList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    case Actions.LOOKUP_EMP_ATT_TIMETABLE_LOADING: {
      return {
        ...state,
        empAttTimetableListLoading: true,
        empAttTimetableList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_EMP_ATT_TIMETABLE_SUCCESS: {
      return {
        ...state,
        empAttTimetableListLoading: false,
        empAttTimetableList: [...action.payload],
        error: null,
      };
    }
    case Actions.LOOKUP_EMP_ATT_TIMETABLE_ERROR: {
      return {
        ...state,
        empAttTimetableListLoading: false,
        empAttTimetableList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
      
    }
    case Actions.LOOKUP_BANK_ACCOUNTS_LOADING: {
      return {
        ...state,
        bankAccountsLoading: true,
        bankAccounts: [],
        error: null,
      };
    }
    case Actions.LOOKUP_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        bankAccountsLoading: false,
        bankAccounts: [...action.payload],
        error: null,
      };
    }
    case Actions.LOOKUP_BANK_ACCOUNTS_ERROR: {
      return {
        ...state,
        bankAccountsLoading: false,
        bankAccounts: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
      
    }
    default: {
      return {
        ...state,
        isLoading: false,
        latestClassListLoading: false,
      };
    }
  }
};
