import * as Actions from "../actions/dashboard.actions";
import { PageInfo } from "../../constants/GlobalConstants";
import { utils } from "../../@mb";

const INIT_STATE = {
  // isLoading: true,
  // alertMessage: "",
  // showMessage: false,
  // pageInfo: PageInfo,
  studentListLoading: false,
  studentList: [],
  studentY2DListLoading: false,
  studentY2DList: [],
  subject: null,
  campus: null,
  grade: null,
  section: null,
  session: null,
  sectionListLoading: false,
  sectionList: [],
  feeCountLoading: false,
  feeCount: [],
  studentsAreaWiseCountLoading: false,
  studentsAreaWiseCount: [],
  studentRetentionLoading: false,
  studentRetention: null,
  tempEmpListLoading: false,
  tempEmpList: [],
  totalStudentsDRLoading: false,
  totalStudentsDR: null,
  employeeCounterLoading: false,
  employeeCounter: [],
  employeeRetentionLoading: false,
  employeeRetention: null,
  exitStudentsCountCampusWiseLoading: false,
  exitStudentsCountCampusWise: null,
  exitStudentsListLoading: false,
  exitStudentsList: null,
  confirmedStudentsCountCampusWiseLoading: false,
  confirmedStudentsCountCampusWise: null,
  confirmedStudentsListLoading: false,
  confirmedStudentsList: null,
  totalStudentsCountCampusWiseLoading: false,
  totalStudentsCountCampusWise: null,
  studentListLastLoading: false,
  studentListLast: [],
  studentY2DListLastLoading: false,
  studentY2DListLast: [],
  announcementsLoading: false,
  announcements: [],
  exitStudentsTrackingListLoading: false,
  exitStudentsTrackingList: [],

  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.SET_SUBJECT: {
      return {
        ...state,
        subject: action.payload,
      };
    }
    case Actions.SET_CAMPUS: {
      return {
        ...state,
        campus: action.payload,
      };
    }
    case Actions.SET_SESSION: {
      return {
        ...state,
        session: action.payload,
      };
    }
    case Actions.SET_GRADE: {
      return {
        ...state,
        grade: action.payload,
      };
    }
    case Actions.SET_SECTION: {
      return {
        ...state,
        section: action.payload,
      };
    }
    case Actions.SECTION_LIST_LOADING: {
      return {
        ...state,
        sectionListLoading: true,
        sectionList: [],
        error: null,
      };
    }
    case Actions.SECTION_LIST_SUCCESS: {
      return {
        ...state,
        sectionListLoading: false,
        sectionList: [...action.payload],
        error: null,
      };
    }
    case Actions.SECTION_LIST_ERROR: {
      return {
        ...state,
        sectionListLoading: false,
        sectionList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.STUDENT_LIST_LOADING: {
      return {
        ...state,
        studentListLoading: true,
        studentList: [],
        error: null,
      };
    }
    case Actions.STUDENT_LIST_SUCCESS: {
      return {
        ...state,
        studentListLoading: false,
        studentList: action.payload,
        error: null,
      };
    }
    case Actions.STUDENT_LIST_ERROR: {
      return {
        studentListLoading: false,
        studentList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.STUDENT_Y2D_LIST_LOADING: {
      return {
        ...state,
        studentY2DListLoading: true,
        studentY2DList: [],
        error: null,
      };
    }
    case Actions.STUDENT_Y2D_LIST_SUCCESS: {
      return {
        ...state,
        studentY2DListLoading: false,
        studentY2DList: action.payload,
        error: null,
      };
    }
    case Actions.STUDENT_Y2D_LIST_ERROR: {
      return {
        studentY2DListLoading: false,
        studentY2DList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.STUDENT_LIST_LAST_LOADING: {
      return {
        ...state,
        studentListLastLoading: true,
        studentListLast: [],
        error: null,
      };
    }
    case Actions.STUDENT_LIST_LAST_SUCCESS: {
      return {
        ...state,
        studentListLastLoading: false,
        studentListLast: action.payload,
        error: null,
      };
    }
    case Actions.STUDENT_LIST_LAST_ERROR: {
      return {
        studentListLastLoading: false,
        studentListLast: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.STUDENT_Y2D_LIST_LAST_LOADING: {
      return {
        ...state,
        studentY2DListLastLoading: true,
        studentY2DLastList: [],
        error: null,
      };
    }
    case Actions.STUDENT_Y2D_LIST_LAST_SUCCESS: {
      return {
        ...state,
        studentY2DListLastLoading: false,
        studentY2DListLast: action.payload,
        error: null,
      };
    }
    case Actions.STUDENT_Y2D_LIST_LAST_ERROR: {
      return {
        studentY2DListLastLoading: false,
        studentY2DListLast: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    case Actions.FEE_COUNT_LOADING: {
      return {
        ...state,
        feeCountLoading: true,
        feeCount: [],
        error: null,
      };
    }
    case Actions.FEE_COUNT_SUCCESS: {
      return {
        ...state,
        feeCountLoading: false,
        feeCount: action.payload,
        error: null,
      };
    }
    case Actions.FEE_COUNT_ERROR: {
      return {
        feeCountLoading: false,
        feeCount: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.STUDENTS_AREA_WISE_LOADING: {
      return {
        ...state,
        studentsAreaWiseCountLoading: true,
        studentsAreaWiseCount: [],
        error: null,
      };
    }
    case Actions.STUDENTS_AREA_WISE_SUCCESS: {
      return {
        ...state,
        studentsAreaWiseCountLoading: false,
        studentsAreaWiseCount: action.payload,
        error: null,
      };
    }
    case Actions.STUDENTS_AREA_WISE_ERROR: {
      return {
        studentsAreaWiseCountLoading: false,
        studentsAreaWiseCount: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.TEMP_EMP_LIST_LOADING: {
      return {
        ...state,
        tempEmpListLoading: true,
        tempEmpList: [],
        error: null,
      };
    }
    case Actions.TEMP_EMP_LIST_SUCCESS: {
      return {
        ...state,
        tempEmpListLoading: false,
        tempEmpList: action.payload,
        error: null,
      };
    }
    case Actions.TEMP_EMP_LIST_ERROR: {
      return {
        tempEmpListLoading: false,
        tempEmpList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    case Actions.EXIT_STUDENTS_COUNT_CAMPUSWISE_LOADING: {
      return {
        ...state,
        exitStudentsCountCampusWiseLoading: true,
        exitStudentsCountCampusWise: [],
        error: null,
      };
    }
    case Actions.EXIT_STUDENTS_COUNT_CAMPUSWISE_SUCCESS: {
      return {
        ...state,
        exitStudentsCountCampusWiseLoading: false,
        exitStudentsCountCampusWise: action.payload,
        error: null,
      };
    }
    case Actions.EXIT_STUDENTS_COUNT_CAMPUSWISE_ERROR: {
      return {
        exitStudentsCountCampusWiseLoading: false,
        exitStudentsCountCampusWise: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.EXIT_STUDENTS_LIST_LOADING: {
      return {
        ...state,
        exitStudentsListLoading: true,
        exitStudentsList: [],
        error: null,
      };
    }
    case Actions.EXIT_STUDENTS_LIST_SUCCESS: {
      return {
        ...state,
        exitStudentsListLoading: false,
        exitStudentsList: action.payload,
        error: null,
      };
    }
    case Actions.EXIT_STUDENTS_LIST_ERROR: {
      return {
        exitStudentsListLoading: false,
        exitStudentsList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.CONFIRMED_STUDENTS_COUNT_CAMPUSWISE_LOADING: {
      return {
        ...state,
        confirmedStudentsCountCampusWiseLoading: true,
        confirmedStudentsCountCampusWise: [],
        error: null,
      };
    }
    case Actions.CONFIRMED_STUDENTS_COUNT_CAMPUSWISE_SUCCESS: {
      return {
        ...state,
        confirmedStudentsCountCampusWiseLoading: false,
        confirmedStudentsCountCampusWise: action.payload,
        error: null,
      };
    }
    case Actions.CONFIRMED_STUDENTS_COUNT_CAMPUSWISE_ERROR: {
      return {
        confirmedStudentsCountCampusWiseLoading: false,
        confirmedStudentsCountCampusWise: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.CONFIRMED_STUDENTS_LIST_LOADING: {
      return {
        ...state,
        confirmedStudentsListLoading: true,
        confirmedStudentsList: [],
        error: null,
      };
    }
    case Actions.CONFIRMED_STUDENTS_LIST_SUCCESS: {
      return {
        ...state,
        confirmedStudentsListLoading: false,
        confirmedStudentsList: action.payload,
        error: null,
      };
    }
    case Actions.CONFIRMED_STUDENTS_LIST_ERROR: {
      return {
        confirmedStudentsListLoading: false,
        confirmedStudentsList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.TOTAL_STUDENTS_COUNT_CAMPUSWISE_LOADING: {
      return {
        ...state,
        totalStudentsCountCampusWiseLoading: true,
        totalStudentsCountCampusWise: [],
        error: null,
      };
    }
    case Actions.TOTAL_STUDENTS_COUNT_CAMPUSWISE_SUCCESS: {
      return {
        ...state,
        totalStudentsCountCampusWiseLoading: false,
        totalStudentsCountCampusWise: action.payload,
        error: null,
      };
    }
    case Actions.TOTAL_STUDENTS_COUNT_CAMPUSWISE_ERROR: {
      return {
        totalStudentsCountCampusWiseLoading: false,
        totalStudentsCountCampusWise: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.TOTAL_STUDENTS_DR_LOADING: {
      return {
        ...state,
        totalStudentsDRLoading: true,
        totalStudentsDR: null,
        error: null,
      };
    }
    case Actions.TOTAL_STUDENTS_DR_SUCCESS: {
      return {
        ...state,
        totalStudentsDRLoading: false,
        totalStudentsDR: action.payload,
        error: null,
      };
    }
    case Actions.TOTAL_STUDENTS_DR_ERROR: {
      return {
        ...state,
        totalStudentsDRLoading: false,
        totalStudentsDR: null,
        error: {
          ...state.error,
          code: action?.payload?.code,
          message: action?.payload?.message,
        },
      };
    }
    case Actions.EMPLOYEE_COUNT_LOADING: {
      return {
        ...state,
        employeeCounterLoading: true,
        employeeCounter: [],
        error: null,
      };
    }
    case Actions.EMPLOYEE_COUNT_SUCCESS: {
      return {
        ...state,
        employeeCounterLoading: false,
        employeeCounter: action.payload,
        error: null,
      };
    }
    case Actions.EMPLOYEE_COUNT_ERROR: {
      return {
        employeeCounterLoading: false,
        employeeCounter: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.STUDENT_RETENTION_LOADING: {
      return {
        ...state,
        studentRetentionLoading: true,
        studentRetention: {},
        error: null,
      };
    }
    case Actions.STUDENT_RETENTION_SUCCESS: {
      return {
        ...state,
        studentRetentionLoading: false,
        studentRetention: { ...action.payload },
        error: null,
      };
    }
    case Actions.STUDENT_RETENTION_ERROR: {
      return {
        ...state,
        studentRetentionLoading: false,
        studentRetention: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.EMPLOYEE_RETENTION_LOADING: {
      return {
        ...state,
        employeeRetentionLoading: true,
        employeeRetention: null,
        error: null,
      };
    }
    case Actions.EMPLOYEE_RETENTION_SUCCESS: {
      return {
        ...state,
        employeeRetentionLoading: false,
        employeeRetention: { ...action.payload },
        error: null,
      };
    }
    case Actions.EMPLOYEE_RETENTION_ERROR: {
      return {
        ...state,
        employeeRetentionLoading: false,
        employeeRetention: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.GLANCE_MEETINGS_LOADING: {
      return {
        ...state,
        glanceMeetingsLoading: true,
        glanceMeetings: null,
        error: null,
      };
    }
    case Actions.GLANCE_MEETINGS_SUCCESS: {
      return {
        ...state,
        glanceMeetingsLoading: false,
        glanceMeetings: action.payload,
        error: null,
      };
    }
    case Actions.GLANCE_MEETINGS_ERROR: {
      return {
        ...state,
        glanceMeetingsLoading: false,
        glanceMeetings: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.ANNOUNCEMENTS_LOADING: {
      return {
        ...state,
        announcementsLoading: true,
        announcements: [],
        error: null,
      };
    }
    case Actions.ANNOUNCEMENTS_SUCCESS: {
      return {
        ...state,
        announcementsLoading: false,
        announcements: action?.payload,
        error: null,
      };
    }
    case Actions.ANNOUNCEMENTS_ERROR: {
      return {
        ...state,
        announcementsLoading: false,
        announcements: [],
        error: {
          ...state.error,
          code: action?.payload?.code,
          message: action?.payload?.message,
        },
      };
    }
    case Actions.EXIT_STUDENTS_TRACKING_LOADING: {
      return {
        ...state,
        exitStudentsTrackingListLoading: true,
        exitStudentsTrackingList: [],
        error: null,
      };
    }
    case Actions.EXIT_STUDENTS_TRACKING_SUCCESS: {
      return {
        ...state,
        exitStudentsTrackingListLoading: false,
        exitStudentsTrackingList: action?.payload,
        error: null,
      };
    }
    case Actions.EXIT_STUDENTS_TRACKING_ERROR: {
      return {
        ...state,
        exitStudentsTrackingListLoading: false,
        aexitStudentsTrackingList: [],
        error: {
          ...state.error,
          code: action?.payload?.code,
          message: action?.payload?.message,
        },
      };
    }

    default: {
      return {
        ...state,
        isLoading: false,
      };
    }
  }
};
