import * as Actions from "../actions/ec.actions";
import { PageInfo } from "constants/GlobalConstants";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,
  pageInfo: PageInfo,
  ecItemId: null,
  ecItem: null,
  ecItemSaving: false,
  ecItemLoading: false,
  ecListLoading: false,
  ecList: [],
  ecMediaUploading: false,
  ecMediaInfo: null,

  shouldFetchEcDetails: true,
  ecDetailItemSaving: false,
  ecDetailItem: null,
  ecDetailItemId: null,
  ecDetailItemDeleting: false,
  ecDetailItemDelted: false,

  ecDetailListLoading: false,
  ecDetailList: [],

  ecRecommendationListLoading: false,
  ecRecommendationListSaving: false,
  ecRecommendationList: [],
  ecRecommendationPageInfo: PageInfo,
  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.EC_LIST_LOADING: {
      return {
        ...state,
        ecListLoading: true,
        shouldFetchEcDetails: false,
        ecItem: null,
        ecList: [],
        error: null,
      };
    }
    case Actions.EC_LIST_SUCCESS: {
      return {
        ...state,
        ecListLoading: false,
        ecList: [...action.payload.data],
        shouldFetchEcDetails: true,
        pageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },

        error: null,
      };
    }
    case Actions.EC_LIST_ERROR: {
      return {
        ...state,
        ecListLoading: false,
        ecList: [],
        shouldFetchEcDetails: false,
        pageInfo: {
          ...PageInfo,
          HasNext: false,
          HasPrevious: false,
        },
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.EC_ITEM_ADDING:
    case Actions.EC_ITEM_SAVING: {
      return {
        ...state,
        ecItemSaving: true,
        error: null,
      };
    }
    case Actions.EC_ITEM_ADD_SUCCESS: {
      let item = action.payload;
      let stateList = state.ecList;
      stateList.push(item);
      return {
        ...state,
        ecItemSaving: false,
        ecItemId: item.id,
        shouldFetchEcDetails: true,
        ecList: [...stateList],
        error: null,
      };
    }

    case Actions.EC_ITEM_SAVE_SUCCESS: {
      let ecItemUpdated = action.payload;
      let index = state.ecList.findIndex((i) => i.id === ecItemUpdated.id);
      let stateList = state.ecList;
      if (index >= 0) {
        stateList[index] = ecItemUpdated;
      }
      return {
        ...state,
        ecItemSaving: false,
        ecItem: ecItemUpdated,
        ecList: [...stateList],
        shouldFetchEcDetails: true,
        error: null,
      };
    }
    case Actions.EC_ITEM_ADD_ERROR:
    case Actions.EC_ITEM_SAVE_ERROR: {
      return {
        ...state,
        ecItemSaving: false,
        ecItem: null,
        ecItemId: null,
        shouldFetchEcDetails: false,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    case Actions.EC_ITEM_LOADING: {
      return {
        ...state,
        ecItem: null,
        ecItemId: null,
        ecItemLoading: true,
        shouldFetchEcDetails: false,
        error: null,
      };
    }
    case Actions.EC_ITEM_SUCCESS: {
      return {
        ...state,
        ecItemLoading: false,
        ecItemId: null,
        ecItem: action.payload,
        shouldFetchEcDetails: true,
        error: null,
      };
    }
    case Actions.EC_ITEM_ERROR: {
      return {
        ...state,
        ecItemLoading: false,
        ecItem: null,
        ecItemId: null,
        shouldFetchEcDetails: false,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.EC_DETAILS_LIST_LOADING: {
      return {
        ...state,
        ecDetailListLoading: true,
        shouldFetchEcDetails: false,
        ecDetailItem: null,
        ecDetailList: [],
        error: null,
      };
    }
    case Actions.EC_DETAILS_LIST_SUCCESS: {
      return {
        ...state,
        ecDetailListLoading: false,
        shouldFetchEcDetails: true,
        ecDetailList: [...action.payload],
        error: null,
      };
    }
    case Actions.EC_DETAILS_LIST_ERROR: {
      return {
        ...state,
        ecDetailListLoading: false,
        shouldFetchEcDetails: false,
        ecDetailList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.EC_DETAILS_ITEM_ADDING:
    case Actions.EC_DETAILS_ITEM_SAVING: {
      return {
        ...state,
        ecDetailItemSaving: true,
        shouldFetchEcDetails: false,
        error: null,
      };
    }
    case Actions.EC_DETAILS_ITEM_ADD_SUCCESS: {
      let item = action.payload;
      let stateList = state.ecDetailList;
      stateList.push(item);
      return {
        ...state,
        ecDetailItemSaving: false,
        ecDetailItemId: item.id,
        //ecDetailItem: item,
        shouldFetchEcDetails: true,
        ecDetailList: [...stateList],
        error: null,
      };
    }

    case Actions.EC_DETAILS_ITEM_SAVE_SUCCESS: {
      let ecDetailItemUpdated = action.payload;
      let index = state.ecDetailList.findIndex(
        (i) => i.id === ecDetailItemUpdated.id
      );
      let stateList = state.ecDetailList;
      if (index >= 0) {
        stateList[index] = ecDetailItemUpdated;
      }
      return {
        ...state,
        ecDetailItemSaving: false,
        ecDetailItem: ecDetailItemUpdated,
        shouldFetchEcDetails: true,
        ecDetailList: [...stateList],
        error: null,
      };
    }
    case Actions.EC_DETAILS_ITEM_ADD_ERROR:
    case Actions.EC_DETAILS_ITEM_SAVE_ERROR: {
      return {
        ...state,
        ecDetailItemSaving: false,
        shouldFetchEcDetails: false,
        ecDetailItem: null,
        ecDetailItemId: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.EC_DETAILS_ITEM_DELETING: {
      return {
        ...state,
        ecDetailItemDeleting: true,
        ecDetailItemDelted: false,
        error: null,
      };
    }
    case Actions.EC_DETAILS_ITEM_DELETE_SUCCESS: {
      let { entityId: ecItemId, detailId: ecDetailItemId } = action.payload;
      let index = state.ecDetailList.findIndex(
        (k) => k.electronicContentId === ecItemId && k.id === ecDetailItemId
      );
      if (index < 0) {
        return {
          ...state,
        };
      }
      let stateList = state.ecDetailList;
      stateList.splice(index, 1);
      return {
        ...state,
        ecDetailItemDeleting: false,
        ecDetailItemDelted: true,
        ecDetailItem: null,
        ecDetailList: [...stateList],
        error: null,
      };
    }
    case Actions.EC_DETAILS_ITEM_DELETE_ERROR: {
      return {
        ...state,
        ecDetailItemDeleting: false,
        ecDetailItemDelted: false,
        error: {
          ...state.error,
          code: action.payload.code,
          message: `Unable to delete the requested Detail Item!`,
        },
      };
    }
    case Actions.EC_DETAILS_ITEM_REORDER_SUCCESS: {
      const newOrder = action.payload;
      let stateList = state.ecDetailList;

      const movingFromIndex = stateList.findIndex(
        (i) => i.id === newOrder.oldIndex
      );
      const movingToIndex = stateList.findIndex(
        (i) => i.id === newOrder.newIndex
      );

      let movingFromItem = stateList[movingFromIndex];
      let movingToItem = stateList[movingToIndex];

      const oldSortOrder = movingFromItem.sortOrder,
        newSortOrder = movingToItem.sortOrder;
      movingFromItem.sortOrder = oldSortOrder;
      movingToItem.sortOrder = newSortOrder;

      stateList[movingFromIndex] = movingToItem;
      stateList[movingToIndex] = movingFromItem;

      return {
        ...state,
        ecDetailList: [...stateList],
        error: null,
      };
    }

    case Actions.EC_RECOMMENDATIONS_LOADING: {
      return {
        ...state,
        ecRecommendationListLoading: true,
        ecRecommendationList: [],
        error: null,
      };
    }
    case Actions.EC_RECOMMENDATIONS_SUCCESS: {
      return {
        ...state,
        ecRecommendationListLoading: false,
        ecRecommendationList: [...action?.payload?.data],
        ecRecommendationPageInfo: {
          ...PageInfo,
          ...action.payload?.pageInfo,
        },
        error: null,
      };
    }
    case Actions.EC_RECOMMENDATIONS_ERROR: {
      return {
        ...state,
        ecRecommendationListLoading: false,
        ecRecommendationList: [],
        ecRecommendationPageInfo: {
          ...PageInfo,
          HasNext: false,
          HasPrevious: false,
        },
        error: {
          ...state.error,
          code: action.payload?.code,
          message: action.payload?.message,
        },
      };
    }
    case Actions.EC_RECOMMENDATION_SAVING: {
      return {
        ...state,
        ecRecommendationListSaving: true,
        error: null,
      };
    }
    case Actions.EC_RECOMMENDATION_SAVE_SUCCESS: {
      return {
        ...state,
        ecRecommendationListSaving: false,
        error: null,
      };
    }
    case Actions.EC_RECOMMENDATION_SAVE_ERROR: {
      return {
        ...state,
        ecRecommendationListSaving: false,
        error: {
          ...state.error,
          code: action.payload?.code,
          message: action.payload?.message,
        },
      };
    }

    default: {
      return {
        ...state,
        isLoading: false,
        ecListLoading: false,
        ecItemSaving: false,
      };
    }
  }
};
