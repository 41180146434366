import * as Actions from "../actions";
import * as common from "../actions/Common";
import { SearchType } from "constants/GlobalConstants";
import employeeService from "appServices/employeeService";
import studentService from "appServices/studentService";

const submitSearch = (searchType, query) => async (dispatch) => {
  switch (searchType) {
    case SearchType.Employee:
      return await common.thunkHelper(
        dispatch,
        Actions.SEARCH_LOADING,
        Actions.SEARCH_SUCCESS,
        Actions.SEARCH_ERROR,
        employeeService.getPagedList,
        query,
        {
          searchType,
          searchText: query.searchText,
          ...query.additional,
        }
      );
    case SearchType.Student:
      return await common.thunkHelper(
        dispatch,
        Actions.SEARCH_LOADING,
        Actions.SEARCH_SUCCESS,
        Actions.SEARCH_ERROR,
        studentService.getById,
        query.searchText,
        {
          searchType,
          searchText: query.searchText,
          ...query.additional,
        }
      );
    default:
      break;
  }
};

const searchEmployeeList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.SEARCH_EMPLOYEE_LIST_LOADING,
    Actions.SEARCH_EMPLOYEE_LIST_SUCCESS,
    Actions.SEARCH_EMPLOYEE_LIST_ERROR,
    employeeService.getPagedList,
    query,
    query.additional
  );

const resetSearch = () => async (dispatch) =>
  dispatch(common.fetchSuccess(Actions.MEDIA_RESET));

export { submitSearch, resetSearch, searchEmployeeList };
