import { mb } from "@mb/api";

const accountsService = {
  fetchChequeList: async (query) => {
    return mb.api.get(`api/bankReconcilation/viewCheque`, query);
  },
  /**
   * Retrieves the list of bank reconciliations.
   *
   * @param {Object} query - The query object containing the parameters for the request.
   * @param {string} query.accountNo - The account number.
   * @param {string} query.fromDate - The start date for the reconciliation search.
   * @param {string} query.toDate - The end date for the reconciliation search.
   * @return {Promise} A promise that resolves to the list of bank reconciliations.
   */
  getBankReconList: async (query) => {
    return mb.api.get(`api/bankReconcilation/getBankReconList`, query);
  },
  updateCheque: async (query) =>
    mb.api.post(`api/bankReconcilation/updateCheque`, query),
  fetchBankAccountsList: async () => {
    return mb.api.get(`api/bankAccounts`);
  },
  fetchBankAccountItem: async (id) => {
    return mb.api.get(`api/bankAccounts/getBankAccountItem`, id);
  },
  updateBankAccount: async (query) => mb.api.post(`api/bankAccounts`, query),
  updateBankRecon: async (query) =>
    mb.api.post(`api/bankReconcilation/updateBankRecon`, query),
  verifyVoucher: async ({ ledgVoucherNo, debit, credit, acctCode, date }) =>
    mb.api.get(`api/bankReconcilation/test`, {
      ledgVoucherNo,
      debit,
      credit,
      acctCode,
      date,
    }),
};

export default accountsService;
