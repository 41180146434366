import { useDispatch, useSelector } from "react-redux";
import { Avatar, Popover, Tooltip} from "antd";
import { utils } from "@mb";
import { userSignOut } from "appRedux/actions/auth.actions";
import { Link } from "react-router-dom";

const UserInfo = () => {
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  // console.log(authUser.employeeId)

  const logout = () => {
    dispatch(userSignOut());
    window.location = "/admin-web/VerifyAuth";
  };
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li >
        <Link to="/ChangePassword">
          <i className="reset-password" />
          Change Password
        </Link></li>
      <li><Link to="/smis">BMS</Link></li>
      <li onClick={logout}>Logout</li>
    </ul>
  );
  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <Tooltip placement="right" title={authUser.fullName}>
        <Avatar className="gx-size-30 gx-avatar gx-pointer">
          {utils.toInitials(authUser.fullName)}
        </Avatar>
      </Tooltip>
    </Popover>
  );
};
export default UserInfo;
