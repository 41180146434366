import * as Actions from "../actions";
import * as common from "../actions/Common";

import ecService from "appServices/ecService";

export {
  fetchECById,
  fetchECList,
  fetchECListWithDetails,
  submitAddECItem,
  submitUpdateECItem,
  fetchEcDetailsList,
  submitAddNewEcDetailItem,
  submitUpdateEcDetailItem,
  submitDeleteEcDetailItem,
  submitReorder,
  fetchECRecommendations,
  updateEcRecommendation,
};

const fetchECById = (id) => {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.EC_ITEM_LOADING));

      const result = await ecService.getById(id);
      return dispatch(
        common.fetchSuccessWithData(Actions.EC_ITEM_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.EC_ITEM_ERROR));
    }
  };
};

const submitAddECItem = (ec) => {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.EC_ITEM_ADDING));

      const result = await ecService.submitECItem(ec);
      return dispatch(
        common.fetchSuccessWithData(Actions.EC_ITEM_ADD_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.EC_ITEM_ADD_ERROR));
    }
  };
};
const submitUpdateECItem = (ec) => {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.EC_ITEM_SAVING));

      const result = await ecService.submitECItem(ec);
      return dispatch(
        common.fetchSuccessWithData(Actions.EC_ITEM_SAVE_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.EC_ITEM_SAVE_ERROR));
    }
  };
};

const fetchECList = (query) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.EC_LIST_LOADING));

    const result = await ecService.getPagedList(query);
    return dispatch(
      common.fetchSuccessWithData(Actions.EC_LIST_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.EC_LIST_ERROR));
  }
};

const fetchECListWithDetails = (query) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.EC_LIST_LOADING));

    const result = await ecService.getPagedListWithDetails(query);
    return dispatch(
      common.fetchSuccessWithData(Actions.EC_LIST_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.EC_LIST_ERROR));
  }
};


const fetchEcDetailsList = (id) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.EC_DETAILS_LIST_LOADING));

    const result = await ecService.getEcDetailsListByParentId(id);
    return dispatch(
      common.fetchSuccessWithData(Actions.EC_DETAILS_LIST_SUCCESS, result)
    );
  } catch (error) {
    return dispatch(common.fetchError(error, Actions.EC_DETAILS_LIST_ERROR));
  }
};

const submitAddNewEcDetailItem = (entity) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.EC_DETAILS_ITEM_ADDING));

    const result = await ecService.updateEcDetailItem(entity);
    return dispatch(
      common.fetchSuccessWithData(Actions.EC_DETAILS_ITEM_ADD_SUCCESS, result)
    );
  } catch (error) {
    return dispatch(
      common.fetchError(error, Actions.EC_DETAILS_ITEM_ADD_ERROR)
    );
  }
};

const submitUpdateEcDetailItem = (entity) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.EC_DETAILS_ITEM_SAVING));

    const result = await ecService.updateEcDetailItem(entity);
    return dispatch(
      common.fetchSuccessWithData(Actions.EC_DETAILS_ITEM_SAVE_SUCCESS, result)
    );
  } catch (error) {
    return dispatch(
      common.fetchError(error, Actions.EC_DETAILS_ITEM_SAVE_ERROR)
    );
  }
};
const submitDeleteEcDetailItem =
  (ecItemId, ecDetailItemId) => async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.EC_DETAILS_ITEM_DELETING));

      const result = await ecService.deleteEcDetailItem(
        ecItemId,
        ecDetailItemId
      );
      return dispatch(
        common.fetchSuccessWithData(
          Actions.EC_DETAILS_ITEM_DELETE_SUCCESS,
          result
        )
      );
    } catch (error) {
      return dispatch(
        common.fetchError(error, Actions.EC_DETAILS_ITEM_DELETE_ERROR)
      );
    }
  };

const submitReorder = (oldIndex, newIndex) => async (dispatch) => {
  dispatch(
    common.fetchSuccessWithData(Actions.EC_DETAILS_ITEM_REORDER_SUCCESS, {
      oldIndex,
      newIndex,
    })
  );
};

const fetchECRecommendations = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.EC_RECOMMENDATIONS_LOADING,
    Actions.EC_RECOMMENDATIONS_SUCCESS,
    Actions.EC_RECOMMENDATIONS_ERROR,
    ecService.getRecommendations,
    query
  );

const updateEcRecommendation = (query) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.EC_RECOMMENDATION_SAVING));

    const result = await ecService.updateEcRecommendation(query);
    dispatch(
      common.fetchSuccessWithData(
        Actions.EC_RECOMMENDATION_SAVE_SUCCESS,
        result
      )
    );
    dispatch(
      fetchECRecommendations({
        ...query.filter,
        pageSize: query.pageSize,
        pageNumber: 1,
      })
    );
  } catch (error) {
    return dispatch(
      common.fetchError(error, Actions.EC_RECOMMENDATION_SAVE_ERROR)
    );
  }
};
