import * as Actions from "../actions/notification.actions";
import { PageInfo } from "../../constants/GlobalConstants";
import { utils } from "../../@mb";

const INIT_STATE = {
  pageInfo: PageInfo,
  notificationLoading: false,
  notificationItem: null,
  notificationItemId: null,
  notificationItemSaving: false,
  notificationItemSaved: false,
  notificationItemCreating: false,
  notificationItemCreated: null,
  notificationList: [],
  notificationListLoading: false,
  activeFilter: {
    campusId: -1,
    sessionId: -1,
    classId: -1,
    sectionId: -1,
    searchText: null,
  },
  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.NOTIFICATION_LOADING: {
      return {
        ...state,
        notificationLoading: true,
        notificationItem: null,
        error: null,
      };
    }
    case Actions.NOTIFICATION_SUCCESS: {
      return {
        ...state,
        notificationLoading: false,
        notificationItem: action.payload,
        error: null,
      };
    }
    case Actions.NOTIFICATION_CREATING: {
      return {
        ...state,
        notificationCreating: true,
        notificationItem: null,
        notificationItemCreated: null,
        error: null,
      };
    }
    case Actions.NOTIFICATION_CREATED: {
      return {
        ...state,
        notificationCreating: false,
        notificationItem: action.payload,
        notificationItemCreated: action.payload.id,
        notificationItemId: action.payload.id,
        error: null,
      };
    }
    case Actions.NOTIFICATION_SAVING: {
      return {
        ...state,
        notificationItemSaving: true,
        notificationItemSaved: false,
        error: null,
      };
    }
    case Actions.NOTIFICATION_SAVED: {
      return {
        ...state,
        notificationItemSaving: false,
        notificationItemSaved: true,
        notificationItem: action.payload,
        notificationItemCreated:
          action.payload.id === state.notificationItemCreated
            ? null
            : state.notificationItemCreated,
        error: null,
      };
    }
    case Actions.NOTIFICATION_EDIT_SELECT: {
      return {
        ...state,
        notificationItemId: action.payload,
      };
    }
    case Actions.NOTIFICATION_ERROR: {
      return {
        ...state,
        notificationLoading: false,
        notificationItem: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.NOTIFICATION_LIST_LOADING: {
      return {
        ...state,
        notificationListLoading: true,
        error: null,
      };
    }
    case Actions.NOTIFICATION_LIST_SUCCESS: {
      return {
        ...state,
        notificationListLoading: false,
        notificationList: [...action.payload.data],
        pageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },
        error: null,
      };
    }
    case Actions.NOTIFICATION_LIST_ERROR: {
      return {
        ...state,
        notificationListLoading: false,
        notificationList: [],
        activeFilter: { ...INIT_STATE.activeFilter },
        pageInfo: {
          ...PageInfo,
          HasNext: false,
          HasPrevious: false,
        },
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.NOTIFICATION_ITEM_SAVED_HANDLED: {
      return {
        ...state,
        notificationItemSaved: false,
      };
    }
    case Actions.NOTIFICATION_SET_FILTER: {
      return {
        ...state,
        activeFilter: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
