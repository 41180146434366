import * as Actions from "../actions";
import { PageInfo } from "../../constants/GlobalConstants";
import { utils } from "../../@mb";

const INIT_STATE = {
  examPageInfo: PageInfo,
  examListLoading: false,
  examList: [],
  examError: {
    code: null,
    message: null,
  },
  assessmentPageInfo: PageInfo,
  assessmentListLoading: false,
  assessmentList: [],
  assessmentError: {
    code: null,
    message: null,
  },
  quizPageInfo: PageInfo,
  quizListLoading: false,
  quizList: [],
  quizError: {
    code: null,
    message: null,
  },
  createExamLoading: false,
  createExamSuccess: {},
  examStudentsLoading: false,
  examStudents: [],
  examStudentsError: {
    code: null,
    message: null,
  },
  examDetailLoading: false,
  examDetailError: {
    code: null,
    message: null,
  },
  examDetail: null,
  subjectSkills: null,
  subjectSkillsLoading: false,
  subjectSkillsError: {
    code: null,
    message: null,
  },
  examMarkList: null,
  examMarkListLoading: false,
  examMarkListError: {
    code: null,
    message: null,
  },
  subjectDetailLoading: null,
  subjectDetail: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.SUBJECT_DETAIL_LOADING: {
      return {
        ...state,
        subjectDetailLoading: true,
      };
    }
    case Actions.SUBJECT_DETAIL_SUCCESS: {
      return {
        ...state,
        subjectDetailLoading: false,
        subjectDetail: action.payload,
      };
    }
    case Actions.SUBJECT_DETAIL_ERROR: {
      return {
        ...state,
        subjectDetailLoading: false,
        subjectDetail: {
          error: {
            code: action.payload.code,
            message: action.payload.message,
          }
        }
      }
    }
    case Actions.UPDATE_EXAM_LOADING: {
      return {
        ...state,
        examDetailLoading: true,
        examDetailError: null,
      };
    }
    case Actions.UPDATE_EXAM_SUCCESS: {
      return {
        ...state,
        examDetailLoading: false,
        examDetailError: null,
      };
    }
    case Actions.UPDATE_EXAM_ERROR: {
      return {
        ...state,
        examDetailLoading: false,
        examDetailError: {
          ...state.examDetailError,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    case Actions.EXAM_MARK_LIST_LOADING: {
      return {
        ...state,
        examMarkListLoading: true,
        examMarkList: null,
        examMarkListError: null,
      };
    }
    case Actions.EXAM_MARK_LIST_SUCCESS: {
      return {
        ...state,
        examMarkListLoading: false,
        examMarkList: null,
        examMarkListError: null,
      };
    }
    case Actions.EXAM_MARK_LIST_ERROR: {
      return {
        ...state,
        examMarkListLoading: false,
        examMarkList: null,
        examMarkListError: {
          code: action?.payload?.code,
          message: action?.payload?.message,
        },
      };
    }
    case Actions.SUBJECT_SKILLS_LOADING: {
      return {
        ...state,
        subjectSkillsLoading: true,
        subjectSkills: null,
        subjectSkillsError: null,
      };
    }
    case Actions.SUBJECT_SKILLS_SUCCESS: {
      return {
        ...state,
        subjectSkillsLoading: false,
        subjectSkills: utils.isEmpty(action.payload) ? [] : action.payload,
        subjectSkillsError: null,
      };
    }
    case Actions.SUBJECT_SKILLS_ERROR: {
      return {
        ...state,
        subjectSkillsLoading: false,
        subjectSkills: null,
        subjectSkillsError: {
          ...state.subjectSkills,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.EXAM_STUDENTS_LOADING: {
      return {
        ...state,
        examStudentsLoading: true,
        examStudents: [],
        examStudentsError: null,
      };
    }
    case Actions.EXAM_STUDENTS_SUCCESS: {
      return {
        ...state,
        examStudentsLoading: false,
        examStudents: action.payload,
        examStudentsError: null,
      };
    }
    case Actions.EXAM_STUDENTS_ERROR: {
      return {
        ...state,
        examStudentsLoading: false,
        examStudents: null,
        examStudentsError: {
          ...state.examDetailError,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    case Actions.CREATE_EXAM_LOADING: {
      return {
        ...state,
        createExamLoading: true,
        examDetail: {},
        examDetailError: null,
      };
    }
    case Actions.CREATE_EXAM_SUCCESS: {
      return {
        ...state,
        createExamLoading: false,
        examDetail: { ...action.payload },
        examDetailError: null,
      };
    }
    case Actions.CREATE_EXAM_ERROR: {
      return {
        ...state,
        createExamLoading: false,
        examDetail: null,
        examDetailError: {
          ...state.examDetailError,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.EXAM_DETAIL_LOADING: {
      return {
        ...state,
        examDetailLoading: true,
        examDetail: {},
        examDetailError: null,
      };
    }
    case Actions.EXAM_DETAIL_SUCCESS: {
      return {
        ...state,
        examDetailLoading: false,
        examDetail: action.payload,
        examDetailError: null,
      };
    }
    case Actions.EXAM_DETAIL_ERROR: {
      return {
        ...state,
        examDetailLoading: false,
        examDetail: null,
        examDetailError: {
          ...state.examDetailError,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.WIDGET_QUIZ_LIST_LOADING: {
      return {
        ...state,
        quizListLoading: true,
        quizError: null,
      };
    }
    case Actions.WIDGET_QUIZ_LIST_SUCCESS: {
      return {
        ...state,
        quizListLoading: false,
        quizList:
          action.payload?.pageInfo.Current === 1
            ? [...action.payload.data]
            : [...state.quizList, ...action.payload.data],
        quizPageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },
        quizError: null,
      };
    }
    case Actions.WIDGET_QUIZ_LIST_ERROR: {
      return {
        ...state,
        quizListLoading: false,
        quizList: [...state.quizList],
        quizPageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },
        quizError: {
          ...state.quizError,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.WIDGET_ASSESSMENT_LIST_LOADING: {
      return {
        ...state,
        assessmentListLoading: true,
        assessmentError: null,
      };
    }
    case Actions.WIDGET_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        assessmentListLoading: false,
        assessmentList:
          action.payload?.pageInfo.Current === 1
            ? [...action.payload.data]
            : [...state.assessmentList, ...action.payload.data],
        assessmentPageInfo: {
          ...PageInfo,
          ...action?.payload?.pageInfo,
        },
        assessmentError: null,
      };
    }
    case Actions.WIDGET_ASSESSMENT_LIST_ERROR: {
      return {
        ...state,
        assessmentListLoading: false,
        assessmentPageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },
        assessmentError: {
          ...state.assessmentError,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.WIDGET_EXAM_LIST_LOADING: {
      return {
        ...state,
        examListLoading: true,
        examError: null,
      };
    }
    case Actions.WIDGET_EXAM_LIST_SUCCESS: {
      return {
        ...state,
        examListLoading: false,
        examList:
          action.payload?.pageInfo.Current === 1
            ? [...action.payload.data]
            : [...state.examList, ...action.payload.data],
        examPageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },
        examError: null,
      };
    }
    case Actions.WIDGET_EXAM_LIST_ERROR: {
      return {
        ...state,
        examListLoading: false,
        examPageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },
        examError: {
          ...state.examError,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
