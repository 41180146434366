import { Spin, Alert } from "antd";

const Reports = () => {
  console.log(window.location);
  window.location = "/Reports";
  return (
    <div className="gx-page-error-container">
      <div className="gx-page-error-content">
        <div className="gx-error-code">Wait</div>

        <div className="gx-text-center">
          <Spin spinning>
            <Alert
              message="Connecting to Reporting Server"
              description="Please wait while we are connecting to Reporting Server..."
              type="info"
            />
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default Reports;
