import * as Actions from "../actions";
import * as common from "../actions/Common";
import campusManagementService from "appServices/campusManagementService";

const saveRoom = (query) => async (dispatch) => {
  console.log("saveRoom", query);
  await common.thunkHelper(
    dispatch,
    Actions.ROOM_ITEM_SAVING,
    Actions.ROOM_ITEM_SAVED,
    Actions.ROOM_ITEM_SAVE_ERROR,
    campusManagementService.updateRoom,
    query
  );
};

const createRoom = (history, item) => async (dispatch, getState) => {
  // check redux store if newRoomItemId exists, if yes then redirect to Room/id
  // else create dummy entry, update redux store newRoomItemId and redirect to Room/id
  try {
    dispatch(common.fetchStart(Actions.ROOM_ITEM_ADD_SAVING));
    const existingDummyId = getState().campusManagement.newRoomItemId;
    if (existingDummyId !== null) {
      return history.push(`/Edit/${existingDummyId}`);
    }
    const result = await campusManagementService.updateRoom(item);
    dispatch(common.fetchSuccessWithData(Actions.ROOM_ITEM_ADD_SAVED, result));

    // return history.push(`/Edit/${result.id}`);
  } catch (ex) {
    return dispatch(common.fetchError(ex, Actions.ROOM_ITEM_SAVE_ADD_ERROR));
  }
};

const fetchRoomList = (query) => async (dispatch) => {
  await common.thunkHelper(
    dispatch,
    Actions.ROOM_LIST_LOADING,
    Actions.ROOM_LIST_SUCCESS,
    Actions.ROOM_LIST_ERROR,
    campusManagementService.fetchRoomList,
    query
  );
};
const fetchRoomOccupancy = (query) => async (dispatch) => {
  await common.thunkHelper(
    dispatch,
    Actions.ROOM_OCCUPANCY_LIST_LOADING,
    Actions.ROOM_OCCUPANCY_LIST_SUCCESS,
    Actions.ROOM_OCCUPANCY_LIST_ERROR,
    campusManagementService.fetchRoomOccupancy,
    query
  );
};

const fetchRoomById = (query) => async (dispatch) => {
  console.log("fetchRoomById", query);
  await common.thunkHelper(
    dispatch,
    Actions.ROOM_ITEM_LOADING,
    Actions.ROOM_ITEM_SUCCESS,
    Actions.ROOM_ITEM_ERROR,
    campusManagementService.fetchRoomById,
    query
  );
};

export {
  fetchRoomList,
  fetchRoomById,
  saveRoom,
  createRoom,
  fetchRoomOccupancy,
};
