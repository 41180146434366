const INIT_STATE = {
  error: null,
};
export default (state = INIT_STATE, action) => {
  const { error } = action;
  if (error) {
    return { error: error };
  }
  return state;
};
