export const STAFF_ATTENDANCE_LIST_LOADING = "STAFF_ATTENDANCE_LIST_LOADING";
export const STAFF_ATTENDANCE_LIST_SUCCESS = "STAFF_ATTENDANCE_LIST_SUCCESS";
export const STAFF_ATTENDANCE_LIST_ERROR = "STAFF_ATTENDANCE_LIST_ERROR";
export const STAFF_ATTENDANCE_LIST_RESET = "STAFF_ATTENDANCE_LIST_RESET";

export const STAFF_ATTENDANCE_ITEM_UPDATE = "STAFF_ATTENDANCE_ITEM_UPDATE";
export const STAFF_ATTENDANCE_ITEM_UPDATING = "STAFF_ATTENDANCE_ITEM_UPDATING";
export const STAFF_ATTENDANCE_ITEM_UPDATED = "STAFF_ATTENDANCE_ITEM_UPDATED";
export const STAFF_ATTENDANCE_ITEM_ERROR = "STAFF_ATTENDANCE_ITEM_ERROR";

export const resetStaffAttendanceList = () => ({
  type: STAFF_ATTENDANCE_LIST_RESET,
});

export const updateSingleAttendance = (entry) => ({
  type: STAFF_ATTENDANCE_ITEM_UPDATE,
  payload: entry,
});
