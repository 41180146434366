import * as Actions from "../actions";
import * as common from "../actions/Common";

import employeeService from "appServices/employeeService";
import usersService from "appServices/usersService";
import {
  EMPLOYEE_LIST_ERROR,
  EMPLOYEE_LIST_LOADING,
  EMPLOYEE_LIST_SUCCESS,
  EMPLOYEE_ITEM_LOADING,
  EMPLOYEE_ITEM_SUCCESS,
  EMPLOYEE_ITEM_ERROR,
  EMPLOYEE_ITEM_ADD_SAVING,
  EMPLOYEE_ITEM_ADD_SUCCESS,
  EMPLOYEE_ITEM_ADD_ERROR,
  EMPLOYEE_ITEM_SAVING,
  EMPLOYEE_ITEM_SAVED,
  EMPLOYEE_ITEM_SAVE_ERROR,
  EMPLOYEE_EMAILS_SENDING,
  EMPLOYEE_EMAILS_SENT,
  EMPLOYEE_EMAILS_ERROR,
  EMPLOYEE_MOBILIZATION_SAVING,
  EMPLOYEE_MOBILIZATION_SAVED,
  EMPLOYEE_MOBILIZATION_ERROR,
  
  USERS_LIST_LOADING,
  USERS_LIST_SUCCESS,
  USERS_LIST_ERROR,
} from "../actions";

export {
  fetchEmployeeList,
  fetchMobilizationInfo,
  submitUpdateEmployeeMobilization,
  fetchEmployeeById,
  saveEmployee,
  sendNotificationEmails,
  fetchUsersList,
  fetchUsers,
  fetchUsersById,
  saveUsers,
  saveNewUsers,
};

/**
 * ### Description
 * Updates the employee state from redux store.
 * The following props from state are updated:
 *  - employeeItemLoading
 *  - newEmployeeItemId
 *  - employeeItem
 *  - employeeMediaInfo
 *  - error
 *
 * ### Parameters
 * @param {Object} query - Object with id of the employee to fetch.
 *
 */
const fetchEmployeeById = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    EMPLOYEE_ITEM_LOADING,
    EMPLOYEE_ITEM_SUCCESS,
    EMPLOYEE_ITEM_ERROR,
    employeeService.getById,
    query
  );

const sendNotificationEmails = () => async (dispatch) => {
  await common.thunkHelper(
    dispatch,
    EMPLOYEE_EMAILS_SENDING,
    EMPLOYEE_EMAILS_SENT,
    EMPLOYEE_EMAILS_ERROR,
    employeeService.notifyHr
  );
};

const saveEmployee =
  (query, newEmployee = false) =>
  async (dispatch) =>
    newEmployee
      ? await common.thunkHelper(
          dispatch,
          EMPLOYEE_ITEM_ADD_SAVING,
          EMPLOYEE_ITEM_ADD_SUCCESS,
          EMPLOYEE_ITEM_ADD_ERROR,
          employeeService.submitEmployee,
          query
        )
      : await common.thunkHelper(
          dispatch,
          EMPLOYEE_ITEM_SAVING,
          EMPLOYEE_ITEM_SAVED,
          EMPLOYEE_ITEM_SAVE_ERROR,
          employeeService.submitEmployee,
          query
        );

const fetchEmployeeList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    EMPLOYEE_LIST_LOADING,
    EMPLOYEE_LIST_SUCCESS,
    EMPLOYEE_LIST_ERROR,
    employeeService.getPagedList,
    query,
    query?.additional
  );

const saveUsers = (query) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.USERS_ITEM_SAVING));
    const result = await usersService.submitUsers(query);
    return dispatch(
      common.fetchSuccessWithData(Actions.USERS_ITEM_SAVED, result)
    );
  } catch (error) {
    return dispatch(common.fetchError(error, Actions.USERS_ITEM_SAVE_ERROR));
  }
};

const saveNewUsers = (query) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.USERS_ITEM_ADD_SAVING));
    const result = await usersService.submitUsers(query);
    return dispatch(
      common.fetchSuccessWithData(Actions.USERS_ITEM_ADD_SAVED, result)
    );
  } catch (error) {
    return dispatch(
      common.fetchError(error, Actions.USERS_ITEM_SAVE_ADD_ERROR)
    );
  }
};
const fetchUsers = (query) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.USERS_LOADING));
    const result = await usersService.getPagedList(query, query?.searchText);
    return dispatch(
      common.fetchSuccessWithData(Actions.USERS_SUCCESS, result),
      console.log("thunk: ", result)
    );
  } catch (error) {
    return dispatch(
      common.fetchError(error, Actions.USERS_ERROR),
      console.log(error)
    );
  }
};

const fetchUsersById = (id) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.USERS_ITEM_LOADING));
    const result = await usersService.fetchUsersById(id);
    return dispatch(
      common.fetchSuccessWithData(Actions.USERS_ITEM_SUCCESS, result),
      console.log("thunk:", result)
    );
  } catch (error) {
    return dispatch(common.fetchError(error, Actions.USERS_ITEM_ERROR));
  }
};

const fetchUsersList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    USERS_LIST_LOADING,
    USERS_LIST_SUCCESS,
    USERS_LIST_ERROR,
    usersService.getPagedList,
    query,
    query?.searchText
  );

const fetchMobilizationInfo = (employee) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.EMPLOYEE_MOBILIZATION_LOADING));

    const result = await employeeService.loadMobilizationInfo(employee);
    return dispatch(
      common.fetchSuccessWithData(Actions.EMPLOYEE_MOBILIZATION_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(
      common.fetchError(error, Actions.EMPLOYEE_MOBILIZATION_ERROR)
    );
  }
};

const submitUpdateEmployeeMobilization = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    EMPLOYEE_MOBILIZATION_SAVING,
    EMPLOYEE_MOBILIZATION_SAVED,
    EMPLOYEE_MOBILIZATION_ERROR,
    employeeService.updateMobilizationInfo,
    query,
    query.additional
  );

/*
function submitEmployeeMedia(employeeId, formData) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.EMPLOYEE_MEDIA_SAVING));

      const result = await employeeService.submitEmployeeMedia(
        employeeId,
        formData
      );
      return dispatch(
        common.fetchSuccessWithData(Actions.EMPLOYEE_MEDIA_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.EMPLOYEE_MEDIA_ERROR));
    }
  };
}

function submitBulkEmployees(formData) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.EMPLOYEE_BULK_UPLOAD_LOADING));

      const result = await employeeService.submitBulkEmployees(formData);
      return dispatch(
        common.fetchSuccessWithData(
          Actions.EMPLOYEE_BULK_UPLOAD_SUCCESS,
          result
        )
      );
    } catch (error) {
      debugger;
      return dispatch(
        common.fetchError(error, Actions.EMPLOYEE_BULK_UPLOAD_ERROR)
      );
    }
  };
}*/
