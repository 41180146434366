import * as Actions from "../actions";
import * as common from "../actions/Common";
import dashboardService from "../../appServices/dashboardService";

export {
  fetchStudentList,
  fetchSectionListByGrade,
  fetchFeeCount,
  fetchStudentsAreaWise,
  fetchTempEmpList,
  fetchTotalStudentsDR,
  fetchEmployeeCounter,
  fetchStudentRetention,
  fetchEmployeeRetention,
  fetchExitStudentsCountCampusWise,
  fetchExitStudentsList,
  fetchStudentY2DList,
  fetchConfirmedStudentsCountCampusWise,
  fetchStudentListLast,
  fetchStudentY2DListLast,
  fetchConfirmedStudentsList,
  fetchTotalStudentsCountCampusWise,
  fetchGlanceMeetings,
  fetchAnnouncements,
  fetchExitStudentsTracking,
};

const fetchSectionListByGrade = (gradeId) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.SECTION_LIST_LOADING,
    Actions.SECTION_LIST_SUCCESS,
    Actions.SECTION_LIST_ERROR,
    dashboardService.loadSectionList,
    gradeId
  );

const fetchStudentList = (query) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.STUDENT_LIST_LOADING));
    const result = await dashboardService.loadStudentList(query);
    return dispatch(
      common.fetchSuccessWithData(Actions.STUDENT_LIST_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.STUDENT_LIST_ERROR));
  }
};
const fetchStudentY2DList = (query) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.STUDENT_Y2D_LIST_LOADING));
    const result = await dashboardService.loadStudentList(query);
    return dispatch(
      common.fetchSuccessWithData(Actions.STUDENT_Y2D_LIST_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.STUDENT_Y2D_LIST_ERROR));
  }
};
const fetchStudentListLast = (query) => async (dispatch) => {
  await common.thunkHelper(
    dispatch,
    Actions.STUDENT_LIST_LAST_LOADING,
    Actions.STUDENT_LIST_LAST_SUCCESS,
    Actions.STUDENT_LIST_LAST_ERROR,
    dashboardService.loadStudentList,
    query
  )
 };
 const fetchStudentY2DListLast = (query) => async (dispatch) => {
   try {
     dispatch(common.fetchStart(Actions.STUDENT_Y2D_LIST_LAST_LOADING));
     const result = await dashboardService.loadStudentList(query);
     return dispatch(
       common.fetchSuccessWithData(Actions.STUDENT_Y2D_LIST_LAST_SUCCESS, result)
     );
   } catch (error) {
     debugger;
     return dispatch(common.fetchError(error, Actions.STUDENT_Y2D_LIST_LAST_ERROR));
   }
 };

const fetchFeeCount = (date) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.FEE_COUNT_LOADING));
    const result = await dashboardService.loadFeeCount(date);
    return dispatch(
      common.fetchSuccessWithData(Actions.FEE_COUNT_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.FEE_COUNT_ERROR));
  }
};

const fetchStudentsAreaWise = (campusId) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.STUDENTS_AREA_WISE_LOADING));
    const result = await dashboardService.loadStudentsAreaWise(campusId);
    return dispatch(
      common.fetchSuccessWithData(Actions.STUDENTS_AREA_WISE_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.STUDENTS_AREA_WISE_ERROR));
  }
};
const fetchTempEmpList = (userId) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.TEMP_EMP_LIST_LOADING));
    const result = await dashboardService.loadTempEmpList(userId);
    return dispatch(
      common.fetchSuccessWithData(Actions.TEMP_EMP_LIST_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.TEMP_EMP_LIST_ERROR));
  }
};

// This is for the Total students displayed on the absent students KPI daily report graph
const fetchTotalStudentsDR = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.TOTAL_STUDENTS_DR_LOADING,
    Actions.TOTAL_STUDENTS_DR_SUCCESS,
    Actions.TOTAL_STUDENTS_DR_ERROR,
    dashboardService.fetchTotalStudentsDR,
    query
  );
const fetchEmployeeCounter = (query) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.EMPLOYEE_COUNT_LOADING));
    const result = await dashboardService.loadEmoloyeeCounter(query);
    return dispatch(
      common.fetchSuccessWithData(Actions.EMPLOYEE_COUNT_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.EMPLOYEE_COUNT_ERROR));
  }
};
const fetchExitStudentsCountCampusWise = (query) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.EXIT_STUDENTS_COUNT_CAMPUSWISE_LOADING));
    const result = await dashboardService.loadExitStudentsCountCampusWise(query);
    return dispatch(
      common.fetchSuccessWithData(Actions.EXIT_STUDENTS_COUNT_CAMPUSWISE_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.EXIT_STUDENTS_COUNT_CAMPUSWISE_ERROR));
  }
};

const fetchExitStudentsList = (query) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.EXIT_STUDENTS_LIST_LOADING));
    const result = await dashboardService.loadExitStudentsList(query);
    return dispatch(
      common.fetchSuccessWithData(Actions.EXIT_STUDENTS_LIST_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.EXIT_STUDENTS_LIST_ERROR));
  }
};
const fetchConfirmedStudentsCountCampusWise = (query) => async (dispatch) => {
  await common.thunkHelper(
    dispatch,
    Actions.CONFIRMED_STUDENTS_COUNT_CAMPUSWISE_LOADING,
    Actions.CONFIRMED_STUDENTS_COUNT_CAMPUSWISE_SUCCESS,
    Actions.CONFIRMED_STUDENTS_COUNT_CAMPUSWISE_ERROR,
    dashboardService.loadConfirmedStudentsCountCampusWise,
    query
  );
};
const fetchConfirmedStudentsList = (query) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.CONFIRMED_STUDENTS_LIST_LOADING));
    const result = await dashboardService.loadConfirmedStudentsList(query);
    return dispatch(
      common.fetchSuccessWithData(Actions.CONFIRMED_STUDENTS_LIST_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.CONFIRMED_STUDENTS_LIST_ERROR));
  }
};
const fetchTotalStudentsCountCampusWise = (query) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.TOTAL_STUDENTS_COUNT_CAMPUSWISE_LOADING));
    const result = await dashboardService.loadTotalStudentsCountCampusWise(query);
    return dispatch(
      common.fetchSuccessWithData(Actions.TOTAL_STUDENTS_COUNT_CAMPUSWISE_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.TOTAL_STUDENTS_COUNT_CAMPUSWISE_ERROR));
  }
};

const fetchStudentRetention = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.STUDENT_RETENTION_LOADING,
    Actions.STUDENT_RETENTION_SUCCESS,
    Actions.STUDENT_RETENTION_ERROR,
    dashboardService.fetchStudentRetention,
    query
  );

const fetchEmployeeRetention = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.EMPLOYEE_RETENTION_LOADING,
    Actions.EMPLOYEE_RETENTION_SUCCESS,
    Actions.EMPLOYEE_RETENTION_ERROR,
    dashboardService.fetchEmployeeRetention,
    query
  );
const fetchGlanceMeetings = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.GLANCE_MEETINGS_LOADING,
    Actions.GLANCE_MEETINGS_SUCCESS,
    Actions.GLANCE_MEETINGS_ERROR,
    dashboardService.fetchGlanceMeetings,
    query
  );
const fetchAnnouncements = (query) => async (dispatch) => 
  await common.thunkHelper(
    dispatch,
    Actions.ANNOUNCEMENTS_LOADING,
    Actions.ANNOUNCEMENTS_SUCCESS,
    Actions.ANNOUNCEMENTS_ERROR,
    dashboardService.fetchAnnouncements,
    query
  );
const fetchExitStudentsTracking = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.EXIT_STUDENTS_TRACKING_LOADING,
    Actions.EXIT_STUDENTS_TRACKING_SUCCESS,
    Actions.EXIT_STUDENTS_TRACKING_ERROR,
    dashboardService.fetchExitStudentsTracking,
    query
  );
