import { Layout, Tooltip } from "antd";
import { Link } from "react-router-dom";
import UserInfo from "components/UserInfo";
import { toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import { ScheduleOutlined } from "@ant-design/icons";

const { Header } = Layout;

const Topbar = () => {
  const dispatch = useDispatch();

  const { navStyle } = useSelector(({ settings }) => settings);
  const { navCollapsed, width } = useSelector(({ common }) => common);

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn gx-text-white icon icon-menu-unfold"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}
      <Link
        to="/home"
        className="gx-d-block gx-pointer"
      >
        <img alt="" src={require("assets/images/w-logo.png")} />
      </Link>

      <ul className="gx-header-notifications gx-ml-auto">
        <>
          <li className="gx-msg">
            <span className="gx-pointer gx-status-pos gx-d-block">
              <Link to="/smis">
                <Tooltip placement="bottom" title="BMS Old">
                  <ScheduleOutlined className="gx-text-white intro-step-at-a-glance" />
                </Tooltip>
              </Link>
              {/*<span className="gx-status gx-status-rtl gx-small gx-orange" />*/}
            </span>
          </li>
          <li className="gx-user-nav">
            <UserInfo />
          </li>
        </>
      </ul>
    </Header>
  );
};

export default Topbar;
