export const EMPLOYEE_LIST_LOADING = "EMPLOYEE_LIST_LOADING";
export const EMPLOYEE_LIST_SUCCESS = "EMPLOYEE_LIST_SUCCESS";
export const EMPLOYEE_LIST_ERROR = "EMPLOYEE_LIST_ERROR";

export const USERS_LIST_LOADING = "USERS_LIST_LOADING";
export const USERS_LIST_SUCCESS = "USERS_LIST_SUCCESS";
export const USERS_LIST_ERROR = "USERS_LIST_ERROR";

export const USERS_LOADING = "USERS_LOADING";
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_ERROR = "USERS_ERROR";

export const USERS_ITEM_LOADING = "USERS_ITEM_LOADING";
export const USERS_ITEM_SUCCESS = "USERS_ITEM_SUCCESS";
export const USERS_ITEM_ERROR = "USERS_ITEM_ERROR";

export const USERS_ITEM_SAVING = "USERS_ITEM_SAVING";
export const USERS_ITEM_SAVED = "USERS_ITEM_SAVED";
export const USERS_ITEM_SAVED_HANDLED = "USERS_ITEM_SAVED_HANDLED";
export const USERS_ITEM_SAVE_ERROR = "USERS_ITEM_SAVE_ERROR";

export const USERS_ITEM_ADD_SAVING = "USERS_ITEM_ADD_SAVING";
export const USERS_ITEM_ADD_SAVED = "USERS_ITEM_ADD_SAVED";
export const USERS_ITEM_SAVE_ADD_ERROR = "USERS_ITEM_SAVE_ADD_ERROR";

export const EMPLOYEE_ITEM_LOADING = "EMPLOYEE_ITEM_LOADING";
export const EMPLOYEE_ITEM_SUCCESS = "EMPLOYEE_ITEM_SUCCESS";
export const EMPLOYEE_ITEM_ERROR = "EMPLOYEE_ITEM_ERROR";

export const MONTHLY_TEACHER_LOADING = "MONTHLY_TEACHER_LOADING";
export const MONTHLY_TEACHER_SUCCESS = "MONTHLY_TEACHER_SUCCESS";
export const MONTHLY_TEACHER_ERROR = "MONTHLY_TEACHER_ERROR";

export const EMPLOYEE_ITEM_SAVING = "EMPLOYEE_ITEM_SAVING";
export const EMPLOYEE_ITEM_SAVED = "EMPLOYEE_ITEM_SAVED";
export const EMPLOYEE_ITEM_SAVED_HANDLED = "EMPLOYEE_ITEM_SAVED_HANDLED";
export const EMPLOYEE_ITEM_SAVE_ERROR = "EMPLOYEE_ITEM_SAVE_ERROR";

export const EMPLOYEE_ITEM_ADD_SAVING = "EMPLOYEE_ITEM_ADD_SAVING";
export const EMPLOYEE_ITEM_ADD_SUCCESS = "EMPLOYEE_ITEM_ADD_SUCCESS";
export const EMPLOYEE_ITEM_ADD_ERROR = "EMPLOYEE_ITEM_ADD_ERROR";

export const EMPLOYEE_MOBILIZATION_LOADING = "EMPLOYEE_MOBILIZATION_LOADING";
export const EMPLOYEE_MOBILIZATION_SAVING = "EMPLOYEE_MOBILIZATION_SAVING";
export const EMPLOYEE_MOBILIZATION_SAVED = "EMPLOYEE_MOBILIZATION_SAVED";
export const EMPLOYEE_MOBILIZATION_SUCCESS = "EMPLOYEE_MOBILIZATION_SUCCESS";
export const EMPLOYEE_MOBILIZATION_ERROR = "EMPLOYEE_MOBILIZATION_ERROR";

export const EMPLOYEE_EMAILS_SENDING = "EMPLOYEE_EMAILS_SENDING";
export const EMPLOYEE_EMAILS_SENT = "EMPLOYEE_EMAILS_SENT";
export const EMPLOYEE_EMAILS_ERROR = "EMPLOYEE_EMAILS_ERROR";
