export const ROOM_LIST_LOADING = "ROOM_LIST_LOADING";
export const ROOM_LIST_SUCCESS = "ROOM_LIST_SUCCESS";
export const ROOM_LIST_ERROR = "ROOM_LIST_ERROR";

export const ROOM_ITEM_LOADING = "ROOM_ITEM_LOADING";
export const ROOM_ITEM_SUCCESS = "ROOM_ITEM_SUCCESS";
export const ROOM_ITEM_ERROR = "ROOM_ITEM_ERROR";

export const ROOM_ITEM_SAVING = "ROOM_ITEM_SAVING";
export const ROOM_ITEM_SAVED = "ROOM_ITEM_SAVED";
export const ROOM_ITEM_SAVED_HANDLED = "ROOM_ITEM_SAVED_HANDLED";
export const ROOM_ITEM_SAVE_ERROR = "ROOM_ITEM_SAVE_ERROR";

export const ROOM_ITEM_ADD_SAVING = "ROOM_ITEM_ADD_SAVING";
export const ROOM_ITEM_ADD_SAVED = "ROOM_ITEM_ADD_SAVED";
export const ROOM_ITEM_SAVE_ADD_ERROR = "ROOM_ITEM_SAVE_ADD_ERROR";

export const ROOM_OCCUPANCY_LIST_LOADING = "ROOM_OCCUPANCY_LIST_LOADING";
export const ROOM_OCCUPANCY_LIST_SUCCESS = "ROOM_OCCUPANCY_LIST_SUCCESS";
export const ROOM_OCCUPANCY_LIST_ERROR = "ROOM_OCCUPANCY_LIST_ERROR";
