import { useEffect, useState } from "react";
import lookupService from "appServices/lookupService";
import { LookupType } from "appRedux/thunks";

/**
 *
 * @returns [status, campusList]
 */
export const STATUS_IDLE = "idle";
export const STATUS_LOADING = "loading";
export const STATUS_SUCCESS = "success";
export const STATUS_ERROR = "error";

const useUserCampusList = () => {
  const [state, setState] = useState([STATUS_IDLE, null]);
  useEffect(() => {
    setState([STATUS_LOADING, null]);
    lookupService
      .getList({
        type: LookupType.CampusByUser,
      })
      .then((data) => {
        setState([STATUS_SUCCESS, data]);
      })
      .catch((e) => setState(STATUS_ERROR, null));
  }, []);

  return state;
};

export default useUserCampusList;
