import * as Actions from "../actions";
import * as common from "../actions/Common";

import diaryService from "appServices/diaryService";

export { loadDiaryForSubject, loadDiaryDetails, submitDiaryDetailUpdate };

const loadDiaryForSubject = (diary) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.DIARY_ITEM_LOADING));

    const result = await diaryService.loadDiary(diary);
    return dispatch(
      common.fetchSuccessWithData(Actions.DIARY_ITEM_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.DIARY_ITEM_ERROR));
  }
};

const loadDiaryDetails = (diaryId) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.DIARY_DETAILS_LIST_LOADING));

    const result = await diaryService.loadDiaryDetails(diaryId);
    return dispatch(
      common.fetchSuccessWithData(Actions.DIARY_DETAILS_LIST_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.DIARY_DETAILS_LIST_ERROR));
  }
};
const submitDiaryDetailUpdate = (diaryDetailItem) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.DIARY_DETAIL_ITEM_SAVING));

    const result = await diaryService.updateDiaryDetail(diaryDetailItem);
    return dispatch(
      common.fetchSuccessWithData(
        Actions.DIARY_DETAIL_ITEM_SAVE_SUCCESS,
        result
      )
    );
  } catch (error) {
    debugger;
    return dispatch(
      common.fetchError(error, Actions.DIARY_DETAIL_ITEM_SAVE_ERROR)
    );
  }
};
/*
function fetchDiaryById(id) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.EMPLOYEE_ITEM_LOADING));

      const result = await diaryService.getById(id);
      return dispatch(
        common.fetchSuccessWithData(Actions.EMPLOYEE_ITEM_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.EMPLOYEE_ITEM_ERROR));
    }
  };
}

const fetchDiaryList = query => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.EMPLOYEE_LIST_LOADING));

    const result = await diaryService.getPagedList(query);
    return dispatch(
      common.fetchSuccessWithData(Actions.EMPLOYEE_LIST_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.EMPLOYEE_LIST_ERROR));
  }
}

function submitDiaryMedia(diaryId, formData) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.EMPLOYEE_MEDIA_SAVING));

      const result = await diaryService.submitDiaryMedia(
        diaryId,
        formData
      );
      return dispatch(
        common.fetchSuccessWithData(Actions.EMPLOYEE_MEDIA_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.EMPLOYEE_MEDIA_ERROR));
    }
  };
}

function submitBulkDiarys(formData) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.EMPLOYEE_BULK_UPLOAD_LOADING));

      const result = await diaryService.submitBulkDiarys(formData);
      return dispatch(
        common.fetchSuccessWithData(
          Actions.EMPLOYEE_BULK_UPLOAD_SUCCESS,
          result
        )
      );
    } catch (error) {
      debugger;
      return dispatch(
        common.fetchError(error, Actions.EMPLOYEE_BULK_UPLOAD_ERROR)
      );
    }
  };
}*/
