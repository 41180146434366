import {
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  RESET_ALL,
} from "../../constants/ActionTypes";
import jwtService from "appServices/jwtService";
import * as common from "./Common";
import { ADMIN_LOGIN_VERIFIED, ADMIN_VERIFICATION_URL_RECIEVED, ADMIN_LOGIN_VERIFING_REQ } from "./admin.actions";

// import userService from "../../services/User";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const ALLOW_SIGNIN = "ALLOW_SIGNIN";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const getAdminUserLoggedIn = () => {
  return (dispatch) => {
    //dispatch(common.fetchStart(ADMIN_LOGIN_VERIFING_REQ));

    jwtService
      .getAdminLoggedIn()
      .then((authUser) => {
        dispatch(common.fetchSuccessWithData(ADMIN_LOGIN_VERIFIED, authUser));
      })
      .catch((error) => {
        //console.error(`Error****:`, error);
        //console.log(error.response);
        //console.log(error.response.data.redirectUrl);
        dispatch(
          common.fetchError(error.response, ADMIN_VERIFICATION_URL_RECIEVED)
        );
      });
  };
};

export const getAdminUserVerified = (code) => {
  return (dispatch) => {
    //dispatch(common.fetchStart(ADMIN_LOGIN_VERIFING_REQ));
    jwtService
      .verifyAdminLogin(code)
      .then((authUser) => {
        dispatch(common.fetchSuccessWithData(ADMIN_LOGIN_VERIFIED, authUser));
      })
      .catch((error) => {
        console.error(`Error****:`, error);
        dispatch(common.fetchError(error));
      });
  };
};

export const getUser = () => {
  // return dispatch => dispatch({type:FETCH_ERROR, payload:null});
  return (dispatch) => {
    //dispatch({ type: FETCH_START });
    jwtService
      .verifyLoggedInUser()
      .then((authUser) => {
        //dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_DATA, payload: authUser });
      })
      .catch((error) => {
        debugger;
        console.error(`Error****:`, error);
        //dispatch(hideAuthLoader());
        return dispatch({
          type: FETCH_ERROR,
          payload: error,
        });
      });
  };
};

export const userSignUp = () => {
  return (dispatch) => {
    
    dispatch({ type: USER_DATA, payload: null });
  };
};
export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
// export const getAuthUserInfo = () => {
//   return (dispatch) => {
//     dispatch({type: FETCH_START});
// userService.getAuthUserInfo().then(({data}) => {
//       console.log("userSignIn: ", data);
//       if (data.result) {
//         dispatch({type: FETCH_SUCCESS});
//         dispatch({type: USER_DATA, payload: data.user});
//       } else {
//         dispatch({type: FETCH_ERROR, payload: data.error});
//       }
//     }).catch(function (error) {
//       dispatch({type: FETCH_ERROR, payload: error.message});
//       console.log("Error****:", error.message);
//     });
//   }
// };

export const userSignOut = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtService.signOut();
    dispatch({ type: SIGNOUT_USER_SUCCESS });
    setTimeout(() => {
      dispatch({ type: SIGNOUT_USER_SUCCESS });
      dispatch({ type: RESET_ALL });
    }, 2000);
  };
};

// export function getUserImage() {
//   return async dispatch => {
//     try {
//       const studentImage = await jwtService.getMyImage();
//       return dispatch({
//         type: USER_IMAGE_SET,
//         payload: studentImage
//       });
//     } catch (error) {
//       debugger;
//       return dispatch({
//         type: FETCH_ERROR,
//         payload: error //{Message, Code}
//       });
//     }
//   };
// }
