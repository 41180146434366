import * as Actions from "../actions/studentAttendance.actions";
import { PageInfo } from "../../constants/GlobalConstants";
import { utils } from "../../@mb";

const INIT_STATE = {
  studentAttendanceListLoading: false,
  studentAttendanceList: null,

  error: {
    code: null,
    message: null,
  },
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.STUDENT_ATTENDANCE_LIST_LOADING: {
      return {
        ...state,
        studentAttendanceListLoading: true,
        // studentAttendanceList: null,
        error: null,
      };
    }
    case Actions.STUDENT_ATTENDANCE_LIST_SUCCESS: {
      return {
        ...state,
        studentAttendanceListLoading: false,
        studentAttendanceList: { ...action.payload },
        error: null,
      };
    }
    case Actions.STUDENT_ATTENDANCE_LIST_ERROR: {
      return {
        studentAttendanceListLoading: false,
        studentAttendanceList: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.UPDATE_STUDENT_ATTENDANCE: {
      const { grNo, attendance } = action.payload;
      const { details } = state.studentAttendanceList;
      const existingEntryIndex = details.findIndex((x) => x.grNo === grNo);
      if (existingEntryIndex === -1) {
        return { ...state };
      }
      const existingEntry = details[existingEntryIndex];
      const updatedEntry = {
        ...existingEntry,
        attendance,
      };
      const updatedDetails = [
        ...details.slice(0, existingEntryIndex),
        updatedEntry,
        ...details.slice(existingEntryIndex + 1),
      ];
      const updatedStudentAttendanceList = {
        ...state.studentAttendanceList,
        details: updatedDetails,
      };
      return {
        ...state,
        studentAttendanceList: updatedStudentAttendanceList,
        studentAttendanceListLoading: false,
      };
    }
    default: {
      return {
        ...state,
        isLoading: false,
      };
    }
  }
};

export default reducer;
