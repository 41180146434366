import * as Actions from "../actions";
import * as common from "../actions/Common";
import attendanceTimetableService from "appServices/attendanceTimetableService";

const fetchAttendanceTimetable = () => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.ATTENDANCE_TIMETABLE_LIST_LOADING,
    Actions.ATTENDANCE_TIMETABLE_LIST_SUCCESS,
    Actions.ATTENDANCE_TIMETABLE_LIST_ERROR,
    attendanceTimetableService.fetchAttendanceTimetable
  );

const saveAttendanceTimetable = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.ATTENDANCE_TIMETABLE_ITEM_UPDATING,
    Actions.ATTENDANCE_TIMETABLE_ITEM_UPDATED,
    Actions.ATTENDANCE_TIMETABLE_ITEM_ERROR,
    attendanceTimetableService.saveAttendanceTimetable,
    query
  );

export { fetchAttendanceTimetable, saveAttendanceTimetable };
