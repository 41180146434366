import * as Actions from "../actions";
import * as common from "../actions/Common";

import questionService from "appServices/questionService";

export {
  fetchQuestionList,
  submitBulkQuestions,
  fetchQuestionById,
  submitAddNewQuestion,
  submitUpdateQuestion,
  submitQuestionMedia,
};

function fetchQuestionById(id) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.QUESTION_LOADING));

      const result = await questionService.getById(id);
      return dispatch(
        common.fetchSuccessWithData(Actions.QUESTION_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.QUESTION_ERROR));
    }
  };
}
function submitAddNewQuestion(question) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.QUESTION_ADD_SAVING));

      const result = await questionService.updateQuestion(question);
      return dispatch(
        common.fetchSuccessWithData(Actions.QUESTION_ADD_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.QUESTION_ADD_ERROR));
    }
  };
}
function submitUpdateQuestion(question) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.QUESTION_SAVING));

      const result = await questionService.updateQuestion(question);
      dispatch(
        common.fetchSuccessWithData(Actions.QUIZ_QUESTION_UPDATED, result)
      );
      return dispatch(
        common.fetchSuccessWithData(Actions.QUESTION_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.QUESTION_ERROR));
    }
  };
}
function fetchQuestionList(query) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.QUESTION_LIST_LOADING));

      const result = await questionService.getPagedList(query);
      //   selectedClass,
      //   selectedSubject
      // );
      //const result = cardList;
      return dispatch(
        common.fetchSuccessWithData(Actions.QUESTION_LIST_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.QUESTION_LIST_ERROR));
    }
  };
}

function submitQuestionMedia(questionId, formData) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.QUESTION_MEDIA_SAVING));

      const result = await questionService.submitQuestionMedia(
        questionId,
        formData
      );
      return dispatch(
        common.fetchSuccessWithData(Actions.QUESTION_MEDIA_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.QUESTION_MEDIA_ERROR));
    }
  };
}

function submitBulkQuestions(formData) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.QUESTION_BULK_UPLOAD_LOADING));

      const result = await questionService.submitBulkQuestions(formData);
      return dispatch(
        common.fetchSuccessWithData(
          Actions.QUESTION_BULK_UPLOAD_SUCCESS,
          result
        )
      );
    } catch (error) {
      debugger;
      return dispatch(
        common.fetchError(error, Actions.QUESTION_BULK_UPLOAD_ERROR)
      );
    }
  };
}
