import * as Actions from "../actions";
import { PageInfo } from "../../constants/GlobalConstants";
import { utils } from "../../@mb";

const INIT_STATE = {
  roomListLoading: false,
  roomList: [],
  roomOccupancyListLoading: false,
  roomOccupancyList: [],
  roomItem: null,
  roomItemLoading: false,
  roomItemSaving: false,
  roomItemSaved: false,
  newRoomItemId: null,
  roomPageInfo: PageInfo,
  roomLoadedFor: null,
  roomAddItemLoading: false,

  error: {
    code: null,
    message: null,
  },
};

const roomReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.ROOM_ITEM_LOADING: {
      return {
        ...state,
        roomItemLoading: true,
        roomItem: null,
        error: null,
      };
    }
    case Actions.ROOM_ITEM_SUCCESS: {
      return {
        ...state,
        roomItemLoading: false,
        //  newUsersItemId: null,
        roomItem: action.payload,
        error: null,
      };
    }
    case Actions.ROOM_ITEM_ERROR: {
      return {
        ...state,
        roomItemLoading: false,
        roomItem: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    case Actions.ROOM_LIST_LOADING: {
      return {
        ...state,
        roomListLoading: true,
        roomLoadedFor: null,
        error: null,
      };
    }
    case Actions.ROOM_LIST_SUCCESS: {
      return {
        ...state,
        roomListLoading: false,
        roomList: [...action.payload],
        error: null,
      };
    }
    case Actions.ROOM_LIST_ERROR: {
      return {
        ...state,
        roomListLoading: false,
        roomList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.ROOM_OCCUPANCY_LIST_LOADING: {
      return {
        ...state,
        roomOccupancyListLoading: true,
        roomOccupancyLoadedFor: null,
        error: null,
      };
    }
    case Actions.ROOM_OCCUPANCY_LIST_SUCCESS: {
      return {
        ...state,
        roomOccupancyListLoading: false,
        roomOccupancyList: [...action.payload],
        error: null,
      };
    }
    case Actions.ROOM_OCCUPANCY_LIST_ERROR: {
      return {
        ...state,
        roomOccupancyListLoading: false,
        roomOccupancyList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    case Actions.ROOM_ITEM_SAVING: {
      return {
        ...state,
        roomItemSaved: false,
        roomItemSaving: true,
        error: null,
      };
    }
    case Actions.ROOM_ITEM_SAVED: {
      return {
        ...state,
        roomItemSaving: false,
        newRoomItemId: action.payload.id === state.newRoomItemId ? null : state.newRoomItemId,
        roomItemSaved: true,
        roomItem: action.payload,
        error: null,
      };
    }
    case Actions.ROOM_ITEM_SAVED_HANDLED: {
      return {
        ...state,
        roomItemSaved: false,
      };
    }
    case Actions.ROOM_ITEM_SAVE_ERROR: {
      return {
        ...state,
        roomItemSaving: false,
        roomItemSaved: false,
        newRoomItemId: null, // This prevents the continuous redirection from list to edit page.
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    case Actions.ROOM_ITEM_ADD_SAVING: {
      return {
        ...state,
        roomAddItemLoading: true,
        newRoomItemId: null,
        roomItem: null,
        error: null,
      };
    }
    case Actions.ROOM_ITEM_ADD_SAVED: {
      return {
        ...state,
        roomAddItemLoading: false,
        newRoomItemId: action.payload.id,
        roomItem: action.payload,
        error: null,
      };
    }
    case Actions.ROOM_ITEM_SAVE_ADD_ERROR: {
      return {
        ...state,
        roomAddItemLoading: false,
        newRoomItemId: null,
        roomItem: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    default: {
      return {
        ...state,
        isLoading: false,
        employeeListLoading: false,
      };
    }
  }
};

export default roomReducer;