import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./auth.reducers";
import Common from "./Common";
import Profile from "./profile.reducer";
import Questions from "./question.reducers";
import Quiz from "./quiz.reducers";
import Lookups from "./lookup.reducers";
import Errors from "./error.reducer";
import Plans from "./plan.reducers";
import Employees from "./employee.reducers";
import Diary from "./diary.reducers";
import ElectronicContent from "./ec.reducers";
import Media from "./media.reducers";
import Kpi from "./kpi.reducers";
import Search from "./search.reducer";
import Dashboard from "./dashboard.reducer";
import Notification from "./notification.reducer";
import Exams from "./exams.reducer";
import WidgetExams from "./widget-exams.reducers";
import StudentManagement from "./studentManagement.reducer";
import StudentAttendance from "./studentAttendance.reducer";
import bigX from "./big-x.reducer";
import StaffAttendance from "./staffAttendance.reducer";
import AttendanceTimetable from "./attendanceTimetable.reducer";
import Designation from "./designation.reducer";
import Fee from "./fee.reducer";
import Accounts from "./accounts.reducer";
import CampusManagement from "./campusManagement.reducers";

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    profile: Profile,
    questions: Questions,
    quiz: Quiz,
    lookups: Lookups,
    errors: Errors,
    plans: Plans,
    employees: Employees,
    diary: Diary,
    ec: ElectronicContent,
    media: Media,
    kpi: Kpi,
    bigX,
    search: Search,
    dashboard: Dashboard,
    notifications: Notification,
    exams: Exams,
    widgetExams: WidgetExams,
    studentManagement: StudentManagement,
    studentAttendance: StudentAttendance,
    staffAttendance: StaffAttendance,
    attendanceTimetable:AttendanceTimetable,
    designation: Designation,
    fee: Fee,
    accounts: Accounts,
    campusManagement: CampusManagement,
  });

export default reducers;
