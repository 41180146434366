import * as Actions from "../actions/exams.actions";
import { PageInfo } from "../../constants/GlobalConstants";
import { utils } from "../../@mb";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,
  pageInfo: PageInfo,
  examsListLoading: false,
  examsList: [],
  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case Actions.EXAMS_LIST_LOADING: {
      return {
        ...state,
        examsListLoading: true,
        examsList: [],
        error: null,
      };
    }
    case Actions.EXAMS_LIST_SUCCESS: {
      return {
        ...state,
        examsListLoading: false,
        examsList: action.payload,
        error: null,
      };
    }
    case Actions.EXAMS_LIST_ERROR: {
      return {
        examsListLoading: false,
        examsList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    default: {
      return {
        ...state,
        isLoading: false,
      };
    }
  }
};
