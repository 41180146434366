import * as Actions from "../actions";
import { PageInfo } from "constants/GlobalConstants";
import { Kpi } from "constants/const.kpi";
import { dt } from "@mb";
import { createSelector } from "@reduxjs/toolkit";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,
  pageInfo: PageInfo,
  kpiItemId: null,
  kpiItem: null,
  kpiItemLoading: false,
  kpiDashboardLoading: false,
  kpiDashboardInfo: null,
  kpiStudentExitLoading: false,
  kpiStudentExitInfo: null,
  kpiListLoading: false,
  kpiList: [],
  kpiExitStudentCountDRLoading: false,
  kpiExitStudentCountDR: [],
  kpiGraphsManualContentLoading: false,
  kpiGraphsManualContent: null,
  kpiMonthlyReportLoading: false,
  kpiMonthlyReport: null,
  monthlyReportList: null,
  monthlyReportListLoading: false,
  kpiMonthlyReportDrawer: null,
  relationshipWithParentsLoading: false,
  relationshipWithParents: null,
  monthlyTeacherListLoading: false,
  monthlyTeacherList: null,
  campusUpkeepLoading: false,
  campusUpkeep: null,
  campusUpkeepListLoading: false,
  campusUpkeepList: [],
  campusUpKeepDashboardLoading: false,
  campusUpKeepDashboard: [],
  monthlyReportGraphContentLoading: false,
  monthlyReportGraphContent: null,
  error: {
    code: null,
    message: null,
  },
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.KPI_DB_LOADING: {
      return {
        ...state,
        kpiDashboardLoading: true,
        kpiDashboardInfo: null,
        error: null,
      };
    }
    case Actions.KPI_DB_SUCCESS: {
      const result = action.payload?.reduce((r, data) => {
        const { dateRangeFrom, /*content,*/ manualContent } = data;
        const monthyear = dt.toMonthYearString(dateRangeFrom);

        if (!r[monthyear]) r[monthyear] = [];
        //r[monthyear].push(JSON.parse(content));
        r[monthyear].push(JSON.parse(manualContent));

        return r;
      }, {});

      const flattened = Object.entries(result).map((data) => {
        const obj = {};

        obj[data[0]] = data[1].reduce((acc, it) => [...acc, ...it], []);
        return obj;
      });
      const monthwise = Object.entries(flattened).map((data) => {
        const g = //{};
          //g[Object.keys(data[1])] =
          Object.values(data[1]).map((d) => {
            return d.reduce(
              (acc, it) => {
                const i = `${Kpi.KpiDailyReportItem[it.item]}`; //String(it.item);

                acc[i] = (acc[i] || 0) + Number(it.value);
                return acc;
              },
              { Period: Object.keys(data[1])[0] }
            );
          });

        return g[0];
      });

      dt.sort(monthwise, "Period");
      return {
        ...state,
        kpiDashboardLoading: false,
        kpiDashboardInfo: monthwise,
        error: null,
      };
    }
    case Actions.KPI_DB_ERROR: {
      return {
        ...state,
        kpiDashboardLoading: false,
        kpiDashboardInfo: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.KPIDB_STUDENT_EXIT_INFO_LOADING: {
      return {
        ...state,
        kpiStudentExitLoading: true,
        kpiStudentExitInfo: null,
        error: null,
      };
    }
    case Actions.KPIDB_STUDENT_EXIT_INFO_SUCCESS: {
      return {
        ...state,
        kpiStudentExitLoading: false,
        kpiStudentExitInfo: action.payload,
        error: null,
      };
    }
    case Actions.KPIDB_STUDENT_EXIT_INFO_ERROR: {
      return {
        ...state,
        kpiStudentExitLoading: false,
        kpiStudentExitInfo: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.KPI_DAILY_REPORT_SAVING: {
      return {
        ...state,
        kpiItemLoading: true,
        kpiItem: null,
        error: null,
      };
    }
    case Actions.KPI_DAILY_REPORT_SAVE_SUCCESS: {
      return {
        ...state,
        kpiItemLoading: false,
        kpiItem: action.payload,
        error: null,
      };
    }

    case Actions.KPI_ITEM_LOADING: {
      return {
        ...state,
        kpiItemLoading: true,
        kpiItem: null,
        error: null,
      };
    }

    case Actions.KPI_ITEM_SUCCESS: {
      return {
        ...state,
        kpiItemLoading: false,
        kpiItem: action.payload,
        error: null,
      };
    }

    case Actions.KPI_ITEM_ERROR: {
      return {
        ...state,
        kpiItemLoading: false,
        kpiItem: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.KPI_LIST_LOADING: {
      return {
        ...state,
        kpiListLoading: true,
        kpiItem: null,
        //kpiList: [],
        error: null,
      };
    }
    case Actions.KPI_LIST_SUCCESS: {
      return {
        ...state,
        kpiListLoading: false,
        kpiList: [...action.payload.data],
        pageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },

        error: null,
      };
    }
    case Actions.KPI_LIST_ERROR: {
      return {
        ...state,
        kpiListLoading: false,
        kpiList: [],
        pageInfo: {
          ...PageInfo,
          HasNext: false,
          HasPrevious: false,
        },
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.KPI_DR_GRAPH_MANUAL_CONTENT_LOADING: {
      return {
        ...state,
        kpiGraphsManualContentLoading: true,
        kpiGraphsManualContent: null,
        error: null,
      };
    }
    case Actions.KPI_DR_GRAPH_MANUAL_CONTENT_SUCCESS: {
      return {
        ...state,
        kpiGraphsManualContentLoading: false,
        kpiGraphsManualContent: action.payload,
        error: null,
      };
    }
    case Actions.KPI_DR_GRAPH_MANUAL_CONTENT_ERROR: {
      return {
        ...state,
        kpiGraphsManualContentLoading: false,
        kpiGraphsManualContent: null,
        error: {
          ...state.error,
          code: action?.payload?.code,
          message: action?.payload?.message,
        },
      };
    }
    case Actions.EXIT_STUDENT_COUNT_DR_LOADING: {
      return {
        ...state,
        kpiExitStudentCountDRLoading: true,
        kpiExitStudentCountDR: [],
        error: null,
      };
    }
    case Actions.EXIT_STUDENT_COUNT_DR_SUCCESS: {
      return {
        ...state,
        kpiExitStudentCountDRLoading: false,
        kpiExitStudentCountDR: [...action.payload],
        error: null,
      };
    }
    case Actions.EXIT_STUDENT_COUNT_DR_ERROR: {
      return {
        ...state,
        kpiExitStudentCountDRLoading: false,
        kpiExitStudentCountDR: [],

        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.KPI_MONTHLY_REPORT_LOADING: {
      return {
        ...state,
        kpiMonthlyReportLoading: true,
        kpiMonthlyReport: null,
        error: null,
      };
    }
    case Actions.KPI_MONTHLY_REPORT_SUCCESS: {
      return {
        ...state,
        kpiMonthlyReportLoading: false,
        kpiMonthlyReport: { ...action.payload },
        error: null,
      };
    }
    case Actions.KPI_MONTHLY_REPORT_ERROR: {
      return {
        ...state,
        kpiMonthlyReportLoading: false,
        kpiMonthlyReport: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.RELATIONSHIP_WITH_PARENTS_LOADING: {
      return {
        ...state,
        relationshipWithParentsLoading: true,
        relationshipWithParents: null,
        error: null,
      };
    }
    case Actions.RELATIONSHIP_WITH_PARENTS_SUCCESS: {
      return {
        ...state,
        relationshipWithParentsLoading: false,
        relationshipWithParents: action.payload,
        error: null,
      };
    }
    case Actions.MONTHLY_TEACHER_LOADING: {
      return {
        ...state,
        monthlyTeacherListLoading: true,
        monthlyTeacherList: null,
        error: null,
      };
    }
    case Actions.MONTHLY_TEACHER_SUCCESS: {
      return {
        ...state,
        monthlyTeacherListLoading: false,
        monthlyTeacherList: action.payload,
        error: null,
      };
    }
    case Actions.KPI_MONTHLY_REPORT_SAVING: {
      return {
        ...state,
        kpiMonthlyReportLoading: true,
        kpiMonthlyReport: null,
        error: null,
      };
    }
    case Actions.KPI_MONTHLY_REPORT_SAVE_SUCCESS: {
      return {
        ...state,
        kpiMonthlyReportLoading: false,
        kpiMonthlyReport: action.payload,
        error: null,
      };
    }
    case Actions.KPI_MONTHLY_REPORT_SAVE_ERROR: {
      return {
        ...state,
        kpiMonthlyReportLoading: false,
        // kpiMonthlyReport: null,
      };
    }
    case Actions.KPI_MONTHLY_REPORT_LIST_LOADING: {
      return {
        ...state,
        monthlyReportListLoading: true,
        monthlyReportList: null,
        error: null,
      };
    }
    case Actions.KPI_MONTHLY_REPORT_LIST_SUCCESS: {
      return {
        ...state,
        monthlyReportListLoading: false,
        monthlyReportList: [...action.payload.data],
        pageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },

        error: null,
      };
    }

    case Actions.CAMPUS_UP_KEEP_SAVE_SUCCESS: {
      return {
        ...state,
        campusUpkeepLoading: false,
        campusUpkeep: action.payload,
        error: null,
      };
    }
    case Actions.CAMPUS_UP_KEEP_LIST_LOADING: {
      return {
        ...state,
        campusUpkeepListLoading: true,
        campusUpkeepList: null,
        //kpiList: [],
        error: null,
      };
    }
    case Actions.CAMPUS_UP_KEEP_LIST_SUCCESS: {
      return {
        ...state,
        campusUpkeepListLoading: false,
        campusUpkeepList: [...action.payload.data],
        pageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },

        error: null,
      };
    }
    case Actions.CAMPUS_UP_KEEP_LIST_ERROR: {
      return {
        ...state,
        campusUpkeepListLoading: false,
        campusUpkeepList: [],
        pageInfo: {
          ...PageInfo,
          HasNext: false,
          HasPrevious: false,
        },
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.CAMPUS_UP_KEEP_ITEM_LOADING: {
      return {
        ...state,
        campusUpkeepLoading: true,
        campusUpkeep: null,
        error: null,
      };
    }

    case Actions.CAMPUS_UP_KEEP_ITEM_SUCCESS: {
      return {
        ...state,
        campusUpkeepLoading: false,
        campusUpkeep: action.payload,
        error: null,
      };
    }

    case Actions.CAMPUS_UP_KEEP_ITEM_ERROR: {
      return {
        ...state,
        campusUpkeepLoading: false,
        campusUpkeep: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.KPI_MONTHLY_REPORT_DRAWER_SUCCESS: {
      return {
        ...state,
        kpiMonthlyReportDrawer: {
          ...state.kpiMonthlyReportDrawer,
          ...action.payload,
        },
      };
    }
    case Actions.CAMPUS_UP_KEEP_DASHBOARD_LOADING: {
      return {
        ...state,
        campusUpKeepDashboardLoading: true,
        campusUpKeepDashboard: null,
        error: null,
      };
    }

    case Actions.CAMPUS_UP_KEEP_DASHBOARD_SUCCESS: {
      return {
        ...state,
        campusUpKeepDashboardLoading: false,
        campusUpKeepDashboard: action.payload,
        error: null,
      };
    }

    case Actions.CAMPUS_UP_KEEP_DASHBOARD_ERROR: {
      return {
        ...state,
        campusUpKeepDashboardLoading: false,
        campusUpKeepDashboard: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.MR_GRAPH_CONTENT_LOADING: {
      return {
        ...state,
        monthlyReportGraphContentLoading: true,
        monthlyReportGraphContent: null,
        error: null,
      };
    }

    case Actions.MR_GRAPH_CONTENT_SUCCESS: {
      return {
        ...state,
        monthlyReportGraphContentLoading: false,
        monthlyReportGraphContent: action.payload,
        error: null,
      };
    }

    default: {
      return {
        ...state,
        isLoading: false,
        kpiItemLoading: false,
      };
    }
  }
};

export default reducer;
