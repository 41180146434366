import * as Actions from "../actions";
import * as common from "../actions/Common";
import studentAttendanceService from "../../appServices/studentAttendanceService";

const fetchStudentAttendanceList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.STUDENT_ATTENDANCE_LIST_LOADING,
    Actions.STUDENT_ATTENDANCE_LIST_SUCCESS,
    Actions.STUDENT_ATTENDANCE_LIST_ERROR,
    studentAttendanceService.fetchStudentAttendanceList,
    query
  );

const updateStudentAttendanceList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.STUDENT_ATTENDANCE_LIST_LOADING,
    Actions.STUDENT_ATTENDANCE_LIST_SUCCESS,
    Actions.STUDENT_ATTENDANCE_LIST_ERROR,
    studentAttendanceService.updateStudentAttendanceList,
    query
  );

const updateStudentAttendanceDetail = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.STUDENT_ATTENDANCE_LIST_LOADING,
    Actions.UPDATE_STUDENT_ATTENDANCE,
    Actions.STUDENT_ATTENDANCE_LIST_ERROR,
    studentAttendanceService.updateStudentAttendanceDetail,
    query
  );

export {
  fetchStudentAttendanceList,
  updateStudentAttendanceList,
  updateStudentAttendanceDetail,
};
