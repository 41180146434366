export const QUIZ_ADD_SAVING = "QUIZ_ADD_SAVING";
export const QUIZ_ADD_SUCCESS = "QUIZ_ADD_SUCCESS";
export const QUIZ_ADD_ERROR = "QUIZ_ADD_ERROR";

export const QUIZ_LOADING = "QUIZ_LOADING";
export const QUIZ_SAVING = "QUIZ_SAVING";
export const QUIZ_SUCCESS = "QUIZ_SUCCESS";
export const QUIZ_ERROR = "QUIZ_ERROR";

export const QUIZ_LIST_LOADING = "QUIZ_LIST_LOADING";
export const QUIZ_LIST_SUCCESS = "QUIZ_LIST_SUCCESS";
export const QUIZ_LIST_ERROR = "QUIZ_LIST_ERROR";

export const QUIZ_CLASSS_LOADING = "QUIZ_CLASSS_LOADING";
export const QUIZ_CLASSS_SUCCESS = "QUIZ_CLASSS_SUCCESS";
export const QUIZ_CLASSS_ERROR = "QUIZ_CLASSS_ERROR";
export const QUIZ_SUBJ_LOADING = "QUIZ_SUBJ_LOADING";
export const QUIZ_SUBJ_SUCCESS = "QUIZ_SUBJ_SUCCESS";
export const QUIZ_SUBJ_ERROR = "QUIZ_SUBJ_ERROR";
export const QUIZ_BULK_UPLOAD_LOADING = "QUIZ_BULK_UPLOAD_LOADING";
export const QUIZ_BULK_UPLOAD_SUCCESS = "QUIZ_BULK_UPLOAD_SUCCESS";
export const QUIZ_BULK_UPLOAD_ERROR = "QUIZ_BULK_UPLOAD_ERROR";
export const QUIZ_MEDIA_SAVING = "QUIZ_MEDIA_SAVING";
export const QUIZ_MEDIA_SUCCESS = "QUIZ_MEDIA_SUCCESS";
export const QUIZ_MEDIA_ERROR = "QUIZ_MEDIA_ERROR";

export const QUIZ_QUESTION_LIST_LOADING = "QUIZ_QUESTION_LIST_LOADING";
export const QUIZ_QUESTION_LIST_SUCCESS = "QUIZ_QUESTION_LIST_SUCCESS";
export const QUIZ_QUESTION_LIST_ERROR = "QUIZ_QUESTION_LIST_ERROR";
export const QUIZ_QUESTION_UPDATED = "QUIZ_QUESTION_UPDATED";

export const QUESTION_DELETE_SUBMIT = "QUESTION_DELETE_SUBMIT";
export const QUESTION_DELETE_SUCCESS = "QUESTION_DELETE_SUCCESS";
export const QUESTION_DELETE_ERROR = "QUESTION_DELETE_ERROR";
