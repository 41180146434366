import * as Actions from "../actions/plan.actions";
import { PageInfo } from "../../constants/GlobalConstants";
import { utils } from "../../@mb";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,
  planItemId: null,
  planItem: null,
  planItemLoading: false,
  planListLoading: false,
  planList: [],
  pageInfo: PageInfo,
  planMediaUploading: false,
  planMediaInfo: null,
  unitListLoading: false,
  unitListReordering: false,
  shouldFetchUnits: false,
  unitList: [],
  unitMediaUploading: false,
  unitMediaInfo: null,
  unitItemDeleting: false,
  unitItemDelted: false,
  mappingListLoading: false,
  shouldFetchMappings: false,
  mappingList: [],
  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.REORDER_UNITS_LOADING: {
      return {
        ...state,
        unitListReordering: true,
        error: null,
      };
    }
    case Actions.REORDER_UNITS_SUCCESS: {
      return {
        ...state,
        unitListReordering: false,
        unitList: [...action.payload],
        error: null,
      };
    }
    case Actions.REORDER_UNITS_ERROR: {
      return {
        ...state,
        unitList: [],
        unitListReordering: false,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.PLAN_LOADING: {
      return {
        ...state,
        planItemLoading: true,
        planItemId: null,
        planItem: null,
        shouldFetchUnits: false,
        shouldFetchMappings: true,
        unitList: [],
        error: null,
      };
    }
    case Actions.PLAN_ADD_SAVING: {
      return {
        ...state,
        planItemLoading: true,
        planItemId: null,
        planItem: null,
        error: null,
      };
    }
    case Actions.PLAN_ADD_SUCCESS: {
      const pi = state.pageInfo != null ? state.pageInfo : PageInfo;
      pi.Total++;
      let statePlanList = state.planList;
      statePlanList.push(action.payload);

      return {
        ...state,
        planItemLoading: false,
        planItemId: action.payload.id,
        planItem: null,
        planList: [...statePlanList],
        pageInfo: {
          ...pi,
        },
        error: null,
      };
    }
    case Actions.PLAN_SAVING: {
      return {
        ...state,
        planItemLoading: true,
        error: null,
      };
    }
    case Actions.PLAN_SUCCESS: {
      let newQl = state.planList;
      if (!utils.isEmpty(state.planList)) {
        newQl = state.planList.map((q) => {
          if (q.id === action.payload.id) {
            q = action.payload;
          }
          return q;
        });
      }
      return {
        ...state,
        planItemLoading: false,
        planItem: action.payload,
        planList: newQl,
        shouldFetchUnits: true,
        shouldFetchMappings: true,
        error: null,
      };
    }
    case Actions.PLAN_ADD_ERROR: {
      return {
        ...state,
        planItemLoading: false,
        planItemId: null,
        planItem: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.PLAN_ERROR: {
      return {
        ...state,
        planItemLoading: false,
        planItem: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.PLAN_LIST_LOADING: {
      return {
        ...state,
        planListLoading: true,
        planItem: null,
        planList: [],
        error: null,
      };
    }
    case Actions.PLAN_LIST_SUCCESS: {
      return {
        ...state,
        planListLoading: false,
        planList: [...action.payload.data],
        pageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },

        error: null,
      };
    }
    case Actions.PLAN_LIST_ERROR: {
      return {
        ...state,
        planListLoading: false,
        planList: [],
        pageInfo: {
          ...PageInfo,
          HasNext: false,
          HasPrevious: false,
        },
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.PLAN_BULK_UPLOAD_LOADING: {
      return {
        ...state,
        planListLoading: true,
        planList: [],
        error: null,
      };
    }
    case Actions.PLAN_BULK_UPLOAD_SUCCESS: {
      return {
        ...state,
        planListLoading: false,
        planList: [...action.payload],
        error: null,
      };
    }
    case Actions.PLAN_BULK_UPLOAD_ERROR: {
      return {
        ...state,
        planListLoading: false,
        planList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: `File ingestion failed, please verify the file conforms the import standards.`,
        },
      };
    }

    case Actions.UNIT_LIST_LOADING: {
      return {
        ...state,
        unitListLoading: true,
        shouldFetchUnits: false,
        shouldFetchMappings: false,
        unitList: [],
        error: null,
      };
    }
    case Actions.UNIT_LIST_SUCCESS: {
      return {
        ...state,
        unitListLoading: false,
        shouldFetchUnits: true,
        shouldFetchMappings: true,
        unitList: [...action.payload],
        error: null,
      };
    }
    case Actions.UNIT_LIST_ERROR: {
      return {
        ...state,
        unitListLoading: false,
        shouldFetchUnits: false,
        shouldFetchMappings: false,
        unitList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.UNIT_ADD_SAVING: {
      return {
        ...state,
        unitItemLoading: true,
        unitItemId: null,
        unitItem: null,
        error: null,
      };
    }
    case Actions.UNIT_ADD_SUCCESS: {
      let stateUnitList = state.unitList;
      stateUnitList.push(action.payload);
      return {
        ...state,
        unitItemLoading: false,
        unitItemId: action.payload.id,
        unitItem: null,
        shouldFetchUnits: true,
        shouldFetchMappings: true,
        unitList: [...stateUnitList],

        error: null,
      };
    }
    case Actions.UNIT_MARK_COMPLETED_SAVING:
    case Actions.UNIT_SAVING: {
      return {
        ...state,
        unitItemLoading: true,
        unitMediaUploading: false,
        unitMediaInfo: null,
        error: null,
      };
    }
    case Actions.UNIT_SUCCESS: {
      let unitItemUpdated = action.payload;
      let index = state.unitList.findIndex((u) => u.id === unitItemUpdated.id);
      let stateUnitList = state.unitList;
      stateUnitList[index] = unitItemUpdated;
      return {
        ...state,
        unitItemLoading: false,
        unitItem: action.payload,
        unitList: [...stateUnitList],
        shouldFetchUnits: true,
        shouldFetchMappings: true,
        error: null,
      };
    }
    case Actions.UNIT_MARK_COMPLETED_SUCCESS: {
      let unitItemUpdated = action.payload;
      let index = state.mappingList.findIndex(
        (u) => u.unitId === unitItemUpdated.unitId
      );
      let stateMappingList = state.mappingList;
      stateMappingList[index] = unitItemUpdated;
      return {
        ...state,
        unitItemLoading: false,
        unitItem: action.payload,
        mappingList: [...stateMappingList],
        shouldFetchUnits: true,
        shouldFetchMappings: true,
        error: null,
      };
    }
    case Actions.UNIT_MARK_COMPLETED_ERROR:
    case Actions.UNIT_ADD_ERROR: {
      return {
        ...state,
        unitItemLoading: false,
        unitItemId: null,
        unitItem: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    /*case Actions.UNIT_MEDIA_SAVING: {
      return {
        ...state,
        unitMediaUploading: true,
        unitMediaInfo: null,
        error: null,
      };
    }
    case Actions.UNIT_MEDIA_SUCCESS: {
      return {
        ...state,
        unitMediaUploading: false,
        unitMediaInfo: action.payload,
        error: null,
      };
    }
    case Actions.UNIT_MEDIA_ERROR: {
      return {
        ...state,
        unitMediaUploading: false,
        unitMediaInfo: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: `Media upload failed, please verify the file conforms the requirements.`,
        },
      };
    }*/
    case Actions.UNIT_DELETE_SUBMIT: {
      return {
        ...state,
        unitItemDeleting: true,
        unitItemDelted: false,
        error: null,
      };
    }
    case Actions.UNIT_DELETE_SUCCESS: {
      let { planId, unitId } = action.payload;
      let index = state.unitList.findIndex(
        (u) => u.planId === planId && u.id === unitId
      );
      if (index < 0) {
        return {
          ...state,
        };
      }
      let stateUnitList = state.unitList;
      stateUnitList.splice(index, 1);
      return {
        ...state,
        unitItemDeleting: false,
        unitItemDelted: true,
        unitItem: null,
        unitList: [...stateUnitList],
        shouldFetchUnits: true,
        shouldFetchMappings: true,
        error: null,
      };
    }
    case Actions.UNIT_DELETE_ERROR: {
      return {
        ...state,
        unitItemDeleting: false,
        unitItemDelted: false,
        error: {
          ...state.error,
          code: action.payload.code,
          message: `Unable to delete the requested Unit!`,
        },
      };
    }
    case Actions.UNIT_MAPPING_LIST_LOADING: {
      return {
        ...state,
        mappingListLoading: true,
        shouldFetchMappings: false,
        mappingList: [],
        error: null,
      };
    }
    case Actions.UNIT_MAPPING_LIST_SUCCESS: {
      return {
        ...state,
        mappingListLoading: false,
        shouldFetchMappings: true,
        mappingList: [...action.payload],
        error: null,
      };
    }
    case Actions.UNIT_MAPPING_LIST_ERROR: {
      return {
        ...state,
        mappingListLoading: false,
        shouldFetchMappings: false,
        mappingList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    default: {
      return {
        ...state,
        isLoading: false,
        planListLoading: false,
      };
    }
  }
};
