import * as Actions from "../actions";

const INIT_STATE = {
  designationListLoading: false,
  designationList: [],

  error: {
    code: null,
    message: null,
  },
};

const designationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.DESIGNATION_LIST_LOADING: {
      return {
        ...state,
        designationListLoading: true,
        designationList: [],
      };
    }
    case Actions.DESIGNATION_LIST_SUCCESS: {
      return {
        ...state,
        designationListLoading: false,
        designationList: [...action.payload],
      };
    }
    case Actions.DESIGNATION_LIST_ERROR: {
      return {
        ...state,
        designationListLoading: false,
        designationList: [],
        error: {
          ...state.error,
          code: action?.payload?.code,
          message: action?.payload?.message,
        },
      };
    }
    case Actions.DESIGNATION_LIST_RESET: {
      return {
        ...state,
        designationListLoading: false,
        designationList: [],
        error: {
          code: null,
          message: null,
        },
      };
    }
    case Actions.DESIGNATION_ITEM_UPDATE: {
      const idx = state.designationList.findIndex(
        (entry) => entry.id === action.payload.id
      );
      const newList = [
        ...state.designationList.slice(0, idx),
        action.payload,
        ...state.designationList.slice(idx + 1),
      ];
      return {
        ...state,
        designationList: newList,
      };
    }
    case Actions.DESIGNATION_ITEM_UPDATED: {
      const idx = state.designationList.findIndex(
        (entry) => entry.id === action.payload.id
      );
      
      let newList = [];
      if (idx === -1) {
        newList = [...state.designationList, action.payload];
      } else {
        newList = [
          ...state.designationList.slice(0, idx),
          action.payload,
          ...state.designationList.slice(idx + 1),
        ];
      }

      return {
        ...state,
        designationList: newList,
        designationListLoading: false,
      };
    }
    case Actions.DESIGNATION_ITEM_UPDATING: {
      return {
        ...state,
        designationListLoading: true,
      };
    }
    case Actions.DESIGNATION_ITEM_ERROR: {
      return {
        ...state,
        designationListLoading: false,
        error: {
          ...state.error,
          code: action?.payload?.code,
          message: action?.payload?.message,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default designationReducer;
