import * as Actions from "../actions";

const INIT_STATE = {
  viewChequeListLoading: false,
  viewChequeList: [],
  chequePresentDateItemSaving: false,
  chequePresentDateItemSaved: false,
  chequePresentDateItem:null,
  error: {
    code: null,
    message: null,
  },
};

const accountsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.VIEW_CHEQUE_LIST_LOADING: {
      return {
        ...state,
        viewChequeListLoading: true,
        viewChequeList: [],
      };
    }
    case Actions.VIEW_CHEQUE_LIST_SUCCESS: {
      return {
        ...state,
        viewChequeListLoading: false,
        viewChequeList: [...action.payload],
      };
    }
    case Actions.VIEW_CHEQUE_LIST_ERROR: {
      return {
        ...state,
        viewChequeListLoading: false,
        viewChequeList: [],
        error: {
          ...state.error,
          code: action?.payload?.code,
          message: action?.payload?.message,
        },
      };
    }
    
    case Actions.CHEQUE_PRESENT_DATE_SAVING: {
      return {
        ...state,
        chequePresentDateItemSaving: true,
        chequePresentDateItemSaved: false
      };
    }
    case Actions.CHEQUE_PRESENT_DATE_SAVED: {
      const idx = state.viewChequeList.findIndex(
        (entry) => entry.chequeNo === action.payload.chequeNo && entry.accountNo === action.payload.accountNo && entry.voucher_Number === action.payload.voucher_Number
      );
      let newList = [
        ...state.viewChequeList.slice(0, idx),
        action.payload,
        ...state.viewChequeList.slice(idx + 1),
      ];
      return {
        ...state,
        chequePresentDateItemSaving: false,
        viewChequeList: newList,
        chequePresentDateItemSaved: true
      };
    }
    case Actions.CHEQUE_PRESENT_DATE_ERROR: {
      return {
        ...state,
        chequePresentDateItemSaving: false,
        chequePresentDateItemSaved: false,
        error: {
          ...state.error,
          code: action?.payload?.code,
          message: action?.payload?.message,
        },
      };
    }
    
    default: {
      return {
        ...state,
      };
    }
  }
};

export default accountsReducer;
