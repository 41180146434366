export const PROFILE_ERROR = "PROFILE_ERROR";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_IMAGE_SUCCESS = "PROFILE_IMAGE_SUCCESS";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_SESSION_LIST_LOADING = "PROFILE_SESSION_LIST_LOADING";
export const PROFILE_SESSION_LIST_SUCCESS = "PROFILE_SESSION_LIST_SUCCESS";
export const PROFILE_SESSION_LIST_ERROR = "PROFILE_SESSION_LIST_ERROR";

export const profileActionCreators = {
  profileIsLoading: (isLoading) => ({
    type: PROFILE_LOADING,
    isLoading: isLoading,
  }),
};

export const PROFILE_SUBJ_LIST_LOADING = "PROFILE_SUBJ_LIST_LOADING";
export const PROFILE_SUBJ_LIST_SUCCESS = "PROFILE_SUBJ_LIST_SUCCESS";
export const PROFILE_SUBJ_LIST_ERROR = "PROFILE_SUBJ_LIST_ERROR";

export const PROFILE_SECTION_LIST_LOADING = "PROFILE_SECTION_LIST_LOADING";
export const PROFILE_SECTION_LIST_SUCCESS = "PROFILE_SECTION_LIST_SUCCESS";
export const PROFILE_SECTION_LIST_ERROR = "PROFILE_SECTION_LIST_ERROR";
