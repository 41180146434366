import { mb } from "@mb/api";

const staffAttendanceService = {

  getByEmpId: async (empId) => mb.api.get(`api/StaffAttendance/${empId}`),

  fetchStaffAttendanceByDateRange: async (query) => {
    return mb.api.get(`api/StaffAttendance/${query.empId}`, query);
  },
  fetchAllStaffAttendanceByDate: async (query) => {
    return mb.api.get(`api/StaffAttendance`, query);
  },
  bulkUpdateStatus: async (query) => {
    return mb.api.post(`api/StaffAttendance/updateStatus`, query);
  },
  updateStatusEntry: async (query) => {
    return mb.api.post(`api/StaffAttendance/updateStatusEntry`, query);
  },
};

export default staffAttendanceService;
