import * as Actions from "../actions";
import * as common from "../actions/Common";

import notificationService from "appServices/notificationService";

export { fetchNotification, fetchNotificationList, saveNotification };

const fetchNotification = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.NOTIFICATION_LOADING,
    Actions.NOTIFICATION_SUCCESS,
    Actions.NOTIFICATION_ERROR,
    notificationService.fetchNotificationById,
    query
  );

const fetchNotificationList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.NOTIFICATION_LIST_LOADING,
    Actions.NOTIFICATION_LIST_SUCCESS,
    Actions.NOTIFICATION_LIST_ERROR,
    notificationService.getPagedList,
    query,
    query?.searchText
  );

const saveNotification = (query, createNew = false) => async (dispatch) =>
  createNew
    ? await common.thunkHelper(
        dispatch,
        Actions.NOTIFICATION_CREATING,
        Actions.NOTIFICATION_CREATED,
        Actions.NOTIFICATION_ERROR,
        notificationService.submitNotification,
        query
      )
    : await common.thunkHelper(
        dispatch,
        Actions.NOTIFICATION_SAVING,
        Actions.NOTIFICATION_SAVED,
        Actions.NOTIFICATION_ERROR,
        notificationService.submitNotification,
        query
      );
