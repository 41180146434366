import * as Actions from "../actions/";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,
  profile: null,
  profileLoading: false,
  sessionList: [],
  assignedSubjListLoading: false,
  assignedSubjList: [],
  campusList: [],
  campusListLoading: false,
  shouldFetchAssignedSubjList: true,
  shouldFetchAssignedSectionList: true,
  assignedSectionListLoading: false,
  assignedSectionList: [],

  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.PROFILE_LOADING: {
      return {
        ...state,
        profileLoading: true,
        error: null,
      };
    }
    case Actions.PROFILE_SUCCESS: {
      return {
        ...state,
        profileLoading: false,
        profile: {
          ...state.profile,
          ...action.studentProfile,
        },
        error: null,
        success: true,
      };
    }
    case Actions.PROFILE_ERROR: {
      return {
        profileLoading: false,
        success: false,
        profile: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.PROFILE_SESSION_LIST_LOADING: {
      return {
        ...state,
        isLoading: true,
        sessionList: [],
        error: null,
      };
    }
    case Actions.PROFILE_SESSION_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        sessionList: action.payload,
        error: null,
        success: true,
      };
    }
    case Actions.PROFILE_SESSION_LIST_ERROR: {
      return {
        isLoading: false,
        success: false,
        sessionList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.PROFILE_SUBJ_LIST_LOADING: {
      return {
        ...state,
        assignedSubjListLoading: true,
        assignedSubjList: [],
        shouldFetchAssignedSubjList: false,
        error: null,
      };
    }
    case Actions.PROFILE_SUBJ_LIST_SUCCESS: {
      return {
        ...state,
        assignedSubjListLoading: false,
        assignedSubjList: [...action.payload],
        error: null,
      };
    }
    case Actions.PROFILE_SUBJ_LIST_ERROR: {
      return {
        ...state,
        assignedSubjListLoading: false,
        assignedSubjList: [],
        shouldFetchAssignedSubjList: false,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.PROFILE_SECTION_LIST_LOADING: {
      return {
        ...state,
        assignedSectionListLoading: true,
        assignedSectionList: [],
        shouldFetchAssignedsectionList: false,
        error: null,
      };
    }
    case Actions.PROFILE_SECTION_LIST_SUCCESS: {
      return {
        ...state,
        assignedSectionListLoading: false,
        assignedSectionList: [...action.payload],
        error: null,
      };
    }
    case Actions.PROFILE_SECTION_LIST_ERROR: {
      return {
        ...state,
        assignedSectionListLoading: false,
        assignedSectionList: [],
        shouldFetchAssignedSectionList: false,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    default: {
      return {
        ...state,
        isLoading: false,
      };
    }
  }
};
