import { ClaimKeys } from "@mb";
export const menuList = [
  {
    key: "main",
    hasSubMenus: true,
    intlMessagesId: "sidebar.main",
    iconClass: "icon icon-dashboard",
    claimKey: "AllowedItem",
    subMenus: [
      {
        key: "/home",
        linkTo: "/home",
        iconClass: "icon icon-home",
        intlMessagesId: "sidebar.homePage",
        claimKey: "AllowedItem",
      },
      {
        key: "/notifications",
        linkTo: "/notifications",
        iconClass: "icon icon-notification",
        intlMessagesId: "sidebar.notification",
        claimKey: ClaimKeys.CanListNotifications,
      },
    ],
  },
  {
    key: "academics",
    hasSubMenus: true,
    intlMessagesId: "sidebar.academicsPage",
    iconClass: "icon icon-graduation",
    claimKey: "AllowedItem",
    subMenus: [
      {
        key: "/quiz",
        linkTo: "/quiz",
        iconClass: "icon icon-editor",
        intlMessagesId: "sidebar.quizPage",
        claimKey: ClaimKeys.CanListQuiz,
      },
      {
        key: "/plan",
        linkTo: "/plan",
        iconClass: "icon icon-listing-dbrd",
        intlMessagesId: "sidebar.planPage",
        claimKey: ClaimKeys.CanListPlans,
      },
    ],
  },
  {
    key: "CampusManagement",
    hasSubMenus: true,
    intlMessagesId: "sidebar.cmPage",
    iconClass: "icon icon-default-timeline",
    claimKey: "AllowedItem",
    subMenus: [
      {
        key: "/CampusManagement",
        linkTo: "/CampusManagement",
        iconClass: "icon icon-editor",
        intlMessagesId: "sidebar.room",
        claimKey: ClaimKeys.CanAccessRoomManagement,
      },
      {
        key: "/transport",
        linkTo: "/transport/van",
        iconClass: "icon icon-styled-map",
        intlMessagesId: "sidebar.van",
        claimKey: ClaimKeys.CanViewVanManagement,
      },
      {
        key: "/transport/van/assignment",
        linkTo: "/transport/van/assignment",
        iconClass: "icon icon-styled-map",
        intlMessagesId: "sidebar.vanStudentAssignment",
        claimKey: ClaimKeys.CanViewVanManagement,
      },
    ],
  },
  {
    key: "FeeManagement",
    hasSubMenus: true,
    intlMessagesId: "sidebar.fmPage",
    iconClass: "icon icon-default-timeline",
    claimKey: "AllowedItem",
    subMenus: [
      {
        key: "/feeManagement",
        linkTo: "/feeManagement",
        iconClass: "icon icon-editor",
        intlMessagesId: "sidebar.challanPortalApproval",
        claimKey: ClaimKeys.CanViewFeeApproval,
      },
    ],
  },
  {
    key: "accounts",
    hasSubMenus: true,
    intlMessagesId: "sidebar.accounts",
    iconClass: "icon icon-card",
    claimKey: "AllowedItem",
    subMenus: [
      {
        key: "/accounts/BankAccounts",
        linkTo: "/accounts/BankAccounts",
        iconClass: "icon icon-tab",
        intlMessagesId: "sidebar.bAccounts",
        claimKey: ClaimKeys.CanAccessBankAccountList,
      },
      {
        key: "/accounts/BankRecon",
        linkTo: "/accounts/BankRecon",
        iconClass: "icon icon-tab",
        intlMessagesId: "sidebar.bankRecon",
        claimKey: ClaimKeys.CanAccessBankReconcilation,
      },
      {
        key: "/accounts/ChequeStatus",
        linkTo: "/accounts/ChequeStatus",
        iconClass: "icon icon-tab",
        intlMessagesId: "sidebar.chequeStatus",
        claimKey: ClaimKeys.CanAccessChequeStatus,
      },
      {
        key: "/accounts/AccountReports",
        linkTo: "/accounts/Reports",
        iconClass: "icon icon-tab",
        intlMessagesId: "sidebar.accountReports",
        claimKey: ClaimKeys.CanAcccessGlReports,
      },
    ],
  },
  {
    key: "studentManagement",
    hasSubMenus: true,
    intlMessagesId: "sidebar.studentManagement",
    iconClass: "icon icon-default-timeline",
    claimKey: "AllowedItem",
    subMenus: [
      {
        key: "/exitProforma",
        linkTo: "/exitProforma",
        iconClass: "icon icon-editor",
        intlMessagesId: "sidebar.exitProforma",
        claimKey: ClaimKeys.CanAccessExitProforma,
      },
    ],
  },
  {
    key: "ec",
    hasSubMenus: true,
    intlMessagesId: "sidebar.ecPage",
    iconClass: "icon icon-card",
    claimKey: "AllowedItem",
    subMenus: [
      {
        key: "/ec/library",
        linkTo: "/ec/library",
        iconClass: "icon icon-tab",
        intlMessagesId: "sidebar.eLibraryListPage",
        claimKey: ClaimKeys.CanListElectronicContent,
      },
      {
        key: "/ec/recommendations",
        linkTo: "/ec/recommendations",
        iconClass: "icon icon-tab",
        intlMessagesId: "sidebar.eLibraryRecommendationsPage",
        claimKey: ClaimKeys.CanListElectronicContent,
      },
    ],
  },
  {
    key: "fm",
    hasSubMenus: true,
    intlMessagesId: "sidebar.fmPage",
    iconClass: "icon icon-card",
    claimKey: "AllowedItem",
    subMenus: [
      {
        key: "/fee/feePosting",
        linkTo: "/fee/feePosting",
        iconClass: "icon icon-tab",
        intlMessagesId: "sidebar.feePosting",
        claimKey: ClaimKeys.CanAccessFeePosting,
      },
    ],
  },
  {
    key: "hr",
    hasSubMenus: true,
    intlMessagesId: "sidebar.hrPage",
    iconClass: "icon icon-auth-screen",
    claimKey: "AllowedItem",
    subMenus: [
      {
        key: "/hr/designation",
        linkTo: "/hr/designation",
        iconClass: "icon icon-contacts",
        intlMessagesId: "sidebar.designation",
        claimKey: ClaimKeys.CanChangeEmployeement,
      },
      {
        key: "/hr/employee",
        linkTo: "/hr/employee",
        iconClass: "icon icon-contacts",
        intlMessagesId: "sidebar.employeePage",
        claimKey: ClaimKeys.CanChangeEmployeement,
      },
      {
        key: "/hr/attendance",
        linkTo: "/hr/attendance",
        iconClass: "icon icon-select",
        intlMessagesId: "sidebar.attendancePage",
        // claimKey: ClaimKeys.CanViewEmployeeAttendance,
        claimKey:"AllowedItem" ,
      },
      {
        key: "/hr/attendance/timetable",
        linkTo: "/hr/attendance/timetable",
        iconClass: "icon icon-datepicker",
        intlMessagesId: "sidebar.attendanceTimetablePage",
        claimKey: ClaimKeys.CanViewEmployeeAttendance,
      },
    ],
  },

  {
    key: "kpi",
    hasSubMenus: true,
    intlMessagesId: "sidebar.kpiPage",
    iconClass: "icon icon-dasbhoard",
    claimKey: "AllowedItem",
    subMenus: [
      {
        key: "/kpi/bigXSetup",
        linkTo: "/kpi/BigXSetup",
        iconClass: "icon icon-copy",
        intlMessagesId: "sidebar.big5Setup",
        claimKey: ClaimKeys.CanViewBigXSetup,
      },
      {
        key: "/kpi/list",
        linkTo: "/kpi/list",
        iconClass: "icon icon-copy",
        intlMessagesId: "sidebar.kpiListPage",
        claimKey: ClaimKeys.CanViewKPIDailyReportList,
      },
      {
        key: "/kpi/submit",
        linkTo: "/kpi/submit",
        iconClass: "icon icon-feedback",
        intlMessagesId: "sidebar.kpiSubmitReport",
        claimKey: ClaimKeys.CanViewKPIDailyReport,
      },
      {
        key: "/kpi/dailyreportdashboard",
        linkTo: "/kpi/dailyreportdashboard",
        iconClass: "icon icon-feedback",
        intlMessagesId: "sidebar.kpidailyreportdashboard",
        claimKey: ClaimKeys.CanViewKPIDailyReportDashboard,
      },
      {
        key: "/kpi/monthlyReport/list",
        linkTo: "/kpi/monthlyReport/list",
        iconClass: "icon icon-feedback",
        intlMessagesId: "sidebar.kpiMonthlyReportList",
        claimKey: ClaimKeys.CanViewKPIDailyReport,
      },
      {
        key: "/kpi/monthlyReport",
        linkTo: "/kpi/monthlyReport",
        iconClass: "icon icon-feedback",
        intlMessagesId: "sidebar.kpiMonthlyReport",
        claimKey: ClaimKeys.CanViewKPIDailyReport,
      },
      {
        key: "/kpi/campusUpKeep",
        linkTo: "/kpi/campusUpKeep",
        iconClass: "icon icon-feedback",
        intlMessagesId: "sidebar.kpiCampusUpKeep",
        claimKey: ClaimKeys.CanViewCampusUpKeep,
      },
      {
        key: "/kpi/campusUpKeepList",
        linkTo: "/kpi/campusUpKeepList",
        iconClass: "icon icon-feedback",
        intlMessagesId: "sidebar.kpiCampusUpKeepList",
        claimKey: ClaimKeys.CanViewCampusUpKeep,
      },
      {
        key: "/kpi/monthlyReportDashboard",
        linkTo: "/kpi/monthlyReportDashboard",
        iconClass: "icon icon-feedback",
        intlMessagesId: "sidebar.monthlyReportDashboard",
        claimKey: ClaimKeys.CanViewKPIDailyReportDashboard,
      },
      /*{
        key: "/kpi/dailyreport",
        linkTo: "/kpi/dailyreport",
        iconClass: "icon icon-data-entry",
        intlMessagesId: "sidebar.kpiDailyReportPage",
        claimKey: "AllowedItem",
      },*/
    ],
  },
  {
    key: "admin",
    hasSubMenus: true,
    intlMessagesId: "sidebar.admin",
    iconClass: "icon icon-dashboard",
    claimKey: "AllowedItem",
    subMenus: [
      {
        key: "/users",
        linkTo: "/users",
        iconClass: "icon icon-user",
        intlMessagesId: "sidebar.users",
        claimKey: ClaimKeys.CanListUsers,
      },
      {
        key: "/adminresource",
        linkTo: "/adminResource",
        iconClass: "icon icon-user",
        intlMessagesId: "sidebar.adminresource",
        claimKey: ClaimKeys.CanViewAdminResource,
      },
    ],
  },
];
