export const PLAN_ADD_SAVING = "PLAN_ADD_SAVING";
export const PLAN_ADD_SUCCESS = "PLAN_ADD_SUCCESS";
export const PLAN_ADD_ERROR = "PLAN_ADD_ERROR";
export const PLAN_LOADING = "PLAN_LOADING";
export const PLAN_SAVING = "PLAN_SAVING";
export const PLAN_SUCCESS = "PLAN_SUCCESS";
export const PLAN_ERROR = "PLAN_ERROR";
export const PLAN_LIST_LOADING = "PLAN_LIST_LOADING";
export const PLAN_LIST_SUCCESS = "PLAN_LIST_SUCCESS";
export const PLAN_LIST_ERROR = "PLAN_LIST_ERROR";
export const PLAN_CLASSS_LOADING = "PLAN_CLASSS_LOADING";
export const PLAN_CLASSS_SUCCESS = "PLAN_CLASSS_SUCCESS";
export const PLAN_CLASSS_ERROR = "PLAN_CLASSS_ERROR";
export const PLAN_SUBJ_LOADING = "PLAN_SUBJ_LOADING";
export const PLAN_SUBJ_SUCCESS = "PLAN_SUBJ_SUCCESS";
export const PLAN_SUBJ_ERROR = "PLAN_SUBJ_ERROR";
export const PLAN_BULK_UPLOAD_LOADING = "PLAN_BULK_UPLOAD_LOADING";
export const PLAN_BULK_UPLOAD_SUCCESS = "PLAN_BULK_UPLOAD_SUCCESS";
export const PLAN_BULK_UPLOAD_ERROR = "PLAN_BULK_UPLOAD_ERROR";

export const UNIT_MEDIA_SAVING = "UNIT_MEDIA_SAVING";
export const UNIT_MEDIA_SUCCESS = "UNIT_MEDIA_SUCCESS";
export const UNIT_MEDIA_ERROR = "UNIT_MEDIA_ERROR";

export const UNIT_LIST_LOADING = "UNIT_LIST_LOADING";
export const UNIT_LIST_SUCCESS = "UNIT_LIST_SUCCESS";
export const UNIT_LIST_ERROR = "UNIT_LIST_ERROR";
export const UNIT_ADD_SAVING = "UNIT_ADD_SAVING";
export const UNIT_ADD_SUCCESS = "UNIT_ADD_SUCCESS";
export const UNIT_ADD_ERROR = "UNIT_ADD_ERROR";
export const UNIT_SAVING = "UNIT_SAVING";
export const UNIT_SUCCESS = "UNIT_SUCCESS";
export const UNIT_ERROR = "UNIT_ERROR";
export const UNIT_DELETE_SUBMIT = "UNIT_DELETE_SUBMIT";
export const UNIT_DELETE_SUCCESS = "UNIT_DELETE_SUCCESS";
export const UNIT_DELETE_ERROR = "UNIT_DELETE_ERROR";

export const UNIT_MAPPING_LIST_LOADING = "UNIT_MAPPING_LIST_LOADING";
export const UNIT_MAPPING_LIST_SUCCESS = "UNIT_MAPPING_LIST_SUCCESS";
export const UNIT_MAPPING_LIST_ERROR = "UNIT_MAPPING_LIST_ERROR";
export const UNIT_MARK_COMPLETED_SAVING = "UNIT_MARK_COMPLETED_SAVING";
export const UNIT_MARK_COMPLETED_SUCCESS = "UNIT_MARK_COMPLETED_SUCCESS";
export const UNIT_MARK_COMPLETED_ERROR = "UNIT_MARK_COMPLETED_ERROR";

export const REORDER_UNITS_LOADING = "REORDER_UNITS_LOADING";
export const REORDER_UNITS_SUCCESS = "REORDER_UNITS_SUCCESS";
export const REORDER_UNITS_ERROR = "REORDER_UNITS_ERROR";
