import * as Actions from "../actions";
import * as common from "../actions/Common";
import staffAttendanceService from "appServices/staffAttendanceService";

const fetchStaffAttendanceByDateRange = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.STAFF_ATTENDANCE_LIST_LOADING,
    Actions.STAFF_ATTENDANCE_LIST_SUCCESS,
    Actions.STAFF_ATTENDANCE_LIST_ERROR,
    staffAttendanceService.fetchStaffAttendanceByDateRange,
    query
  );

const fetchAllStaffAttendanceByDate = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.STAFF_ATTENDANCE_LIST_LOADING,
    Actions.STAFF_ATTENDANCE_LIST_SUCCESS,
    Actions.STAFF_ATTENDANCE_LIST_ERROR,
    staffAttendanceService.fetchAllStaffAttendanceByDate,
    query
  );

const bulkUpdateStatus = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.STAFF_ATTENDANCE_LIST_LOADING,
    Actions.STAFF_ATTENDANCE_LIST_SUCCESS,
    Actions.STAFF_ATTENDANCE_LIST_ERROR,
    staffAttendanceService.bulkUpdateStatus,
    query
  );

const updateStatusEntry = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.STAFF_ATTENDANCE_ITEM_UPDATING,
    Actions.STAFF_ATTENDANCE_ITEM_UPDATED,
    Actions.STAFF_ATTENDANCE_ITEM_ERROR,
    staffAttendanceService.updateStatusEntry,
    query
  );

export {
  fetchStaffAttendanceByDateRange,
  fetchAllStaffAttendanceByDate,
  bulkUpdateStatus,
  updateStatusEntry,
};
