import {
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  PASSWORD_CHANGED,
  USER_TOKEN_FAILED,
  PASSWORD_RESET,
  PASSWORD_RESET_FAILED,
  RESET_AUTH_DATA,
  //USER_IMAGE_SET
} from "../../constants/ActionTypes";
import { storage } from "../../@mb";
import {
  ADMIN_LOGIN_ERROR,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_VERIFICATION_URL_RECIEVED,
  ADMIN_LOGIN_VERIFIED,
  ADMIN_LOGIN_VERIFING_REQ,
  AUTH_USER_UPDATED,
} from "../actions";
const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  token: storage.getObject("token"),
  tokenVerified: false,
  signInError: { failed: true },
  initURL: "",
  authRedirectionInfo: null,
  authUser: storage.getObject("authUser"),
  userImage: storage.getObject("imageLink"),
  passwordChangedInfo: { isValid: false },
  passwordResetInfo: null,
  userClaims: storage.getObject("userClaims"),
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        //...state,
        ...INIT_STATE,
        token: null,
        tokenVerified: false,
        authUser: null,
        userImage: null,
        userClaims: [],
      };
    }

    case AUTH_USER_UPDATED: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case RESET_AUTH_DATA: {
      return {
        ...state,
       // alertMessage: "",
       // signInError: null,
        passwordChangedInfo: null,
        passwordResetInfo: null,
       // tokenVerified: false,
      //  userClaims: [],
      };
    }

    case ADMIN_LOGIN_VERIFING_REQ: {
      return {
        ...state,
        authUser: null,
        token: null,
        tokenVerified: false,
        authRedirectionInfo: null,
        userClaims: [],
      };
    }

    case ADMIN_LOGIN_VERIFIED:
    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload.profileInfo || state.authUser,
        token: action.payload.token || state.token,
        userImage: storage.getObject("imageLink"),
        userClaims: storage.getObject("userClaims"),
        tokenVerified: true,
        authRedirectionInfo: null,
      };
    }
    case ADMIN_VERIFICATION_URL_RECIEVED: {
      return {
        ...state,
        authRedirectionInfo: action.payload.data,
      };
    }
    case ADMIN_LOGIN_SUCCESS:
    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
        tokenVerified: true,
        authRedirectionInfo: null,
      };
    }
    case ADMIN_LOGIN_ERROR:
    case USER_TOKEN_FAILED: {
      return {
        ...state,
        token: null,
        tokenVerified: false,
        authRedirectionInfo: null,
        signInError: action.payload,
        userClaims: [],
      };
    }
    case PASSWORD_CHANGED: {
      return {
        ...state,
        passwordChangedInfo: action.payload,
      };
    }
    case PASSWORD_RESET: {
      return {
        ...state,
        passwordResetInfo: action.payload,
      };
    }
    case PASSWORD_RESET_FAILED: {
      return {
        ...state,
        passwordResetInfo: action.payload,
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    default:
      return state;
  }
};
