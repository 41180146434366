import * as Actions from "../actions";
import { PageInfo } from "../../constants/GlobalConstants";
import { utils } from "../../@mb";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,
  quizItemId: 0,
  quizItem: null,
  quizItemLoading: false,
  quizItemAdded: false,
  quizListLoading: false,
  quizList: [],
  pageInfo: PageInfo,
  quizMediaUploading: false,
  quizMediaInfo: null,
  quizQuestionListLoading: false,
  quizQuestionList: [],

  questionItemDeleting: false,
  questionItemDelted: false,

  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.QUIZ_ADD_SAVING: {
      return {
        ...state,
        quizItemLoading: true,
        quizItemAdded: false,
        quizItemId: 0,
        quizItem: null,
        error: null,
      };
    }
    case Actions.QUIZ_ADD_SUCCESS: {
      //const pi = state.pageInfo != null ? state.pageInfo : PageInfo;
      return {
        ...state,
        quizItemLoading: false,
        quizItemAdded: true,
        quizItemId: action.payload.id,
        quizItem: null,
        quizList: [],
        pageInfo: PageInfo,
        error: null,
      };
    }

    case Actions.QUIZ_ADD_ERROR: {
      return {
        ...state,
        quizItemLoading: false,
        quizItemAdded: false,
        quizItemId: 0,
        quizItem: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.QUIZ_LOADING: {
      return {
        ...state,
        quizItemLoading: true,
        quizItemId: 0,
        quizItem: null,
        error: null,
      };
    }
    case Actions.QUIZ_SAVING: {
      return {
        ...state,
        quizItemLoading: true,
        quizItemId: 0,
        quizItemAdded: false,
        error: null,
      };
    }
    case Actions.QUIZ_SUCCESS: {
      let newQl = state.quizList;
      if (!utils.isEmpty(state.quizList)) {
        newQl = state.quizList.map((q) => {
          if (q.id === action.payload.id) {
            q = { ...q, ...action.payload };
          }
          return q;
        });
      }
      return {
        ...state,
        quizItemLoading: false,
        quizItemId: 0,
        quizItemAdded: false,
        quizItem: { ...state.quizItem, ...action.payload },
        quizList: [...newQl],
        error: null,
      };
    }
    case Actions.QUIZ_ERROR: {
      return {
        ...state,
        quizItemLoading: false,
        quizItemId: 0,
        quizItemAdded: false,
        quizItem: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.QUIZ_LIST_LOADING: {
      return {
        ...state,
        quizListLoading: true,
        quizItem: null,
        quizList: [],
        error: null,
      };
    }
    case Actions.QUIZ_LIST_SUCCESS: {
      return {
        ...state,
        quizListLoading: false,
        quizItemId: 0,
        quizItemAdded: false,
        quizList: [...action.payload.data],
        pageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },

        error: null,
      };
    }
    case Actions.QUIZ_LIST_ERROR: {
      return {
        ...state,
        quizListLoading: false,
        quizList: [],
        pageInfo: {
          ...PageInfo,
          HasNext: false,
          HasPrevious: false,
        },
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.QUIZ_BULK_UPLOAD_LOADING: {
      return {
        ...state,
        quizListLoading: true,
        quizList: [],
        error: null,
      };
    }
    case Actions.QUIZ_BULK_UPLOAD_SUCCESS: {
      return {
        ...state,
        quizListLoading: false,
        quizList: [...action.payload],
        error: null,
      };
    }
    case Actions.QUIZ_BULK_UPLOAD_ERROR: {
      return {
        ...state,
        quizListLoading: false,
        quizList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: `File ingestion failed, please verify the file conforms the import standards.`,
        },
      };
    }
    case Actions.QUIZ_MEDIA_SAVING: {
      return {
        ...state,
        quizMediaUploading: true,
        quizMediaInfo: null,
        error: null,
      };
    }
    case Actions.QUIZ_MEDIA_SUCCESS: {
      return {
        ...state,
        quizMediaUploading: false,
        quizMediaInfo: action.payload,
        quizQuestionList: action.payload,
        error: null,
      };
    }
    case Actions.QUIZ_MEDIA_ERROR: {
      return {
        ...state,
        quizMediaUploading: false,
        quizMediaInfo: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: `Media upload failed, please verify the file conforms the requirements.`,
        },
      };
    }
    case Actions.QUIZ_QUESTION_LIST_LOADING: {
      return {
        ...state,
        quizQuestionListLoading: true,
        quizQuestionList: [],
        error: null,
      };
    }
    case Actions.QUIZ_QUESTION_LIST_SUCCESS: {
      return {
        ...state,
        quizQuestionListLoading: false,
        quizQuestionList: [...action.payload],

        error: null,
      };
    }
    case Actions.QUIZ_QUESTION_LIST_ERROR: {
      return {
        ...state,
        quizQuestionListLoading: false,
        quizQuestionList: [],

        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.QUIZ_QUESTION_UPDATED: {
      let updatedQl = state.quizQuestionList;
      if (!utils.isEmpty(state.quizQuestionList)) {
        updatedQl = state.quizQuestionList.map((q) => {
          if (q.uniqueId === action.payload.uniqueId) {
            q = action.payload;
          }
          return q;
        });
      }
      return {
        ...state,
        quizQuestionList: updatedQl,
        error: null,
      };
    }
    case Actions.QUESTION_DELETE_SUBMIT: {
      return {
        ...state,
        questionItemDeleting: true,
        questionItemDelted: false,
        error: null,
      };
    }
    case Actions.QUESTION_DELETE_SUCCESS: {
      let { quizId, questionId } = action.payload;
      let index = state.quizQuestionList.findIndex(
        (q) => q.quizId === quizId && q.id === questionId
      );
      if (index < 0) {
        return {
          ...state,
        };
      }
      let stateQuestionList = state.quizQuestionList;
      stateQuestionList.splice(index, 1);
      return {
        ...state,
        questionItemDeleting: false,
        questionItemDelted: true,
        quizQuestionList: [...stateQuestionList],
        error: null,
      };
    }
    case Actions.QUESTION_DELETE_ERROR: {
      return {
        ...state,
        questionItemDeleting: false,
        questionItemDelted: false,
        error: {
          ...state.error,
          code: action.payload.code,
          message: `Unable to delete the requested Question!`,
        },
      };
    }
    default: {
      return {
        ...state,
        isLoading: false,
        quizListLoading: false,
      };
    }
  }
};
