import * as Actions from "../actions/employee.actions";
import { PageInfo } from "../../constants/GlobalConstants";
import { utils } from "../../@mb";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,
  pageInfo: PageInfo,
  newEmployeeItemId: null,
  employeeItem: null,
  employeeItemLoading: false,
  employeeItemSaving: false,
  employeeItemSaved: false,
  employeeAddItemLoading: false,
  employeeListLoading: false,
  employeeList: [],
  employeeMediaUploading: false,
  employeeMediaInfo: null,
  employeeMobilizationLoading: false,
  employeeMobilizationSaving: false,
  employeeMobilization: null,
  loadedFor: null,
  sendingEmails: false,
  usersListLoading: false,
  usersList: [],
  usersItem: null,
  usersItemLoading: false,
  usersItemSaving: false,
  usersItemSaved: false,
  newUsersItemId: null,
  usersPageInfo: PageInfo,
  usersLoadedFor: null,
  usersAddItemLoading: false,
  usersActiveFilter: {
    searchText: null,
  },
  activeFilter: {
    searchText: null,
  },

  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.EMPLOYEE_EMAILS_SENDING: {
      return { ...state, sendingEmails: true, error: null };
    }
    case Actions.EMPLOYEE_EMAILS_SENT: {
      return { ...state, sendingEmails: false, error: null };
    }

    case Actions.USERS_ITEM_LOADING: {
      return {
        ...state,
        usersItemLoading: true,
        newUsersItemId: null,
        usersItem: null,
        error: null,
      };
    }
    case Actions.USERS_ITEM_SUCCESS: {
      return {
        ...state,
        usersItemLoading: false,
        //  newUsersItemId: null,
        usersItem: action.payload,
        error: null,
      };
    }
    case Actions.USERS_ITEM_ERROR: {
      return {
        ...state,
        usersItemLoading: false,
        usersItem: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.USERS_LOADING: {
      return {
        ...state,
        usersListLoading: true,
        usersList: [],
        error: null,
      };
    }
    case Actions.USERS_SUCCESS: {
      return {
        ...state,
        usersListLoading: false,
        usersList: [...action.payload.data],
        usersLoadedFor: action.payload?.additionalInfo,
        usersPageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },
        error: null,
      };
    }

    case Actions.USERS_ERROR: {
      return {
        ...state,
        usersListLoading: false,
        usersList: [],
        usersLoadedFor: null,
        usersActiveFilter: { ...INIT_STATE.usersActiveFilter },
        usersPageInfo: {
          ...PageInfo,
          HasNext: false,
          HasPrevious: false,
        },
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.USERS_LIST_LOADING: {
      return {
        ...state,
        usersListLoading: true,
        usersLoadedFor: null,
        error: null,
      };
    }
    case Actions.USERS_LIST_SUCCESS: {
      return {
        ...state,
        usersListLoading: false,
        usersList: [...action.payload.data],
        usersLoadedFor: action.payload?.additionalInfo,
        usersPageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },
        error: null,
      };
    }
    case Actions.USERS_LIST_ERROR: {
      return {
        ...state,
        usersListLoading: false,
        usersList: [],
        usersLoadedFor: null,
        usersActiveFilter: { ...INIT_STATE.usersActiveFilter },
        usersPageInfo: {
          ...PageInfo,
          HasNext: false,
          HasPrevious: false,
        },
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    case Actions.USERS_ITEM_SAVING: {
      return {
        ...state,
        usersItemSaved: false,
        usersItemSaving: true,
        error: null,
      };
    }
    case Actions.USERS_ITEM_SAVED: {
      return {
        ...state,
        usersItemSaving: false,
        usersItemSaved: true,
        usersItem: action.payload,
        usersItemCreated:
          action.payload.id === state.usersItemCreated
            ? null
            : state.usersItemCreated,
        error: null,
      };
    }
    case Actions.USERS_ITEM_SAVED_HANDLED: {
      return {
        ...state,
        usersItemSaved: false,
      };
    }
    case Actions.USERS_ITEM_SAVE_ERROR: {
      return {
        ...state,
        usersItemSaving: false,
        usersItemSaved: false,
        newUsersItemId: null, // This prevents the continuous redirection from list to edit page.
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    case Actions.USERS_ITEM_ADD_SAVING: {
      return {
        ...state,
        usersAddItemLoading: true,
        newUsersItemId: null,
        usersItem: null,
        error: null,
      };
    }
    case Actions.USERS_ITEM_ADD_SAVED: {
      const pi = state.pageInfo;
      const usrList = state.usersList;
      if (!pi.HasNext) {
        // We are on last page so append the new employee to list
        // The other option is to dispatch the fetch emp list if we are on the last page.
        if (pi.Total % pi.PageSize) {
          // If there is room on the last page then append to usersList
          usrList.push(action.payload);
          pi.Total += 1;
        } else {
          // No room on last page so add new page
          pi.Total += 1;
          pi.HasNext = true;
          pi.TotalPages += 1;
        }
      }
      return {
        ...state,
        usersPageInfo: {
          ...state.pageInfo,
          ...pi,
        },
        usersAddItemLoading: false,
        newUsersItemId: action.payload.userCode,
        usersItem: action.payload,
        error: null,
      };
    }
    case Actions.USERS_ITEM_SAVE_ADD_ERROR: {
      return {
        ...state,
        usersAddItemLoading: false,
        newUsersItemId: null,
        usersItem: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    case Actions.EMPLOYEE_ITEM_LOADING: {
      return {
        ...state,
        employeeItemLoading: true,
        newEmployeeItemId: null,
        employeeItem: null,
        employeeMediaInfo: null,
        error: null,
      };
    }
    case Actions.EMPLOYEE_ITEM_SUCCESS: {
      let newQl = state.employeeList;
      if (!utils.isEmpty(state.employeeList)) {
        newQl = state.employeeList.map((q) => {
          if (q.empId === action.payload.empId) {
            q = action.payload;
          }
          return q;
        });
      }
      return {
        ...state,
        employeeItemLoading: false,
        employeeItem: action.payload,
        employeeList: newQl,
        error: null,
      };
    }
    case Actions.EMPLOYEE_ITEM_ERROR: {
      return {
        ...state,
        employeeItemLoading: false,
        employeeItem: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.EMPLOYEE_ITEM_ADD_SAVING: {
      return {
        ...state,
        employeeAddItemLoading: true,
        newEmployeeItemId: null,
        employeeItem: null,
        employeeMediaInfo: null,
        error: null,
      };
    }
    case Actions.EMPLOYEE_ITEM_ADD_SUCCESS: {
      const pi = state.pageInfo;
      const empList = state.employeeList;
      if (!pi.HasNext) {
        // We are on last page so append the new employee to list
        // The other option is to dispatch the fetch emp list if we are on the last page.
        if (pi.Total % pi.PageSize) {
          // If there is room on the last page then append to employeeList
          empList.push(action.payload);
          pi.Total += 1;
        } else {
          // No room on last page so add new page
          pi.Total += 1;
          pi.HasNext = true;
          pi.TotalPages += 1;
        }
      }
      return {
        ...state,
        pageInfo: {
          ...state.pageInfo,
          ...pi,
        },
        employeeAddItemLoading: false,
        newEmployeeItemId: action.payload.empId,
        employeeItem: action.payload,
        employeeMediaInfo: null,
        error: null,
      };
    }
    case Actions.EMPLOYEE_ITEM_ADD_ERROR: {
      return {
        ...state,
        employeeAddItemLoading: false,
        newEmployeeItemId: null,
        employeeItem: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.EMPLOYEE_ITEM_SAVING: {
      return {
        ...state,
        employeeItemSaved: false,
        employeeItemSaving: true, // not nulling employeeItem, incase of error we dont want to lose progress?
        error: null,
      };
    }
    case Actions.EMPLOYEE_ITEM_SAVED: {
      let stateEmpList = state.employeeList;
      let index = stateEmpList.findIndex(
        (e) => e.empId === action.payload.empId
      );
      if (index >= 0) {
        stateEmpList[index] = {
          ...stateEmpList[index],
          ...action.payload,
        };
      }
      return {
        ...state,
        employeeList: [...stateEmpList],
        employeeItemSaving: false,
        employeeItem: action.payload,
        employeeItemSaved: true,
        newEmployeeItemId: null,
        error: null,
      };
    }
    case Actions.EMPLOYEE_ITEM_SAVED_HANDLED: {
      return {
        ...state,
        employeeItemSaved: false,
      };
    }
    case Actions.EMPLOYEE_ITEM_SAVE_ERROR: {
      return {
        ...state,
        employeeItemSaving: false,
        employeeItemSaved: false,
        newEmployeeItemId: null, // This prevents the continuous redirection from list to edit page.
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.EMPLOYEE_LIST_LOADING: {
      return {
        ...state,
        employeeListLoading: true,
        employeeItem: null,
        loadedFor: null,
        //employeeList: [],
        error: null,
      };
    }
    case Actions.EMPLOYEE_LIST_SUCCESS: {
      return {
        ...state,
        employeeListLoading: false,
        employeeList: [...action.payload.data],
        loadedFor: action.payload?.additionalInfo,
        pageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },

        error: null,
      };
    }
    case Actions.EMPLOYEE_LIST_ERROR: {
      return {
        ...state,
        employeeListLoading: false,
        employeeList: [],
        loadedFor: null,
        pageInfo: {
          ...PageInfo,
          HasNext: false,
          HasPrevious: false,
        },
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.EMPLOYEE_MOBILIZATION_LOADING: {
      return {
        ...state,
        employeeMobilizationLoading: true,
        employeeMobilizationSaving: false,
        employeeMobilization: null,
        error: null,
      };
    }
    case Actions.EMPLOYEE_MOBILIZATION_SUCCESS: {
      return {
        ...state,
        employeeMobilizationLoading: false,
        employeeMobilization: action.payload,
        error: null,
      };
    }
    case Actions.EMPLOYEE_MOBILIZATION_SAVING: {
      return {
        ...state,
        employeeMobilizationLoading: false,
        employeeMobilizationSaving: true,
        error: null,
      };
    }
    case Actions.EMPLOYEE_MOBILIZATION_SAVED: {
      let emUpdated = action.payload;
      let stateEmpList = [...state.employeeList];
      let index = stateEmpList.findIndex((e) => e.empId === emUpdated.empId);
      if (index >= 0) {
        stateEmpList[index] = {
          ...stateEmpList[index],
          campusId: emUpdated.campusId,
          section: emUpdated.section,
          designationId: emUpdated.designationId,
          designation: emUpdated.designation,
          campusLocation: emUpdated.campus,
        };
      }

      return {
        ...state,
        employeeList: [...stateEmpList],
        employeeMobilizationSaving: false,
        employeeMobilization: action.payload,
        error: null,
      };
    }
    case Actions.EMPLOYEE_MOBILIZATION_ERROR: {
      return {
        ...state,
        employeeMobilizationLoading: false,
        employeeMobilizationSaving: false,
        employeeMobilization: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    default: {
      return {
        ...state,
        isLoading: false,
        employeeListLoading: false,
      };
    }
  }
};
