import * as Actions from "../actions";
import * as common from "../actions/Common";
import designationService from "appServices/designationService";

const fetchDesignation = () => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.DESIGNATION_LIST_LOADING,
    Actions.DESIGNATION_LIST_SUCCESS,
    Actions.DESIGNATION_LIST_ERROR,
    designationService.fetchDesignation
  );

const saveDesignation = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.DESIGNATION_ITEM_UPDATING,
    Actions.DESIGNATION_ITEM_UPDATED,
    Actions.DESIGNATION_ITEM_ERROR,
    designationService.saveDesignation,
    query
  );

export { fetchDesignation, saveDesignation };
