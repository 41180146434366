import { Redirect, Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import { OtpRoute, NotFoundRedirect } from "../components/app/RouteComponents";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}home`}
        component={asyncComponent(() => import("./home"))}
      />
      <Route
        path={`${match.url}Quiz/Edit/:key`}
        component={asyncComponent(() => import("./quiz/edit"))}
      />
      <Route
        path={`${match.url}quiz`}
        component={asyncComponent(() => import("./quiz"))}
      />
      <Route
        path={`${match.url}ChangePassword`}
        component={asyncComponent(() => import("../containers/ChangePassword"))}
      />

      <Route
        path={`${match.url}qbank`}
        component={asyncComponent(() => import("./qbank"))}
      />
      <Route
        path={`${match.url}Plan/Edit/:key`}
        component={asyncComponent(() => import("./plan/edit"))}
      />
      <Route
        path={`${match.url}SubjectUnits/`}
        component={asyncComponent(() => import("./plan/subjectUnits"))}
      />
      <Route
        path={`${match.url}notifications/edit/`}
        component={asyncComponent(() => import("./notifications/edit"))}
      />
      <Route
        path={`${match.url}notifications/`}
        component={asyncComponent(() => import("./notifications"))}
      />
      <Route
        path={`${match.url}subject`}
        component={asyncComponent(() => import("./subject"))}
      />
      <Route
        path={`${match.url}exam`}
        component={asyncComponent(() => import("./subject/exam"))}
      />
      <Route
        path={`${match.url}campus`}
        component={asyncComponent(() => import("./campus"))}
      />
      <Route
        path={`${match.url}grade`}
        component={asyncComponent(() => import("./grade"))}
      />
      <Route
        path={`${match.url}section`}
        component={asyncComponent(() => import("./section"))}
      />
      <Route
        path={`${match.url}plan`}
        component={asyncComponent(() => import("./plan"))}
      />

      <Route
        path={`${match.url}Question/Edit/:key`}
        component={asyncComponent(() => import("./qbank/edit"))}
      />
      <Route
        path={`${match.url}diary/`}
        component={asyncComponent(() => import("./diary"))}
      />

      <Route
        path={`${match.url}hr`}
        component={asyncComponent(() => import("./hr"))}
      />
      <Route
        path={`${match.url}Users/Edit/:key`}
        component={asyncComponent(() => import("./users/edit"))}
      />
      <Route
        path={`${match.url}users/`}
        component={asyncComponent(() => import("./users"))}
      />
      <Route
        path={`${match.url}kpi`}
        component={asyncComponent(() => import("./kpi"))}
      />

      <Route
        path={`${match.url}ec`}
        component={asyncComponent(() => import("./ec"))}
      />
      <Route
        path={`${match.url}studentInformation/`}
        component={asyncComponent(() => import("./studentInformation"))}
      />
      <Route
        path={`${match.url}fee/`}
        component={asyncComponent(() => import("./fee"))}
      />
      <Route
        path={`${match.url}Accounts/`}
        component={asyncComponent(() => import("./accounts"))}
      />
      <Route
        path={`${match.url}exitProforma/`}
        component={asyncComponent(() =>
          import("./StudentManagement/exitProforma")
        )}
      />
      <Route
        path={`${match.url}StudentManagement/`}
        component={asyncComponent(() => import("./StudentManagement"))}
      />
      <Route
        path={`${match.url}CampusManagement/`}
        component={asyncComponent(() => import("./CampusManagement"))}
      />
      <Route
        path={`${match.url}Transport`}
        component={asyncComponent(() => import("./transport"))}
      />
      <Route
        path={`${match.url}FeeManagement`}
        component={asyncComponent(() => import("./feeManagement"))}
      />
      <Route
        path={`${match.url}sample`}
        component={asyncComponent(() => import("./SamplePage"))}
      />
      <Route
        path={`${match.url}adminResource`}
        component={asyncComponent(() => import("./adminResource"))}
      />

      <Route component={NotFoundRedirect} />
    </Switch>
  </div>
);

export default App;
