import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useClaims, ClaimKeys } from "@mb";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { menuList } from "./data.sidebar.content";


const SidebarContent = () => {

  const allClaims = [...Object.keys(ClaimKeys)];
  const claims = useClaims(allClaims);

  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname; //.substr(1);
  //const defaultOpenKeys = selectedKeys.split("/")[1];

  const checkClaims = (claim) => {
    return (
      claim === "AllowedItem" || claims[allClaims.findIndex((c) => c === claim)]
    );
  };

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            // defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
            // inlineCollapsed={sidebarCollapsed}
          >
            {menuList.map((data) => {
              return data.hasSubMenus
                ? checkClaims(data.claimKey) && (
                  <Menu.ItemGroup
                    key={data.key}
                    className="gx-menu-group"
                    title={<IntlMessages id={data.intlMessagesId} />}
                  //{...other} // notice the other props, this is where the 'isRootMenu' is injected from the <Menu> children
                  >
                    {data.subMenus.map(
                      (smItem) =>
                        checkClaims(smItem.claimKey) && (
                          <Menu.Item key={smItem.key} icon={<i className={smItem.iconClass} />}>
                            <Link to={smItem.linkTo}>

                              <IntlMessages id={smItem.intlMessagesId} />
                            </Link>
                          </Menu.Item>
                        )
                    )}
                  </Menu.ItemGroup>
                )
                : checkClaims(data.claimKey) && (
                  <Menu.Item key={data.key}>
                    <Link to={data.linkTo}>
                      <i className={data.iconClass} />
                      <IntlMessages id={data.intlMessagesId} />
                    </Link>
                  </Menu.Item>
                );
            })}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
