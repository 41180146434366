import * as Actions from "../actions/";
import * as common from "../actions/Common";

import profileService from "appServices/profileService";
import { utils } from "@mb";

export { getMyAssignedSubjects, loadProfile, getMyAssignedSections };

function loadProfile() {
  return async (dispatch) => {
    try {
      // dispatch(Actions.profileActionCreators.profileIsLoading(true));
      dispatch(common.fetchStart(Actions.PROFILE_LOADING));

      const studentProfile = await profileService.getMyProfile();
      return dispatch({
        type: Actions.PROFILE_SUCCESS,
        studentProfile: studentProfile,
      });
    } catch (error) {
      debugger;
      return dispatch({
        type: Actions.PROFILE_ERROR,
        payload: error, //{Message, Code}
      });
    }
  };
}
function getMySessionList() {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.PROFILE_SESSION_LIST_LOADING));

      const sessionList = await profileService.getMySessionList();
      if (utils.isEmpty(sessionList))
        return dispatch(
          common.fetchError(null, Actions.PROFILE_SESSION_LIST_ERROR)
        );
      return dispatch(
        common.fetchSuccessWithData(
          Actions.PROFILE_SESSION_LIST_SUCCESS,
          sessionList
        )
      );
    } catch (error) {
      debugger;
      return dispatch({
        type: Actions.PROFILE_SESSION_LIST_ERROR,
        payload: error, //{Message, Code}
      });
    }
  };
}

const getMyAssignedSubjects = () => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.PROFILE_SUBJ_LIST_LOADING));

    const result = await profileService.getMyAssignedSubjects();
    return dispatch(
      common.fetchSuccessWithData(Actions.PROFILE_SUBJ_LIST_SUCCESS, result)
    );
  } catch (error) {
    return dispatch(common.fetchError(error, Actions.PROFILE_SUBJ_LIST_ERROR));
  }
};
const getMyAssignedSections = () => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.PROFILE_SECTION_LIST_LOADING));

    const result = await profileService.getMyAssignedSections();
    return dispatch(
      common.fetchSuccessWithData(Actions.PROFILE_SECTION_LIST_SUCCESS, result)
    );
  } catch (error) {
    return dispatch(common.fetchError(error, Actions.PROFILE_SECTION_LIST_ERROR));
  }
};
