export const STUDENT_ATTENDANCE_LIST_LOADING =
  "STUDENT_ATTENDANCE_LIST_LOADING";
export const STUDENT_ATTENDANCE_LIST_SUCCESS =
  "STUDENT_ATTENDANCE_LIST_SUCCESS";
export const STUDENT_ATTENDANCE_LIST_ERROR = "STUDENT_ATTENDANCE_LIST_ERROR";
export const UPDATE_STUDENT_ATTENDANCE = "UPDATE_STUDENT_ATTENDANCE";

export const studentAttendanceActionCreators = {
  markAttendance: (grNo, attendance) => ({
    type: UPDATE_STUDENT_ATTENDANCE,
    payload: { grNo, attendance },
  }),
};
