export const LOOKUP_CAMPUS_LOADING = "LOOKUP_CAMPUS_LOADING";
export const LOOKUP_CAMPUS_SUCCESS = "LOOKUP_CAMPUS_SUCCESS";
export const LOOKUP_CAMPUS_ERROR = "LOOKUP_CAMPUS_ERROR";

export const LOOKUP_CAMPUS_LOCATIONS_LOADING = "LOOKUP_CAMPUS_LOCATIONS_LOADING";
export const LOOKUP_CAMPUS_LOCATIONS_SUCCESS = "LOOKUP_CAMPUS_LOCATIONS_SUCCESS";
export const LOOKUP_CAMPUS_LOCATIONS_ERROR = "LOOKUP_CAMPUS_LOCATIONS_ERROR";

export const LOOKUP_SESSION_LOADING = "LOOKUP_SESSION_LOADING";
export const LOOKUP_SESSION_SUCCESS = "LOOKUP_SESSION_SUCCESS";
export const LOOKUP_SESSION_ERROR = "LOOKUP_SESSION_ERROR";

export const LOOKUP_SECTION_LOADING = "LOOKUP_SECTION_LOADING";
export const LOOKUP_SECTION_SUCCESS = "LOOKUP_SECTION_SUCCESS";
export const LOOKUP_SECTION_ERROR = "LOOKUP_SECTION_ERROR";

export const LOOKUP_CLASSS_LOADING = "LOOKUP_CLASSS_LOADING";
export const LOOKUP_CLASSS_SUCCESS = "LOOKUP_CLASSS_SUCCESS";
export const LOOKUP_CLASSS_ERROR = "LOOKUP_CLASSS_ERROR";

export const LOOKUP_SUBJ_LOADING = "LOOKUP_SUBJ_LOADING";
export const LOOKUP_SUBJ_SUCCESS = "LOOKUP_SUBJ_SUCCESS";
export const LOOKUP_SUBJ_ERROR = "LOOKUP_SUBJ_ERROR";

export const LOOKUP_DESIGNATION_LOADING = "LOOKUP_DESIGNATION_LOADING";
export const LOOKUP_DESIGNATION_SUCCESS = "LOOKUP_DESIGNATION_SUCCESS";
export const LOOKUP_DESIGNATION_ERROR = "LOOKUP_DESIGNATION_ERROR";

export const LOOKUP_EMPLOYEE_STATUS_LOADING = "LOOKUP_EMPLOYEE_STATUS_LOADING";
export const LOOKUP_EMPLOYEE_STATUS_SUCCESS = "LOOKUP_EMPLOYEE_STATUS_SUCCESS";
export const LOOKUP_EMPLOYEE_STATUS_ERROR = "LOOKUP_EMPLOYEE_STATUS_ERROR";

export const LOOKUP_ROLE_LOADING = "LOOKUP_ROLE_LOADING";
export const LOOKUP_ROLE_SUCCESS = "LOOKUP_ROLE_SUCCESS";
export const LOOKUP_ROLE_ERROR = "LOOKUP_ROLE_ERROR";

export const LOOKUP_DEPARTMENT_LOADING = "LOOKUP_DEPARTMENT_LOADING";
export const LOOKUP_DEPARTMENT_SUCCESS = "LOOKUP_DEPARTMENT_SUCCESS";
export const LOOKUP_DEPARTMENT_ERROR = "LOOKUP_DEPARTMENT_ERROR";

export const LOOKUP_TERM_LOADING = "LOOKUP_TERM_LOADING";
export const LOOKUP_TERM_SUCCESS = "LOOKUP_TERM_SUCCESS";
export const LOOKUP_TERM_ERROR = "LOOKUP_TERM_ERROR";

export const LOOKUP_EMP_ATT_TIMETABLE_LOADING = "LOOKUP_EMP_ATT_TIMETABLE_LOADING";
export const LOOKUP_EMP_ATT_TIMETABLE_SUCCESS = "LOOKUP_EMP_ATT_TIMETABLE_SUCCESS";
export const LOOKUP_EMP_ATT_TIMETABLE_ERROR = "LOOKUP_EMP_ATT_TIMETABLE_ERROR";

export const LOOKUP_BANK_ACCOUNTS_LOADING = "LOOKUP_BANK_ACCOUNTS_LOADING";
export const LOOKUP_BANK_ACCOUNTS_SUCCESS = "LOOKUP_BANK_ACCOUNTS_SUCCESS";
export const LOOKUP_BANK_ACCOUNTS_ERROR = "LOOKUP_BANK_ACCOUNTS_ERROR";

