import * as Actions from "../actions";
import * as common from "../actions/Common";
import accountsService from "appServices/accountsService";

const fetchChequeList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.VIEW_CHEQUE_LIST_LOADING,
    Actions.VIEW_CHEQUE_LIST_SUCCESS,
    Actions.VIEW_CHEQUE_LIST_ERROR,
    accountsService.fetchChequeList,
    query
  );
const updateCheque = (query) => async (dispatch) =>{
  console.log("updateCheque",query)
  await common.thunkHelper(
    dispatch,
    Actions.CHEQUE_PRESENT_DATE_SAVING,
    Actions.CHEQUE_PRESENT_DATE_SAVED,
    Actions.CHEQUE_PRESENT_DATE_ERROR,
    accountsService.updateCheque,
    query
  );

}
 
 


export {
  fetchChequeList,
  updateCheque,
};
