import * as Actions from "../actions";
import * as common from "../actions/Common";

import lookupService from "appServices/lookupService";

export {
  fetchCampusList,
  fetchSessionList,
  fetchClassList,
  fetchSectionList,
  fetchSubjectList,
  fetchSubjectListBySection,
  fetchDesignationList,
  fetchEmployeeStatusList,
  fetchEmployeeRolesList,
  fetchCampusLocationsList,
  fetchDepartmentList,
  fetchClassListBySession,
  fetchSessionListByCampus,
  fetchLatestClassListByCampusId,
  fetchAllCampusList,
  fetchTermList,
  fetchActiveSessionList,
  fetchEmpAttTimeTableList,
  fetchBankAccounts,
};

export const LookupType = {
  Campus: 1000,
  Session: 1001,
  CampusLocation: 1002,
  SessionByCampusId: 1003,
  CampusByUser: 1004,
  ActiveSessions: 1005,

  LatestClasses: 2000,
  ClassesBySessionId: 2001,
  LatestClassesByCampusId: 2003,
  SectionByClass: 2004,

  LatestSubjects: 3000,
  SubjectsByClassId: 3001,
  LatestSubjectsBySectionId: 3002,
  Term: 3003,
  Designations: 5001,
  EmpAttTimeTable: 6001,
  BankAccounts: 7001,
  COAccounts: 7002,
};
const ListOfValueGroups = {
  EmploymentStatus: "EmploymentStatus",
  GradeCalculationType: "GradeCalculationType",
  GradingNumberRange: "GradingNumberRange",
  GradingType: "GradingType",
  TaskStatus: "TaskStatus",
  Role: "Role",
  DiaryType: "DiaryType",
  Designation: "Designation",
  Department: "Department",
};
const fetchTermList = (sectionId) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_TERM_LOADING,
    Actions.LOOKUP_TERM_SUCCESS,
    Actions.LOOKUP_TERM_ERROR,
    lookupService.getList,
    {
      Type: LookupType.Term,
      info: sectionId,
    }
  );

const fetchEmployeeStatusList = () => async (dispatch) => {
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_EMPLOYEE_STATUS_LOADING,
    Actions.LOOKUP_EMPLOYEE_STATUS_SUCCESS,
    Actions.LOOKUP_EMPLOYEE_STATUS_ERROR,
    lookupService.getLov,
    ListOfValueGroups.EmploymentStatus
  );
};

const fetchEmployeeRolesList = () => async (dispatch) => {
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_ROLE_LOADING,
    Actions.LOOKUP_ROLE_SUCCESS,
    Actions.LOOKUP_ROLE_ERROR,
    lookupService.getLov,
    ListOfValueGroups.Role
  );
};

const fetchCampusLocationsList = () => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_CAMPUS_LOCATIONS_LOADING,
    Actions.LOOKUP_CAMPUS_LOCATIONS_SUCCESS,
    Actions.LOOKUP_CAMPUS_LOCATIONS_ERROR,
    lookupService.getList,
    {
      type: LookupType.CampusLocation,
    }
  );

const fetchCampusList = () => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_CAMPUS_LOADING,
    Actions.LOOKUP_CAMPUS_SUCCESS,
    Actions.LOOKUP_CAMPUS_ERROR,
    lookupService.getList,
    {
      type: LookupType.CampusByUser,
    }
  );

const fetchAllCampusList = () => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_CAMPUS_LOADING,
    Actions.LOOKUP_CAMPUS_SUCCESS,
    Actions.LOOKUP_CAMPUS_ERROR,
    lookupService.getList,
    {
      type: LookupType.Campus,
    }
  );

const fetchSessionList = (campusId) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_SESSION_LOADING,
    Actions.LOOKUP_SESSION_SUCCESS,
    Actions.LOOKUP_SESSION_ERROR,
    lookupService.getList,
    {
      type: LookupType.Session,
      info: campusId,
    }
  );

const fetchActiveSessionList = (campusId) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_ACTIVE_SESSION_LOADING,
    Actions.LOOKUP_ACTIVE_SESSION_SUCCESS,
    Actions.LOOKUP_ACTIVE_SESSION_ERROR,
    lookupService.getList,
    {
      type: LookupType.ActiveSessions,
    }
  );

const fetchSessionListByCampus = (campusId) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_SESSION_LOADING,
    Actions.LOOKUP_SESSION_SUCCESS,
    Actions.LOOKUP_SESSION_ERROR,
    lookupService.getList,
    {
      type: LookupType.SessionByCampusId,
      info: campusId,
    }
  );
const fetchClassList = () => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_CLASSS_LOADING,
    Actions.LOOKUP_CLASSS_SUCCESS,
    Actions.LOOKUP_CLASSS_ERROR,
    lookupService.getList,
    {
      type: LookupType.LatestClasses,
    }
  );
const fetchSectionList = (gradeId) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_SECTION_LOADING,
    Actions.LOOKUP_SECTION_SUCCESS,
    Actions.LOOKUP_SECTION_ERROR,
    lookupService.getList,
    {
      type: LookupType.SectionByClass,
      info: gradeId,
    }
  );

const fetchLatestClassListByCampusId = (campusId) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_CLASSS_LOADING,
    Actions.LOOKUP_CLASSS_SUCCESS,
    Actions.LOOKUP_CLASSS_ERROR,
    lookupService.getList,
    {
      type: LookupType.LatestClassesByCampusId,
      info: campusId,
    }
  );

const fetchClassListBySession = (sessionId) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_CLASSS_LOADING,
    Actions.LOOKUP_CLASSS_SUCCESS,
    Actions.LOOKUP_CLASSS_ERROR,
    lookupService.getList,
    {
      type: LookupType.ClassesBySessionId,
      info: sessionId,
    }
  );
const fetchSubjectList = (classId) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_SUBJ_LOADING,
    Actions.LOOKUP_SUBJ_SUCCESS,
    Actions.LOOKUP_SUBJ_ERROR,
    lookupService.getList,
    {
      type: LookupType.LatestSubjects,
      info: classId,
    }
  );

const fetchSubjectListBySection = (sectionId) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_SUBJ_LOADING,
    Actions.LOOKUP_SUBJ_SUCCESS,
    Actions.LOOKUP_SUBJ_ERROR,
    lookupService.getList,
    {
      type: LookupType.LatestSubjectsBySectionId,
      info: sectionId,
    }
  );

const fetchDepartmentList = (INPUT) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_DEPARTMENT_LOADING,
    Actions.LOOKUP_DEPARTMENT_SUCCESS,
    Actions.LOOKUP_DEPARTMENT_ERROR,
    lookupService.getLov,
    ListOfValueGroups.Department
  );


  const fetchDesignationList = () => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_DESIGNATION_LOADING,
    Actions.LOOKUP_DESIGNATION_SUCCESS,
    Actions.LOOKUP_DESIGNATION_ERROR,
    lookupService.getList,
    {
      type: LookupType.Designations
    }
  );

  const fetchEmpAttTimeTableList = () => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_EMP_ATT_TIMETABLE_LOADING,
    Actions.LOOKUP_EMP_ATT_TIMETABLE_SUCCESS,
    Actions.LOOKUP_EMP_ATT_TIMETABLE_ERROR,
    lookupService.getList,
    {
      type: LookupType.EmpAttTimeTable
    }
  );
  const fetchBankAccounts = () => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LOOKUP_BANK_ACCOUNTS_LOADING,
    Actions.LOOKUP_BANK_ACCOUNTS_SUCCESS,
    Actions.LOOKUP_BANK_ACCOUNTS_ERROR,
    lookupService.getList,
    {
      type: LookupType.BankAccounts
    }
  );
// const fetchDesignationList = () => async (dispatch) => {
//   try {
//     dispatch(common.fetchStart(Actions.LOOKUP_DESIGNATION_LOADING));

//     const res = await lookupService.getLov(ListOfValueGroups.Designation);
//     const result = res.map((d) => ({ ...d, value: d.numericValue }));
//     return dispatch(
//       common.fetchSuccessWithData(Actions.LOOKUP_DESIGNATION_SUCCESS, result)
//     );
//   } catch (error) {
//     debugger;
//     return dispatch(common.fetchError(error, Actions.LOOKUP_DESIGNATION_ERROR));
//   }
// };
//     const res = await lookupService.getLov(ListOfValueGroups.Designation);
//     const result = res.map((d) => ({ ...d, value: d.numericValue }));
//     return dispatch(
//       common.fetchSuccessWithData(Actions.LOOKUP_DESIGNATION_SUCCESS, result)
//     );
//   } catch (error) {
//     debugger;
//     return dispatch(common.fetchError(error, Actions.LOOKUP_DESIGNATION_ERROR));
//   }
// };
