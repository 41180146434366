import * as Actions from "../actions";
import * as common from "../actions/Common";
import studentManagementService from "../../appServices/studentManagementService";

export {
  fetchExitStudent,
  saveStudentExit
};

const fetchExitStudent = (GRNo) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.EXIT_STUDENT_LOADING,
    Actions.EXIT_STUDENT_SUCCESS,
    Actions.EXIT_STUDENT_ERROR,
    studentManagementService.fetchExitStudent,
    GRNo
  );
const saveStudentExit = (data) => async (dispatch) =>
    await common.thunkHelper(
    dispatch,
    Actions.EXIT_STUDENT_SAVE_LOADING,
    Actions.EXIT_STUDENT_SAVE_SUCCESS,
    Actions.EXIT_STUDENT_SAVE_ERROR,
    studentManagementService.saveStudentExit,
    data
  );


