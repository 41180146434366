export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_CREATING = "NOTIFICATION_CREATING";
export const NOTIFICATION_CREATED = "NOTIFICATION_CREATED";
export const NOTIFICATION_SAVING = "NOTIFICATION_SAVING";
export const NOTIFICATION_SAVED = "NOTIFICATION_SAVED";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";

export const NOTIFICATION_LIST_LOADING = "NOTIFICATION_LIST_LOADING";
export const NOTIFICATION_LIST_SUCCESS = "NOTIFICATION_LIST_SUCCESS";
export const NOTIFICATION_LIST_ERROR = "NOTIFICATION_LIST_ERROR";

export const NOTIFICATION_EDIT_SELECT = "NOTIFICATION_EDIT_SELECT";
export const NOTIFICATION_ITEM_SAVED_HANDLED =
  "NOTIFICATION_ITEM_SAVED_HANDLED";

export const NOTIFICATION_SET_FILTER = "NOTIFICATION_SET_FILTER";

export const notificationActionCreators = {
  notificationItemSaveHandled: () => ({
    type: NOTIFICATION_ITEM_SAVED_HANDLED,
  }),
  setNotificationForEditing: (id) => ({
    type: NOTIFICATION_EDIT_SELECT,
    payload: id,
  }),
  setActiveFilter: (filter) => ({
    type: NOTIFICATION_SET_FILTER,
    payload: filter,
  }),
};
