import * as Actions from "../actions";

const INIT_STATE = {
  staffAttendanceListLoading: false,
  staffAttendanceList: [],

  error: {
    code: null,
    message: null,
  },
};

const staffAttendanceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.STAFF_ATTENDANCE_LIST_LOADING: {
      return {
        ...state,
        staffAttendanceListLoading: true,
        staffAttendanceList: [],
      };
    }
    case Actions.STAFF_ATTENDANCE_LIST_SUCCESS: {
      return {
        ...state,
        staffAttendanceListLoading: false,
        staffAttendanceList: [...action.payload],
      };
    }
    case Actions.STAFF_ATTENDANCE_LIST_ERROR: {
      return {
        ...state,
        staffAttendanceListLoading: false,
        staffAttendanceList: [],
        error: {
          ...state.error,
          code: action?.payload?.code,
          message: action?.payload?.message,
        },
      };
    }
    case Actions.STAFF_ATTENDANCE_LIST_RESET: {
      return {
        ...state,
        staffAttendanceListLoading: false,
        staffAttendanceList: [],
        error: {
          code: null,
          message: null,
        },
      };
    }
    case Actions.STAFF_ATTENDANCE_ITEM_UPDATE: {
      const idx = state.staffAttendanceList.findIndex(
        (entry) => entry.id === action.payload.id
      );
      const newList = [
        ...state.staffAttendanceList.slice(0, idx),
        action.payload,
        ...state.staffAttendanceList.slice(idx + 1),
      ];
      return {
        ...state,
        staffAttendanceList: newList,
      };
    }
    case Actions.STAFF_ATTENDANCE_ITEM_UPDATED: {
      const idx = state.staffAttendanceList.findIndex(
        (entry) => entry.id === action.payload.id
      );
      const newList = [
        ...state.staffAttendanceList.slice(0, idx),
        action.payload,
        ...state.staffAttendanceList.slice(idx + 1),
      ];
      return {
        ...state,
        staffAttendanceList: newList,
        staffAttendanceListLoading: false,
      };
    }
    case Actions.STAFF_ATTENDANCE_ITEM_UPDATING: {
      return {
        ...state,
        staffAttendanceListLoading: true,
      };
    }
    case Actions.STAFF_ATTENDANCE_ITEM_ERROR: {
      return {
        ...state,
        staffAttendanceListLoading: false,
        error: {
          ...state.error,
          code: action?.payload?.code,
          message: action?.payload?.message,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default staffAttendanceReducer;
