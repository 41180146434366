export const EC_LIST_LOADING = "EC_LIST_LOADING";
export const EC_LIST_SUCCESS = "EC_LIST_SUCCESS";
export const EC_LIST_ERROR = "EC_LIST_ERROR";

export const EC_ITEM_LOADING = "EC_ITEM_LOADING";
export const EC_ITEM_SUCCESS = "EC_ITEM_SUCCESS";
export const EC_ITEM_ERROR = "EC_ITEM_ERROR";

export const EC_ITEM_ADDING = "EC_ITEM_ADDING";
export const EC_ITEM_ADD_SUCCESS = "EC_ITEM_ADD_SUCCESS";
export const EC_ITEM_ADD_ERROR = "EC_ITEM_ADD_ERROR";

export const EC_ITEM_SAVING = "EC_ITEM_SAVING";
export const EC_ITEM_SAVE_SUCCESS = "EC_ITEM_SAVE_SUCCESS";
export const EC_ITEM_SAVE_ERROR = "EC_ITEM_SAVE_ERROR";

export const EC_DETAILS_LIST_LOADING = "EC_DETAILS_LIST_LOADING";
export const EC_DETAILS_LIST_SUCCESS = "EC_DETAILS_LIST_SUCCESS";
export const EC_DETAILS_LIST_ERROR = "EC_DETAILS_LIST_ERROR";

export const EC_DETAILS_ITEM_ADDING = "EC_DETAILS_ITEM_ADDING";
export const EC_DETAILS_ITEM_ADD_SUCCESS = "EC_DETAILS_ITEM_ADD_SUCCESS";
export const EC_DETAILS_ITEM_ADD_ERROR = "EC_DETAILS_ITEM_ADD_ERROR";

export const EC_DETAILS_ITEM_SAVING = "EC_DETAILS_ITEM_SAVING";
export const EC_DETAILS_ITEM_SAVE_SUCCESS = "EC_DETAILS_ITEM_SAVE_SUCCESS";
export const EC_DETAILS_ITEM_SAVE_ERROR = "EC_DETAILS_ITEM_SAVE_ERROR";

export const EC_DETAILS_ITEM_DELETING = "EC_DETAILS_ITEM_DELETING";
export const EC_DETAILS_ITEM_DELETE_SUCCESS = "EC_DETAILS_ITEM_DELETE_SUCCESS";
export const EC_DETAILS_ITEM_DELETE_ERROR = "EC_DETAILS_ITEM_DELETE_ERROR";

export const EC_RECOMMENDATIONS_LOADING = "EC_RECOMMENDATIONS_LOADING";
export const EC_RECOMMENDATIONS_SUCCESS = "EC_RECOMMENDATIONS_SUCCESS";
export const EC_RECOMMENDATIONS_ERROR = "EC_RECOMMENDATIONS_ERROR";

export const EC_RECOMMENDATION_SAVING = "EC_RECOMMENDATION_SAVING";
export const EC_RECOMMENDATION_SAVE_SUCCESS = "EC_RECOMMENDATION_SAVE_SUCCESS";
export const EC_RECOMMENDATION_SAVE_ERROR = "EC_RECOMMENDATION_SAVE_ERROR";

export const EC_DETAILS_ITEM_REORDER_SUCCESS =
  "EC_DETAILS_ITEM_REORDER_SUCCESS";
