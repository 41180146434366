import * as Actions from "../actions/diary.actions";
import { PageInfo } from "../../constants/GlobalConstants";
import { utils } from "../../@mb";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,
  pageInfo: PageInfo,
  diaryItemId: null,
  diaryItem: null,
  diaryItemLoading: false,
  diaryItemDetailList: [],
  diaryItemDetailListLoading: false,
  diaryItemDetailSaving: false,

  diaryListLoading: false,
  diaryList: [],
  diaryMediaUploading: false,
  diaryMediaInfo: null,

  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.DIARY_ITEM_LOADING: {
      return {
        ...state,
        diaryItem: null,
        diaryItemLoading: true,
        error: null,
      };
    }
    case Actions.DIARY_ITEM_SUCCESS: {
      return {
        ...state,
        diaryItemLoading: false,
        diaryItem: action.payload,
        diaryItemDetailList: [],
        error: null,
      };
    }
    case Actions.DIARY_ITEM_ERROR: {
      return {
        ...state,
        diaryItemLoading: false,
        diaryItem: null,
        diaryItemDetailList: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.DIARY_DETAILS_LIST_LOADING: {
      return {
        ...state,
        diaryItemDetailListLoading: true,
        diaryItemDetailList: null,
        error: null,
      };
    }
    case Actions.DIARY_DETAILS_LIST_SUCCESS: {
      return {
        ...state,
        diaryItemDetailListLoading: false,
        diaryItemDetailList: [...action.payload],
        error: null,
      };
    }
    case Actions.DIARY_DETAILS_LIST_ERROR: {
      return {
        ...state,
        diaryItemDetailListLoading: false,
        diaryItemDetailList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.DIARY_DETAIL_ITEM_SAVING: {
      return {
        ...state,
        diaryItemDetailSaving: true,
        error: null,
      };
    }
    case Actions.DIARY_DETAIL_ITEM_SAVE_SUCCESS: {
      let diaryDetailItemUpdated = action.payload;
      let index = state.diaryItemDetailList.findIndex(
        (i) => i.id === diaryDetailItemUpdated.id
      );
      let stateDiaryDetailList = state.diaryItemDetailList;
      if (index > 0) {
        stateDiaryDetailList[index] = diaryDetailItemUpdated;
      }
      return {
        ...state,
        diaryItemDetailSaving: false,
        diaryItemDetailList: [...stateDiaryDetailList],
        error: null,
      };
    }
    case Actions.DIARY_DETAIL_ITEM_SAVE_ERROR: {
      return {
        ...state,
        diaryItemDetailSaving: false,
        diaryItem: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    default: {
      return {
        ...state,
        isLoading: false,
        diaryListLoading: false,
      };
    }
  }
};
