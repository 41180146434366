import * as Actions from "../actions/search.actions";
import { PageInfo } from "../../constants/GlobalConstants";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,
  searchLoading: false,
  searchResult: null,
  pageInfo: PageInfo,
  employeeListLoading: false,
  employeeList: [],
  loadedFor: null,
  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.SEARCH_LOADING: {
      return {
        ...state,
        searchLoading: true,
        searchResult: null,
        loadedFor: null,
        error: null,
      };
    }
    case Actions.SEARCH_SUCCESS: {
      return {
        ...state,
        searchLoading: false,
        searchResult: action.payload,
        loadedFor: action.payload.additionalInfo,
        error: null,
      };
    }
    case Actions.SEARCH_ERROR: {
      return {
        ...state,
        searchLoading: false,
        searchResult: null,
        loadedFor: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: `Unable to perform search!`,
        },
      };
    }
    case Actions.SEARCH_EMPLOYEE_LIST_LOADING: {
      return {
        ...state,
        employeeListLoading: true,
        employeeItem: null,
        loadedFor: null,
        error: null,
      };
    }
    case Actions.SEARCH_EMPLOYEE_LIST_SUCCESS: {
      return {
        ...state,
        employeeListLoading: false,
        employeeList: [...action.payload.data],
        loadedFor: action.payload.additionalInfo,
        pageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },

        error: null,
      };
    }
    case Actions.SEARCH_EMPLOYEE_LIST_ERROR: {
      return {
        ...state,
        employeeListLoading: false,
        employeeList: [],
        pageInfo: {
          ...PageInfo,
          HasNext: false,
          HasPrevious: false,
        },
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    case Actions.SEARCH_RESET:
    default: {
      return {
        ...state,
        isLoading: false,
        searchLoading: false,
        searchResult: null,
      };
    }
  }
};
