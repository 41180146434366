export const EXAMS_LIST_LOADING = "EXAMS_LIST_LOADING";
export const EXAMS_LIST_SUCCESS = "EXAMS_LIST_SUCCESS";
export const EXAMS_LIST_ERROR = "EXAMS_LIST_ERROR";

export const WIDGET_EXAM_LIST_LOADING = "WIDGET_EXAM_LIST_LOADING";
export const WIDGET_EXAM_LIST_SUCCESS = "WIDGET_EXAM_LIST_SUCCESS";
export const WIDGET_EXAM_LIST_ERROR = "WIDGET_EXAM_LIST_ERROR";

export const WIDGET_ASSESSMENT_LIST_LOADING = "WIDGET_ASSESSMENT_LIST_LOADING";
export const WIDGET_ASSESSMENT_LIST_SUCCESS = "WIDGET_ASSESSMENT_LIST_SUCCESS";
export const WIDGET_ASSESSMENT_LIST_ERROR = "WIDGET_ASSESSMENT_LIST_ERROR";

export const WIDGET_QUIZ_LIST_LOADING = "WIDGET_QUIZ_LIST_LOADING";
export const WIDGET_QUIZ_LIST_SUCCESS = "WIDGET_QUIZ_LIST_SUCCESS";
export const WIDGET_QUIZ_LIST_ERROR = "WIDGET_QUIZ_LIST_ERROR";

export const EXAM_DETAIL_LOADING = "EXAM_DETAIL_LOADING";
export const EXAM_DETAIL_SUCCESS = "EXAM_DETAIL_SUCCESS";
export const EXAM_DETAIL_ERROR = "EXAM_DETAIL_ERROR";

export const CREATE_EXAM_LOADING = "CREATE_EXAM_LOADING";
export const CREATE_EXAM_SUCCESS = "CREATE_EXAM_SUCCESS";
export const CREATE_EXAM_ERROR = "CREATE_EXAM_ERROR";

export const EXAM_STUDENTS_LOADING = "EXAM_STUDENTS_LOADING";
export const EXAM_STUDENTS_SUCCESS = "EXAM_STUDENTS_SUCCESS";
export const EXAM_STUDENTS_ERROR = "EXAM_STUDENTS_ERROR";

export const SUBJECT_SKILLS_LOADING = "SUBJECT_SKILLS_LOADING";
export const SUBJECT_SKILLS_SUCCESS = "SUBJECT_SKILLS_SUCCESS";
export const SUBJECT_SKILLS_ERROR = "SUBJECT_SKILLS_ERROR";

export const EXAM_MARK_LIST_LOADING = "EXAM_MARK_LIST_LOADING";
export const EXAM_MARK_LIST_SUCCESS = "EXAM_MARK_LIST_SUCCESS";
export const EXAM_MARK_LIST_ERROR = "EXAM_MARK_LIST_ERROR";

export const UPDATE_EXAM_LOADING = "UPDATE_EXAM_LOADING";
export const UPDATE_EXAM_SUCCESS = "UPDATE_EXAM_SUCCESS";
export const UPDATE_EXAM_ERROR = "UPDATE_EXAM_ERROR";

export const SUBJECT_DETAIL_LOADING = "SUBJECT_DETAIL_LOADING";
export const SUBJECT_DETAIL_SUCCESS = "SUBJECT_DETAIL_SUCCESS";
export const SUBJECT_DETAIL_ERROR = "SUBJECT_DETAIL_ERROR";

