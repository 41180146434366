export const BIG_KPI_LIST_LOADING = "BIG_KPI_LIST_LOADING";
export const BIG_KPI_LIST_SUCCESS = "BIG_KPI_LIST_SUCCESS";
export const BIG_KPI_LIST_ERROR = "BIG_KPI_LIST_ERROR";
export const BIG_KPI_UPDATE = "BIG_KPI_UPDATE";

export const BIGX_CAMPUS_PERFORMANCE_LOADING =
  "BIGX_CAMPUS_PERFORMANCE_LOADING";
export const BIGX_CAMPUS_PERFORMANCE_SUCCESS =
  "BIGX_CAMPUS_PERFORMANCE_SUCCESS";
export const BIGX_CAMPUS_PERFORMANCE_ERROR = "BIGX_CAMPUS_PERFORMANCE_ERROR";
