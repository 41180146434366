import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from "url-search-params";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import asyncComponent from "util/asyncComponent";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType,
} from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from "constants/ThemeSetting";

import {
  setInitUrl,
  //getAdminUserLoggedIn,
} from "appRedux/actions/auth.actions";
import VerifyAuth from "routes/auth/VerifyAuth";
import Reports from "../Reports";
import { AdminRoute } from "components/app/RouteComponents";
import { mb } from "@mb/api";
import { utils } from "@mb";

const App = () => {
  const dispatch = useDispatch();
  const { locale, navStyle, layoutType } = useSelector(
    ({ settings }) => settings
  );
  const { token, tokenVerified, initURL } = useSelector(({ auth }) => auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  // useEffect(() => {
  //   let link = document.createElement("link");
  //   link.type = "text/css";
  //   link.rel = "stylesheet";
  //   link.href = "/css/style.css";
  //   link.className = "gx-style";
  //   document.body.appendChild(link);
  // });

  useEffect(() => {
    if (initURL === "") {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get("theme")));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get("nav-style")));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get("layout-type")));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [
    dispatch,
    initURL,
    layoutType,
    location.pathname,
    location.search,
    navStyle,
  ]);

  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("full-layout");
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove("full-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("boxed-layout");
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("full-layout");
      document.body.classList.add("framed-layout");
    }
  };

  const setNavStyle = (navStyle) => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add("full-scroll");
      document.body.classList.add("horizontal-layout");
    } else {
      document.body.classList.remove("full-scroll");
      document.body.classList.remove("horizontal-layout");
    }
  };
  useEffect(() => {
    if (!utils.isEmpty(token)) {
      mb.api.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete mb.api.axios.defaults.headers.common["Authorization"];
    }
  }, []);
  useEffect(() => {
    if (location.pathname === "/") {
      if (!token) {
        history.push("/VerifyAuth");
      } else if (
        initURL === "" ||
        initURL === "/" ||
        initURL === "/VerifyAuth"
      ) {
        history.push("/home");
      } else {
        history.push(initURL);
      }
    }
  }, [tokenVerified, token, initURL, location, history]);
  // if (themeType === THEME_TYPE_DARK) {
  //   document.body.classList.add("dark-theme");
  // }
  const currentAppLocale = AppLocale[locale.locale];
  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Switch>
          <Route exact path="/VerifyAuth" component={VerifyAuth} />
          <Route exact path="/Reports" component={Reports} />
          <Route
            exact
            path="/smis"
            component={() => {
              window.location.replace(
                `http://${process.env.REACT_APP_REPORT_SERVER_DOMAIN}/smis/DashBoard/Dashboard.aspx`
              );
              //window.location.href = `http://${process.env.REACT_APP_REPORT_SERVER_DOMAIN}/smis/`;
              return null;
            }}
          />
          <Route
            path={`${match.url}404`}
            component={asyncComponent(() => import("routes/errorPages/404"))}
          />
          <Route
            path={`${match.url}500`}
            component={asyncComponent(() => import("routes/errorPages/500"))}
          />
          <AdminRoute
            path={`${match.url}`}
            token={token}
            location={location}
            component={MainApp}
          />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};
export default memo(App);
