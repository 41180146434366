import * as Actions from "../actions";
import { createSelector } from "@reduxjs/toolkit";

const INIT_STATE = {
  bigKpiList: null,
  bigKpiListLoading: false,
  bigKpiListError: false,
  bigKpiUpdate: false,
  bigXCampusPerformanceLoading: false,
  bigXCampusPerformance: null,
  bigXCampusPerformanceError: false,
};

const bigX = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.BIG_KPI_LIST_LOADING: {
      return {
        ...state,
        bigKpiListLoading: true,
        bigKpiListError: false,
        bigKpiList: null,
      };
    }

    case Actions.BIG_KPI_LIST_SUCCESS: {
      return {
        ...state,
        bigKpiListLoading: false,
        bigKpiList: action.payload,
        bigKpiListError: false,
      };
    }

    case Actions.BIG_KPI_LIST_ERROR: {
      return {
        ...state,
        bigKpiListLoading: false,
        bigKpiListError: true,
        bigKpiList: null,
      };
    }

    case Actions.BIG_KPI_UPDATE: {
      console.log(action);
      return {
        ...state,
        bigKpiUpdate: true,
      };
    }
    case Actions.BIGX_CAMPUS_PERFORMANCE_LOADING: {
      return {
        ...state,
        bigXCampusPerformanceLoading: true,
      };
    }
    case Actions.BIGX_CAMPUS_PERFORMANCE_SUCCESS: {
      return {
        ...state,
        bigXCampusPerformanceLoading: false,
        bigXCampusPerformance: action.payload,
      };
    }
    case Actions.BIGX_CAMPUS_PERFORMANCE_ERROR: {
      return {
        ...state,
        bigXCampusPerformanceLoading: false,
        bigXCampusPerformance: null,
        bigXCampusPerformanceError: true,
      };
    }

    default: {
      return {
        ...state,
        isLoading: false,
        kpiItemLoading: false,
      };
    }
  }
};

export const bigKpiListSelectors = createSelector(
  [
    (state) => state.bigX.bigKpiList,
    (state) => state.bigX.bigKpiListLoading,
    (state) => state.bigX.bigKpiListError,
  ],
  (bigKpiList, bigKpiListLoading, bigKpiListError) => {
    if (!bigKpiList) return {};

    const mappedBigKpiList = bigKpiList.reduce((acc, curr) => {
      if (curr.big5 === null) {
        acc[curr.id] = curr;
      } else {
        acc[curr.big5].kpiList = acc[curr.big5].kpiList
          ? acc[curr.big5].kpiList
          : [];
        // curr.formula =
        //   '[{"from":0,"to":5,"value":5},{"from":5,"to":7,"value":4},{"from":7,"to":8,"value":3}]';
        acc[curr.big5].kpiList.push(curr);
      }
      return acc;
    }, {});

    //Mapping. Added targetWithOperator field
    const list = Object.values(mappedBigKpiList).map((bigKpi) => {
      return {
        key: bigKpi.id,
        ...bigKpi,
        kpiList: bigKpi.kpiList.map((kpi) => {
          return {
            ...kpi,
            targetWithOperator: `${targetOperatorDic[kpi.targetOperator]}${
              kpi.target
            }`,
            key: kpi.id,
          };
        }),
      };
    });

    return {
      bigKpiList: list,
      bigKpiListLoading,
      bigKpiListError,
    };
  }
);

export const targetOperatorDic = {
  GreaterThan: ">",
  LessThan: "<",
  "<": "LessThan",
  ">": "GreaterThan",
};

export default bigX;
