import * as Actions from "../actions";
import { PageInfo } from "../../constants/GlobalConstants";
import { utils } from "../../@mb";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,
  questionItemId: null,
  questionItem: null,
  questionItemLoading: false,
  questionListLoading: false,
  questionList: [],
  pageInfo: PageInfo,
  questionMediaUploading: false,
  questionMediaInfo: null,
  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.QUESTION_LOADING: {
      return {
        ...state,
        questionItemLoading: true,
        questionItemId: null,
        questionItem: null,
        questionMediaInfo: null,
        error: null,
      };
    }
    case Actions.QUESTION_ADD_SAVING: {
      return {
        ...state,
        questionItemLoading: true,
        questionItemId: null,
        questionItem: null,
        questionMediaInfo: null,
        error: null,
      };
    }
    case Actions.QUESTION_ADD_SUCCESS: {
      const pi = state.pageInfo != null ? state.pageInfo : PageInfo;
      return {
        ...state,
        questionItemLoading: false,
        questionItemId: action.payload.uniqueId,
        questionItem: null,
        pageInfo: {
          ...pi,
        },
        error: null,
      };
    }
    case Actions.QUESTION_SAVING: {
      return {
        ...state,
        //questionItemLoading: true,
        error: null,
      };
    }
    case Actions.QUESTION_SUCCESS: {
      let newQl = state.questionList;
      if (!utils.isEmpty(state.questionList)) {
        newQl = state.questionList.map((q) => {
          if (q.uniqueId === action.payload.uniqueId) {
            q = action.payload;
          }
          return q;
        });
      }
      return {
        ...state,
        questionItemLoading: false,
        questionItem: action.payload,
        questionList: newQl,
        error: null,
      };
    }
    case Actions.QUESTION_ADD_ERROR: {
      return {
        ...state,
        questionItemLoading: false,
        questionItemId: null,
        questionItem: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.QUESTION_ERROR: {
      return {
        ...state,
        questionItemLoading: false,
        questionItem: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.QUESTION_LIST_LOADING: {
      return {
        ...state,
        questionListLoading: true,
        questionItem: null,
        questionList: [],
        error: null,
      };
    }
    case Actions.QUESTION_LIST_SUCCESS: {
      return {
        ...state,
        questionListLoading: false,
        questionList: [...action.payload.data],
        pageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },

        error: null,
      };
    }
    case Actions.QUESTION_LIST_ERROR: {
      return {
        ...state,
        questionListLoading: false,
        questionList: [],
        pageInfo: {
          ...PageInfo,
          HasNext: false,
          HasPrevious: false,
        },
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.QUESTION_BULK_UPLOAD_LOADING: {
      return {
        ...state,
        questionListLoading: true,
        questionList: [],
        error: null,
      };
    }
    case Actions.QUESTION_BULK_UPLOAD_SUCCESS: {
      return {
        ...state,
        questionListLoading: false,
        questionList: [...action.payload],
        error: null,
      };
    }
    case Actions.QUESTION_BULK_UPLOAD_ERROR: {
      return {
        ...state,
        questionListLoading: false,
        questionList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: `File ingestion failed, please verify the file conforms the import standards.`,
        },
      };
    }
    case Actions.QUESTION_MEDIA_SAVING: {
      return {
        ...state,
        questionMediaUploading: true,
        questionMediaInfo: null,
        error: null,
      };
    }
    case Actions.QUESTION_MEDIA_SUCCESS: {
      return {
        ...state,
        questionMediaUploading: false,
        questionMediaInfo: action.payload,
        error: null,
      };
    }
    case Actions.QUESTION_MEDIA_ERROR: {
      return {
        ...state,
        questionMediaUploading: false,
        questionMediaInfo: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: `Media upload failed, please verify the file conforms the requirements.`,
        },
      };
    }
    default: {
      return {
        ...state,
        isLoading: false,
        questionListLoading: false,
      };
    }
  }
};
