import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
} from "../../constants/ActionTypes";
import { utils } from "@mb";

export const fetchStart = (startType = FETCH_START) => {
  return {
    type: startType,
  };
};

export const fetchSuccess = (successType = FETCH_SUCCESS) => {
  return {
    type: successType,
  };
};

export const fetchSuccessWithData = (successType = FETCH_SUCCESS, payload) => {
  return {
    type: successType,
    payload,
  };
};

export const fetchError = (error, errorType = FETCH_ERROR) => {
  return {
    type: errorType,
    payload: error,
  };
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

// export const hideMessage = () => {
//   return {
//     type: HIDE_MESSAGE,
//   };
// };

export const successCallback = (data, cb) => (!!cb ? cb(data) : data);
/**
 * #### Description
 * A helper function that will keep track of the lifecycle of a typical request to the backend
 * which involves a loading, success and failed state. This function is responsible for querying the
 * api service and updating the redux state to reflect the lifecycle of the request.
 * 
 * @param {dispatch} dispatch - just pass in the dispatch that redux passes in.
 * @param {string} loading - the loading action for the item
 * @param {string} success - the success action for the item
 * @param {string} error - the error action for the item
 * @param {function} delegate - The service to call and observe while dispatching the 3 state actions
 * @param {object} data - the object that is passed to the delegate, usually query params like id.
 * @param {object} additionalInfo - additional details to attach to the result that is forwarded to the store.
 * 
 * #### Example
 * @example
 *
 * ```js
 * const fetchEmployeeById2 = (id) => async (dispatch) =>
    await common.thunkHelper(
      dispatch,
      EMPLOYEE_ITEM_LOADING,
      EMPLOYEE_ITEM_SUCCESS,
      EMPLOYEE_ITEM_ERROR,
      employeeService.getById,
      id
    );
 * ```
 */
export const thunkHelper = async (
  dispatch,
  loading,
  success,
  error,
  delegate,
  data,
  additionalInfo = {},
  successCb
) => {
  try {
    dispatch(fetchStart(loading));

    const result = await delegate(data);
    if (!utils.isEmpty(additionalInfo)) result.additionalInfo = additionalInfo;
    return dispatch(
      fetchSuccessWithData(success, successCallback(result, successCb))
    );
  } catch (ex) {
    // debugger;
    return dispatch(fetchError(ex, error));
  }
};
