//import { AppContainer } from "react-hot-loader";
import ReactDOM from "react-dom";
import NextApp from "./NextApp";
import * as serviceWorker from "./serviceWorker";

// Add this import:


// Wrap the rendering in a function:
const render = (Component) => {
  ReactDOM.render(
    // Wrap App inside AppContainer
  //  <AppContainer>
      <NextApp />,
   // </AppContainer>,
    document.getElementById("root")
  );
};
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./NextApp", () => {
    render(NextApp);
  });
}
