export const Constants = {
  SMIS_APP: {
    KOOLBAG: "KOOLBAG",
    ADMIN: "ADMIN",
  },
};
export const PageInfo = {
  Total: 0,
  PageSize: 7,
  Current: 0,
  TotalPages: 1,
  HasNext: true,
  HasPrevious: false,
};
export const EntityStatus = {
  Draft: 0,
  Active: 1001,
  Deleted: 2001,
};
export const FileType = {
  All: "*/*",
  Excel: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  Image: "image/*",
  Pdf: "application/pdf",
  Video: "*/*",
};
export const ContentType = {
  NotSet: 0,
  Image: 1,
  Audio: 2,
  Video: 3,
  Pdf: 4,
  Text: 5,
};
export const CompletionStatus = {
  NotSet: 0,
  Open: 1000,
  Done: 2000,
};

export const COLORS = [
  "#1f77b4",
  "#ff7f0e",
  "#2ca02c",
  "#d62728",
  "#9467bd",
  "#8c564b",
  "#e377c2",
  "#7f7f7f",
  "#bcbd22",
  "#17becf",
];

export const SearchType = {
  Employee: 100,
  Student: 200,
};

export const KpiReportType = {
  DailyReport: 1,
  MonthlyReport: 2,
};

export const KpiType = {
  DailyReport: 1,
  MonthlyReport: 2,
  CampusUpKeep: 3,
};

export const AttendanceStatus = {
  Pending: 0,
  Submitted: 1,
};
