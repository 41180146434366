import * as Actions from "../actions/studentManagement.actions";
import { PageInfo } from "../../constants/GlobalConstants";
import { utils } from "../../@mb";

const INIT_STATE = {
  
  exitStudentLoading: false,
  exitStudent: null,
  exitStudentSaving: false,
  

  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case Actions. EXIT_STUDENT_LOADING: {
      return {
        ...state,
        exitStudentLoading: true,
        exitStudent: null,
        error: null,
      };
    }
    case Actions. EXIT_STUDENT_SUCCESS: {
      return {
        ...state,
        exitStudentLoading: false,
        exitStudent:{...action.payload},
        error: null,
      };
    }
    case Actions. EXIT_STUDENT_ERROR: {
      return {
        exitStudentLoading: false,
        exitStudent: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }


    case Actions.EXIT_STUDENT_SAVE_LOADING: {
      return {
        ...state,
        exitStudentSaving: true,
        error: null,
      };
    }
    case Actions.EXIT_STUDENT_SAVE_SUCCESS: {
      return {
        ...state,
        exitStudentSaving: false,
        exitStudent:{...action.payload},
        error: null,
      };
    }
    case Actions.EXIT_STUDENT_SAVE_ERROR: {
      return {
        ...state,
        exitStudentSaving: false,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
   
    default: {
      return {
        ...state,
        isLoading: false,
      };
    }
  }
};
