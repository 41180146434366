import * as Actions from "../actions/plan.actions";
import * as common from "../actions/Common";

import planService from "appServices/planService";

export {
  fetchPlanList,
  fetchPlanMapping,
  submitBulkPlan,
  fetchPlanById,
  submitAddNewPlan,
  submitUpdatePlan,
  fetchUnitList,
  submitAddNewUnit,
  submitUpdateUnit,
  submitDeleteUnit,
  submitUnitMarkCompleted,
  reorderUnits,
};

const reorderUnits = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.REORDER_UNITS_LOADING,
    Actions.REORDER_UNITS_SUCCESS,
    Actions.REORDER_UNITS_ERROR,
    planService.reorderUnits,
    query
  );

function fetchPlanById(id) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.PLAN_LOADING));

      const result = await planService.getById(id);
      return dispatch(
        common.fetchSuccessWithData(Actions.PLAN_SUCCESS, result)
      );
    } catch (error) {
      return dispatch(common.fetchError(error, Actions.PLAN_ERROR));
    }
  };
}
function submitAddNewPlan(plan) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.PLAN_ADD_SAVING));

      const result = await planService.updatePlan(plan);
      return dispatch(
        common.fetchSuccessWithData(Actions.PLAN_ADD_SUCCESS, result)
      );
    } catch (error) {
      return dispatch(common.fetchError(error, Actions.PLAN_ADD_ERROR));
    }
  };
}
function submitUpdatePlan(question) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.PLAN_SAVING));

      const result = await planService.updatePlan(question);
      return dispatch(
        common.fetchSuccessWithData(Actions.PLAN_SUCCESS, result)
      );
    } catch (error) {
      return dispatch(common.fetchError(error, Actions.PLAN_ERROR));
    }
  };
}
function fetchPlanList(query) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.PLAN_LIST_LOADING));

      const result = await planService.getPagedList(query);
      //   selectedClass,
      //   selectedSubject
      // );
      //const result = cardList;

      return dispatch(
        common.fetchSuccessWithData(Actions.PLAN_LIST_SUCCESS, result)
      );
    } catch (error) {
      return dispatch(common.fetchError(error, Actions.PLAN_LIST_ERROR));
    }
  };
}

/*function submitMedia(entityId, formData) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.UNIT_MEDIA_SAVING));

      const result = await planService.submitMedia(entityId, formData);
      return dispatch(
        common.fetchSuccessWithData(Actions.UNIT_MEDIA_SUCCESS, result)
      );
    } catch (error) {
      return dispatch(common.fetchError(error, Actions.UNIT_MEDIA_ERROR));
    }
  };
}*/

function submitBulkPlan(formData) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.PLAN_BULK_UPLOAD_LOADING));

      const result = await planService.submitBulkPlans(formData);
      return dispatch(
        common.fetchSuccessWithData(Actions.PLAN_BULK_UPLOAD_SUCCESS, result)
      );
    } catch (error) {
      return dispatch(common.fetchError(error, Actions.PLAN_BULK_UPLOAD_ERROR));
    }
  };
}

function fetchUnitList(query) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.UNIT_LIST_LOADING));

      const result = await planService.getUnitListByPlanId(query);
      return dispatch(
        common.fetchSuccessWithData(Actions.UNIT_LIST_SUCCESS, result)
      );
    } catch (error) {
      return dispatch(common.fetchError(error, Actions.UNIT_LIST_ERROR));
    }
  };
}

const submitAddNewUnit = (entity) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.UNIT_ADD_SAVING));

    const result = await planService.updateUnit(entity);
    return dispatch(
      common.fetchSuccessWithData(Actions.UNIT_ADD_SUCCESS, result)
    );
  } catch (error) {
    return dispatch(common.fetchError(error, Actions.UNIT_ADD_ERROR));
  }
};

const submitUpdateUnit = (entity) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.UNIT_SAVING));

    const result = await planService.updateUnit(entity);
    return dispatch(common.fetchSuccessWithData(Actions.UNIT_SUCCESS, result));
  } catch (error) {
    return dispatch(common.fetchError(error, Actions.UNIT_ERROR));
  }
};
const submitUnitMarkCompleted = (entity) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.UNIT_MARK_COMPLETED_SAVING));

    const result = await planService.submitUnitMarkCompleted(entity);
    return dispatch(
      common.fetchSuccessWithData(Actions.UNIT_MARK_COMPLETED_SUCCESS, result)
    );
  } catch (error) {
    return dispatch(
      common.fetchError(error, Actions.UNIT_MARK_COMPLETED_ERROR)
    );
  }
};

const submitDeleteUnit = (planId, unitId) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.UNIT_DELETE_SUBMIT));

    const result = await planService.deleteUnit(planId, unitId);
    return dispatch(
      common.fetchSuccessWithData(Actions.UNIT_DELETE_SUCCESS, result)
    );
  } catch (error) {
    return dispatch(common.fetchError(error, Actions.UNIT_DELETE_ERROR));
  }
};

const fetchPlanMapping = (subjectId) => {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.UNIT_MAPPING_LIST_LOADING));

      const result = await planService.getPlanMapping(subjectId);

      return dispatch(
        common.fetchSuccessWithData(
          Actions.UNIT_MAPPING_LIST_SUCCESS,
          //result.filter((d) => d.planId == planId)
          result
        )
      );
    } catch (error) {
      return dispatch(
        common.fetchError(error, Actions.UNIT_MAPPING_LIST_ERROR)
      );
    }
  };
};
