import * as common from "../actions/Common";

import {
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  USER_TOKEN_SET,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  PASSWORD_CHANGED,
  PASSWORD_RESET,
  USER_TOKEN_FAILED,
  PASSWORD_RESET_FAILED,
  RESET_AUTH_DATA,
} from "constants/ActionTypes";

import jwtService from "appServices/jwtService";
import profileService from "appServices/profileService";

import { utils } from "@mb";
import {
  ADMIN_VERIFICATION_URL_RECIEVED,
  ADMIN_LOGIN_VERIFIED,
  AUTH_USER_UPDATED,
} from "../actions";

export { 
  getAdminUserLoggedIn, 
  getAdminUserVerified, 
  updateCampusId, 
  submitChangePassword,
  submitResetAuthInfo };

const updateCampusId = (query) => {
  return (dispatch) => {
    const newAuthUser = profileService.updateCampusId(query);
    dispatch(common.fetchSuccessWithData(AUTH_USER_UPDATED, newAuthUser));
  };
};

function getAdminUserLoggedIn() {
  return (dispatch) => {
    jwtService
      .getAdminLoggedIn()
      .then((authUser) => {
        dispatch(
          common.fetchSuccessWithData(ADMIN_LOGIN_VERIFIED, authUser)
          );
      })
      .catch((error) => {
        console.error(`Error****:`, error);
        console.log(error.response)
        console.log(error.response.data.redirectUrl)
        dispatch(common.fetchError(error.response, ADMIN_VERIFICATION_URL_RECIEVED));
      });
  };
}

function getAdminUserVerified(code) {
  return (dispatch) => {
    jwtService
      .verifyAdminLogin(code)
      .then((authUser) => {
        dispatch(common.fetchSuccessWithData(ADMIN_LOGIN_VERIFIED, authUser));
      })
      .catch((error) => {
        console.error(`Error****:`, error);
        dispatch(common.fetchError(error));
      });
  };
}

function submitResetAuthInfo() {
  return (dispatch) => {
    dispatch({ type: RESET_AUTH_DATA });
  };
}

function submitLogin({ email, password }) {
  return (dispatch) => {
    dispatch(showAuthLoader());
    dispatch({ type: FETCH_START });

    jwtService
      .signInWithEmailAndPassword(email, password)
      .then((authInfo) => {
        if (!utils.isEmpty(authInfo)) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: authInfo });
        } else {
          dispatch(
            common.fetchError(
              { failed: true, message: "Sign in error, please try again." },
              USER_TOKEN_FAILED
            )
          );
        }
        dispatch(hideAuthLoader());
      })
      .catch((error) => {
        debugger;
        console.error(`Error****:`, error);
        dispatch(hideAuthLoader());
        return dispatch({
          type: FETCH_ERROR,
          payload: error,
        });
      });
  };
}

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

const submitChangePassword = ({ currentPassword, password }) => async (
  
  dispatch
) => {
  try {
    dispatch(common.fetchStart());
    jwtService
      .changePassword(currentPassword, password)
      .then((changedPasswordInfo) => {
        if (changedPasswordInfo.isValid) {
          dispatch(common.fetchSuccess());
        } else {
          dispatch(common.fetchError(changedPasswordInfo.message));
        }
        dispatch({ type: PASSWORD_CHANGED, payload: changedPasswordInfo });
      })
      .catch((error) => {
        debugger;
        console.error(`Error****:`, error);
        return dispatch(common.fetchError(error));
      });
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error));
  }
};

function submitForgotPassword({ grNo, phone }) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart());
      jwtService
        .forgotPassword(grNo, phone)
        .then((forgotPasswordInfo) => {
          if (forgotPasswordInfo.isValid) {
            dispatch(common.fetchSuccess());
          } else {
            dispatch(
              common.fetchError(
                forgotPasswordInfo.message,
                PASSWORD_RESET_FAILED
              )
            );
          }
          dispatch({ type: PASSWORD_RESET, payload: forgotPasswordInfo });
        })
        .catch((error) => {
          debugger;
          console.error(`Error****:`, error);
          return dispatch(common.fetchError(error));
        });
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error));
    }
  };
}
