import * as common from "../actions/Common";
import * as Actions from "../actions";
import bigXService from "../../appServices/bigXService/big-x.service";
import kpiService from "../../appServices/kpiService";
import { dt } from "@mb";
import kpiHelpers from "../../routes/kpi/kpi.helpers";
import { Kpi } from "constants/const.kpi";
import lookupService from "../../appServices/lookupService";
import { LookupType } from "./lookup.thunk";
import employeeService from "../../appServices/employeeService";

const getBigKpiList = (data) => async (dispatch) => {
  return await common.thunkHelper(
    dispatch,
    Actions.BIG_KPI_LIST_LOADING,
    Actions.BIG_KPI_LIST_SUCCESS,
    Actions.BIG_KPI_LIST_ERROR,
    bigXService.getBigKpiList,
    data
  );
};

const updateBigKpi = (data) => async (dispatch) => {
  return await common.thunkHelper(
    dispatch,
    null,
    Actions.BIG_KPI_UPDATE,
    null,
    bigXService.updateBigKpi,
    data
  );
};

const calculateBigXCampusPerformance = (campusList) => {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.BIGX_CAMPUS_PERFORMANCE_LOADING));

      const CAMPUS_SESSION_LIST = await lookupService.getList({
        type: LookupType.ActiveSessions,
      });
      const bigXByCampus = {};
      const userCampusSessionList = CAMPUS_SESSION_LIST.filter(
        (cs) => campusList.findIndex((c) => c.value === cs.campusID) !== -1
      );

      for (const {
        campusID,
        sessionID,
        sessionStart,
        sessionEnd,
      } of userCampusSessionList) {
        try {
          const additionalData = {};
          additionalData["totalTeachers"] = (
            await employeeService.getMonthlyTeacherList({
              campusID,
            })
          ).length;
          const bigXCriteria = await bigXService.getBigKpiList({
            campusID,
            sessionID,
          });
          const startMonthlyReportGraphContent = (
            await kpiService.fetchMonthlyReportGraphContent({
              date: dt.toLocal(sessionStart).format(dt.formats.DateMonthYear1),
              campusID,
            })
          ).filter((entry) =>
            dt.isBetween(entry.dateRangeFrom, sessionStart, sessionEnd)
          );
          const endMonthlyReportGraphContent = (
            await kpiService.fetchMonthlyReportGraphContent({
              date: dt.toLocal(sessionEnd).format(dt.formats.DateMonthYear1),
              campusID,
            })
          ).filter((entry) =>
            dt.isBetween(entry.dateRangeFrom, sessionStart, sessionEnd)
          );

          const monthlyReportGraphContent = [
            ...startMonthlyReportGraphContent,
            ...endMonthlyReportGraphContent,
          ];
          const parsedMonthlyData =
            await kpiHelpers.parseMonthlyReportGraphContentAsync(
              monthlyReportGraphContent,
              dt.today(),
              campusID
            );

          const bigXCampusResult = calculateBigXFromMonthlyData(
            bigXCriteria,
            parsedMonthlyData,
            additionalData
          );

          bigXByCampus[campusID] = bigXCampusResult;
        } catch (error) {
          console.error({ error });
        }
      }

      return dispatch({
        type: Actions.BIGX_CAMPUS_PERFORMANCE_SUCCESS,
        payload: { ...bigXByCampus },
      });
    } catch (error) {
      debugger;
      return dispatch({
        type: Actions.BIGX_CAMPUS_PERFORMANCE_ERROR,
        payload: error,
      });
    }
  };
};
const CUSTOM_EXTRACTOR = "bigxkpi";

function calculateBigXFromMonthlyData(bigX, parsedMonthlyData, additionalData) {
  const months = dt.getShortMonths();
  const result = new Map();
  for (const m of months) {
    let tmp = {};
    const bigXParents = bigX.filter((b) => b.big5 === null);
    for (const parent of bigXParents) {
      const obj = { total: 0, weightage: parent.weightage };
      tmp[parent.name] = obj;
      bigX
        .filter((b) => b.big5 === parent.id)
        .forEach((b) => {
          const childResult = {
            bigXScore: 0,
            value: 0,
            target: b.target,
            targetOperator: b.targetOperator,
            weightage: b.weightage,
          };
          obj[b.name] = childResult;
          const key = +b.kpIs;
          const [kpiKey, extractor] =
            Kpi.BigXKpiToParsedMonthlyDataMapping[key].split(":");
          let r = {};
          const data = parsedMonthlyData?.[kpiKey]?.find((f) => f.month === m);
          if (!data) {
            return;
          }
          if (extractor === CUSTOM_EXTRACTOR) {
            r = getBigXKpiFromMonthlyData(key, data, additionalData);
          } else {
            r = data[extractor];
          }
          const formula = JSON.parse(b.formula);
          const t = +(
            formula?.find((f) => r >= +f.from && r <= +f.to)?.value ?? 0
          );
          childResult.value = r;
          childResult.bigXScore = t;
          obj.total += t;
        });
    }
    result.set(m, tmp);
  }
  return result;
}

function getBigXKpiFromMonthlyData(kpiKey, data, additionalData) {
  switch (kpiKey) {
    case 1060:
      // Teacher Attendance = (1 - (absent + late) / (totalTeachers * 25.0)) * 100.0;
      // need to fetch total teachers here.
      const { totalTeachers } = additionalData;
      const { absent, late } = data;
      const result = (1 - (absent + late) / (totalTeachers * 25.0)) * 100.0;
      return result;
    case 1070:
      // Teacher Performance = goodTeacher + averageTeacher
      return data.goodTeacher + data.averageTeacher;
    default:
      break;
  }
}
export { getBigKpiList, updateBigKpi, calculateBigXCampusPerformance };
