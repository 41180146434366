import * as Actions from "../actions";
import * as common from "../actions/Common";

import quizService from "../../appServices/quizService";

export {
  fetchQuizList,
  submitBulkQuizs,
  fetchQuizById,
  submitAddNewQuiz,
  submitUpdateQuiz,
  submitQuizMedia,
  fetchQuizQuestionList,
  submitDeleteQuestion
};

const fetchQuizById = (id) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.QUIZ_LOADING));

    const result = await quizService.getById(id);
    return dispatch(common.fetchSuccessWithData(Actions.QUIZ_SUCCESS, result));
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.QUIZ_ERROR));
  }
};

const submitAddNewQuiz = (quiz) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.QUIZ_ADD_SAVING));

    const result = await quizService.updateQuiz(quiz);
    return dispatch(
      common.fetchSuccessWithData(Actions.QUIZ_ADD_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.QUIZ_ADD_ERROR));
  }
};

const submitUpdateQuiz = (quiz) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.QUIZ_SAVING));

    const result = await quizService.updateQuiz(quiz);
    return dispatch(common.fetchSuccessWithData(Actions.QUIZ_SUCCESS, result));
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.QUIZ_ERROR));
  }
};

const fetchQuizList = (query) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.QUIZ_LIST_LOADING));

    const result = await quizService.getPagedList(query);
    //   selectedClass,
    //   selectedSubject
    // );
    //const result = cardList;
    return dispatch(
      common.fetchSuccessWithData(Actions.QUIZ_LIST_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.QUIZ_LIST_ERROR));
  }
};

const fetchQuizQuestionList = (query) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.QUIZ_QUESTION_LIST_LOADING));

    const result = await quizService.getQuestionList(query);

    return dispatch(
      common.fetchSuccessWithData(Actions.QUIZ_QUESTION_LIST_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.QUIZ_QUESTION_LIST_ERROR));
  }
};

const submitQuizMedia = (quizId, formData) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.QUIZ_MEDIA_SAVING));

    const result = await quizService.submitQuizMedia(quizId, formData);
    return dispatch(
      common.fetchSuccessWithData(Actions.QUIZ_MEDIA_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.QUIZ_MEDIA_ERROR));
  }
};

const submitBulkQuizs = (formData) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.QUIZ_BULK_UPLOAD_LOADING));

    const result = await quizService.submitBulkQuizs(formData);
    return dispatch(
      common.fetchSuccessWithData(Actions.QUIZ_BULK_UPLOAD_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.QUIZ_BULK_UPLOAD_ERROR));
  }
};

const submitDeleteQuestion = (quizId, questionId) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.QUESTION_DELETE_SUBMIT));

    const result = await quizService.deleteQuestion(quizId, questionId);
    return dispatch(
      common.fetchSuccessWithData(Actions.QUESTION_DELETE_SUCCESS, result)
    );
  } catch (error) {
    return dispatch(common.fetchError(error, Actions.QUESTION_DELETE_ERROR));
  }
};
